/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import {
  BhCard,
  BhDivider,
  BhIllustration,
  BhList,
  BhRadioButton,
  BhSelectionGroup,
  BhTitleWrapper,
} from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import './Configuration.scss';
import ConfigurationPreview from '../Shared/ConfigurationPreview/ConfigurationPreview';
import EditConfigurationModal from '../Shared/Modal/EditConfigurationModal';
import WarningIcon from '@mui/icons-material/Warning';
import { Avatar, Tooltip } from '@mui/material';
import { getSubUnitName, getUnitName } from '../../utils/utils';

export default function Configuration(props: any) {
  const { getConfigData, getConfigDataForSandBox } = props;
  const [isConfigOpen, setConfigOpenFlag] = useState(false);
  const [preflashConfig, setPreflashConfig] = useState<string>('');
  const [overheadConfig, setOverheadConfig] = useState<string>('');
  const [desalterConfig, setDesalterConfig] = useState<string>('');
  const [pipeInsideDiameter, setPipeInsideDiameter] = useState<string>('');
  const [pipeInsideDiameterUnit, setPipeInsideDiameterUnit] = useState('');
  const [branchNumber, setbranchNumber] = useState<string>('');
  const [waterWashUsed, setWaterWashUsed] = useState<string>('');
  const [washInjectionPoint, setwashInjectionPoint] = useState<string>('');
  const [waterWashInjectionEquipment, setWaterWashInjectionEquipment] = useState<string>('');
  const [washFrequency, setWashFrequency] = useState<string>('');
  const [neutralizerInjectionPoint, setNeutralizerInjectionPoint] = useState<string>('');
  const [neutralizerInjectionStage, setNeutralizerInjectionStage] = useState<string>('');
  const [configType, setConfigType] = useState<string>('');
  const [subUnitName, setSubUnitName] = useState<string>('');
  const [changedParameters, setChangedParameters] = useState<any>([]);
  const [unitType, setUnitType] = useState<string>('');

  const closeConfigModal = (e: any) => {
    if (e) {
      if (e.target.id === 'confirmation-modal') {
        return;
      } else {
        setConfigOpenFlag(false);
      }
    }
    else {
      setConfigOpenFlag(false);
    }
  };

  const setPreFlashValue = (preflashValue: string) => {
    let preflashConfigText = '';
    if(preflashValue){
    switch (preflashValue.toLowerCase()) {
      case 'no preflash':
        preflashConfigText = 'No Preflash';
        break;
      case 'preflash vapors feed atmospheric tower':
        preflashConfigText = 'Preflash vapors feed atmospheric tower';
        break;
      case 'preflash vapors feed atmospheric tower overhead line':
        preflashConfigText = 'Preflash vapors feed atmospheric tower overhead line';
        break;
      case 'preflash vapors leave crude unit':
        preflashConfigText = 'Preflash vapors leave crude unit';
        break;
      case 'prefractionation tower':
        preflashConfigText = 'Prefractionation tower';
        break;
    }
   }
    setPreflashConfig(preflashConfigText);
  };

  const checkDefaultConfigChanged =(configName:string)=>{
    if(changedParameters.length){
      const resObject = changedParameters.find((item:any) => item.fieldName === configName);
      if(resObject){
         return true;
      }
      else return false;
    }
    else return false;
  }

  const setToolTipText = (configName:string) => {
   let toolTipText='';
   if(changedParameters.length){
    const resObject = changedParameters.find((item:any) => item.fieldName === configName);
    if(resObject){
      let idsValue = resObject.idsValue;
      switch(resObject.fieldName){
        case 'numOvhdStages':
          if(resObject.idsValue === '1') idsValue = 'One Stage Overhead';
          else idsValue = 'Two Stage Overhead';
        break;
        case 'numDesalterStages':
          if(resObject.idsValue === '1') idsValue = 'One Stage Desalter';
          else idsValue = 'Two Stage Desalter';
        break;
        case 'stageOvhdWashStream':
          if(resObject.idsValue === '1') idsValue = 'First Stage';
          else idsValue = 'Second Stage';
        break;
        case 'stageOvhdNeutralizerStream':
          if(resObject.idsValue === '1') idsValue = 'First Stage';
          else idsValue = 'Second Stage';
        break;
        case 'injectedNeutralizerStream':
          if(resObject.idsValue.toLowerCase() === 'with or after wash water') idsValue = 'In or After Water Wash';
          else idsValue = 'Before Water Wash';
        break;
        case 'idOvhdStream':
          let resultObj = changedParameters.find((item:any) => item.fieldName === 'idOvhdStreamUom');
          if(resultObj){
            idsValue = idsValue + ' ' + resultObj.idsValue;
          }
        break;
      }
      toolTipText = 'IDS default value is ' + idsValue;
    }
   }
   return toolTipText;
  }

  const setConfigValues = () => {
    let config;
    if(localStorage.getItem('defaultConfig'))
    config = JSON.parse(localStorage.getItem('defaultConfig')!).refinery;
    if (config && config.unit && config.unit[0]) {
      setUnitType(config.unit[0].unitType.trim().toLowerCase());
      config.unit[0].changedParameters && setChangedParameters(config.unit[0].changedParameters);
      //preflash
      setPreFlashValue(config.unit[0].preflashPrefractionator);

      if (config.unit[0].subUnit && config.unit[0].subUnit[0]) {
        //subunit name
        let subUnitName = getSubUnitName(config.unit[0].subUnit[0].typeSubunit.trim().toLowerCase());
        let unitType = getUnitName(config.unit[0].unitType.trim().toLowerCase());
        setSubUnitName(unitType + ' | ' + subUnitName);

        //overhead configuration
        let overheadCondensingStages = config.unit[0].subUnit[0].numOvhdStages;
        overheadCondensingStages && overheadCondensingStages === 1
          ? setOverheadConfig('One Stage Overhead')
          : setOverheadConfig('Two Stage Overhead');

        //desalter
        let desalter = config.unit[0].subUnit[0].numDesalterStages;
        desalter && desalter === 1 ? setDesalterConfig('One Stage Desalter') : setDesalterConfig('Two Stage Desalters');

        //pipe inside diameter
        setPipeInsideDiameter(config.unit[0].subUnit[0].idOvhdStream);

        //pipe inside diameter unit
        setPipeInsideDiameterUnit(config.unit[0].subUnit[0].idOvhdStreamUom);

        //number of branches
        setbranchNumber(config.unit[0].subUnit[0].numBranchesOvhdStream);

        //water wash used
        config.unit[0].subUnit[0].waterWash ? setWaterWashUsed('Yes') : setWaterWashUsed('No');

        //wash injection point
        config.unit[0].subUnit[0].stageOvhdWashStream === '1'
          ? setwashInjectionPoint('First Stage')
          : setwashInjectionPoint('Second Stage');

        //wash injection equipment
        if (config.unit[0].subUnit[0].equipmentOvhdWashStream === 'Spray nozzle oriented cocurrent')
          setWaterWashInjectionEquipment('Spray nozzle oriented cocurrent');
        else if (config.unit[0].subUnit[0].equipmentOvhdWashStream === 'Spray nozzle oriented countercurrent')
          setWaterWashInjectionEquipment('Spray nozzle oriented countercurrent');
        else if (config.unit[0].subUnit[0].equipmentOvhdWashStream === 'Other') setWaterWashInjectionEquipment('Other');

        //wash frequency
        config.unit[0].subUnit[0].washFrequency === 'Continuous' ? setWashFrequency('Continuous') : setWashFrequency('Intermittent');

        //Neutralizer injection point
        config.unit[0].subUnit[0].stageOvhdNeutralizerStream === '1'
          ? setNeutralizerInjectionStage('First Stage')
          : setNeutralizerInjectionStage('Second Stage');

        //Neutralizer injection stage
        config.unit[0].subUnit[0].injectedNeutralizerStream && config.unit[0].subUnit[0].injectedNeutralizerStream.toLowerCase() === 'with or after wash water'
          ? setNeutralizerInjectionPoint('In or After Water Wash')
          : setNeutralizerInjectionPoint('Before Water Wash');

        //set config type
        setConfigType(config.unit[0].unitType.trim().toLowerCase() === 'coker' || config.unit[0].unitType.trim().toLowerCase() === 'fcc' ? '1A': config.unit[0].subUnit[0].configType);
      }
    }
  };

  useEffect(() => {
    setConfigValues();
  }, [props]);

  return (
    <React.Fragment>
      <BhCard class="card-wrapper">
        <BhTitleWrapper
          data-testid="config-title"
          class="subunit-tile-wrapper"
          type="subtitle"
          header={subUnitName}
          cta={JSON.stringify({
            secondary: 'Edit configuration',
          })}
          onBhEventCtaClick={(event: any) => {
            setConfigOpenFlag(true);
          }}
        ></BhTitleWrapper>
        <BhDivider marginTop="small"></BhDivider>

        <div style={{ display: 'flex', justifyContent: 'space-between', minHeight: '500px' }}>
          <div style={{ width: '50%', marginRight: '5px' }}>
            <div className={(unitType === 'coker' || unitType === 'fcc') ? 'coker-config-wrapper': 'mrg-left-20'}>
            {(unitType !== 'coker' && unitType !== 'fcc') && 
                <>
              <BhTitleWrapper type="subtitle" header="Process and preflash"></BhTitleWrapper>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    width: '30%',
                    flexDirection: 'column',
                    height: '50px',
                    alignContent: 'left',
                    justifyContent: 'left',
                  }}
                >
                  <div style={{height: '20px'}}>
                    <span slot="left--first" className="typography--decorative-small typography--color-secondary">
                      Overhead configuration
                    </span>
                    { !localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ?
                    (checkDefaultConfigChanged('numOvhdStages') &&
                    <Tooltip title={setToolTipText('numOvhdStages')}>
                     <Avatar className='info-avatar info-avatar-overhead'  sx={{ width: 24, height: 24 }}>
                     <WarningIcon style={{color: '#cb6e17', marginTop:'-4px'}} fontSize="small" />
                     </Avatar>
                    </Tooltip>)
                    : ''                  
                   }
                  </div>
                  <div>
                    <span slot="left--first" className="typography--label-small typography--color-primary">
                      {overheadConfig}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '30%',
                    flexDirection: 'column',
                    height: '50px',
                    alignContent: 'left',
                    justifyContent: 'left',
                  }}
                >
                  <div style={{height: '20px'}}>
                    <span slot="left--first" className="typography--decorative-small typography--color-secondary">
                      Desalter
                    </span>
                    { !localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ?
                    (checkDefaultConfigChanged('numDesalterStages') &&
                    <Tooltip title={setToolTipText('numDesalterStages')}>
                       <Avatar className='info-avatar info-avatar-desalt'  sx={{ width: 24, height: 24 }}>
                     <WarningIcon style={{color: '#cb6e17', marginTop:'-4px'}} fontSize="small" />
                     </Avatar>
                    </Tooltip>)
                     : ''                  
                    }
                  </div>

                  <div>
                    <span slot="left--first" className="typography--label-small typography--color-primary">
                      {desalterConfig}
                    </span>
                  </div>
                </div>
                <div className='preflash-height'
                  style={{
                    display: 'flex',
                    width: '30%',
                    flexDirection: 'column',
                    alignContent: 'left',
                    justifyContent: 'left',
                  }}
                >
                  <div style={{height: '20px'}}>
                    <span slot="left--first" className="typography--decorative-small typography--color-secondary">
                      Preflash configuration
                    </span>
                    { !localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ?
                    ( checkDefaultConfigChanged('preflashPrefractionator') &&
                    <Tooltip title={setToolTipText('preflashPrefractionator')}>
                       <Avatar className='info-avatar info-avatar-preflash'  sx={{ width: 24, height: 24 }}>
                     <WarningIcon style={{color: '#cb6e17', marginTop:'-4px'}} fontSize="small" />
                     </Avatar>
                    </Tooltip>)
                     : ''                  
                    }
                  </div>

                  <div>
                    <span slot="left--first" className="typography--label-small typography--color-primary">
                      {preflashConfig}
                    </span>
                  </div>
                </div>
              </div>

              <BhDivider></BhDivider>
              </>
            }

              <BhTitleWrapper type="subtitle" header="Velocity parameters"></BhTitleWrapper>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} className={unitType === 'coker' || unitType === 'fcc' ? 'mrg-top-15':''}>
                <div
                  style={{
                    display: 'flex',
                    width: '30%',
                    flexDirection: 'column',
                    height: '50px',
                    alignContent: 'left',
                    justifyContent: 'left',
                  }}
                >
                  <div style={{height: '20px'}}>
                    <span slot="left--first" className='typography--decorative-small typography--color-secondary'>
                      Pipe inside diameter
                    { !localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ?
                    ((checkDefaultConfigChanged('idOvhdStream') || checkDefaultConfigChanged('idOvhdStreamUom')) &&
                    <Tooltip title={setToolTipText('idOvhdStream') || setToolTipText('idOvhdStreamUom')}>
                       <Avatar className='info-avatar info-avatar-position'  sx={{ width: 24, height: 24 }}>
                     <WarningIcon style={{color: '#cb6e17', marginTop:'-4px'}} fontSize="small" />
                     </Avatar>
                    </Tooltip>)
                     : ''                  
                    }
                    </span>
                  </div>
                  <div className={(unitType === 'coker' || unitType === 'fcc') ? 'mrgn-top-5' :''}>
                    <span slot="left--first" className='typography--label-small typography--color-primary'>
                      {pipeInsideDiameter ? pipeInsideDiameter + ' ' + pipeInsideDiameterUnit : ''}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '30%',
                    flexDirection: 'column',
                    height: '50px',
                    alignContent: 'left',
                    justifyContent: 'left',
                  }}
                >
                  <div style={{height: '20px'}}>
                    <span slot="left--first" className="typography--decorative-small typography--color-secondary">
                      Number of branches
                    { !localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ?
                    (checkDefaultConfigChanged('numBranchesOvhdStream') &&
                    <Tooltip title={setToolTipText('numBranchesOvhdStream')}>
                      <Avatar className='info-avatar info-avatar-position'  sx={{ width: 24, height: 24 }}>
                     <WarningIcon style={{color: '#cb6e17', marginTop:'-4px'}} fontSize="small" />
                     </Avatar>
                    </Tooltip>)
                     : ''                  
                    }
                     </span>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      width: '30%',
                      flexDirection: 'column',
                      height: '50px',
                      alignContent: 'left',
                      justifyContent: 'left',
                    }} className={unitType === 'coker' || unitType === 'fcc' ? 'mrgn-top-5' :''}
                  >
                    <span slot="left--first" className="typography--label-small typography--color-primary">
                      {branchNumber}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '30%',
                    flexDirection: 'column',
                    height: '50px',
                    alignContent: 'left',
                    justifyContent: 'left',
                  }}
                ></div>
              </div>

              <BhDivider></BhDivider>

              <BhTitleWrapper type="subtitle" header="Water wash"></BhTitleWrapper>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '90px' }}>
                <div style={{ display: 'flex', width: '30%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}>
                  <div style={{height: '20px'}}>
                    <span slot="left--first" className="typography--decorative-small typography--color-secondary">
                      Is water wash used?
                    </span>
                    { !localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ?
                    (checkDefaultConfigChanged('waterWash') &&
                    <Tooltip title={setToolTipText('waterWash')}>
                       <Avatar className='info-avatar info-avatar-position'  sx={{ width: 24, height: 24 }}>
                     <WarningIcon style={{color: '#cb6e17', marginTop:'-4px'}} fontSize="small" />
                     </Avatar>
                    </Tooltip>)
                     : ''                  
                    }
                  </div>
                  <div className={unitType === 'coker' || unitType === 'fcc' ? 'mrgn-top-5' :''}>
                    <span slot="left--first" className="typography--label-small typography--color-primary">
                      {waterWashUsed}
                    </span>
                  </div>

                  <div style={{height: '20px'}} className={unitType === 'coker' || unitType === 'fcc' ? 'mrgn-top-5' :''}>
                    <span slot="left--first" className="typography--decorative-small typography--color-secondary">
                      Wash frequency:
                    </span>
                    { !localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ?
                    (checkDefaultConfigChanged('washFrequency') &&
                    <Tooltip title={setToolTipText('washFrequency')}>
                       <Avatar className='info-avatar info-avatar-washfreq'  sx={{ width: 24, height: 24 }}>
                     <WarningIcon style={{color: '#cb6e17', marginTop:'-4px'}} fontSize="small" />
                     </Avatar>
                    </Tooltip>)
                     : ''                  
                    }
                  </div>
                  <div className={unitType === 'coker' || unitType === 'fcc' ? 'mrgn-top-5' :''}>
                    <span slot="left--first" className="typography--label-small typography--color-primary">
                      {washFrequency}
                    </span>
                  </div>
                </div>

                <div style={{ display: 'flex', width: '40%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}>
                  <div style={{height: '20px'}}>
                    <span slot="left--first" className="typography--decorative-small typography--color-secondary">
                      Wash injection point:
                    </span>
                    { !localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ?
                    (checkDefaultConfigChanged('stageOvhdWashStream') &&
                    <Tooltip title={setToolTipText('stageOvhdWashStream')}>
                       <Avatar className='info-avatar info-avatar-washinj'  sx={{ width: 24, height: 24 }}>
                     <WarningIcon style={{color: '#cb6e17', marginTop:'-4px'}} fontSize="small" />
                     </Avatar>
                    </Tooltip>)
                     : ''                  
                    }
                  </div>

                  <div className={unitType === 'coker' || unitType === 'fcc' ? 'mrgn-top-5' :''}>
                    <span slot="left--first" className="typography--label-small typography--color-primary">
                      {washInjectionPoint}
                    </span>
                  </div>

                  <div style={{height: '20px'}} className={unitType === 'coker' || unitType === 'fcc' ? 'mrgn-top-5' :''}>
                    <span slot="left--first" className="typography--decorative-small typography--color-secondary">
                      Injection equipment used:
                    { !localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ?
                    ( checkDefaultConfigChanged('equipmentOvhdWashStream') &&
                    <Tooltip title={setToolTipText('equipmentOvhdWashStream')}>
                       <Avatar className='info-avatar info-avatar-injeqp'  sx={{ width: 24, height: 24 }}>
                     <WarningIcon style={{color: '#cb6e17', marginTop:'-4px'}} fontSize="small" />
                     </Avatar>
                    </Tooltip>
                    )
                     : ''                  
                    }
                    </span>
                  </div>

                  <div className={unitType === 'coker' || unitType === 'fcc' ? 'mrgn-top-5' :''}>
                    <span slot="left--first" className="typography--label-small typography--color-primary">
                      {waterWashInjectionEquipment}
                    </span>
                  </div>
                </div>

                <div style={{ display: 'flex', width: '20%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}></div>
              </div>

              <BhDivider></BhDivider>
              {(unitType !== 'coker' && unitType !== 'fcc') && 
                <>
              <BhTitleWrapper type="subtitle" header="Neutralizer"></BhTitleWrapper>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: ' 100%', height: '50px' }}>
                <div style={{ display: 'flex', width: '30%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}>
                  <div className='div-ht-20'>
                    <span slot="left--first" className="typography--decorative-small typography--color-secondary">
                      Neutralizer injection point:
                    </span>
                    { !localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ?
                    (checkDefaultConfigChanged('injectedNeutralizerStream') &&
                    <Tooltip title={setToolTipText('injectedNeutralizerStream')}>
                       <Avatar className='info-avatar info-avatar-neutr'  sx={{ width: 24, height: 24 }}>
                     <WarningIcon style={{color: '#cb6e17', marginTop:'-4px'}} fontSize="small" />
                     </Avatar>
                    </Tooltip>)
                     : ''                  
                    }
                  </div>
                  <div>
                    <span slot="left--first" className="typography--label-small typography--color-primary">
                      {neutralizerInjectionPoint}
                    </span>
                  </div>
                </div>

                <div style={{ display: 'flex', width: '30%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}>
                  <div className='div-ht-20'>
                    <span slot="left--first" className="typography--decorative-small typography--color-secondary">
                      Neutralizer injected at stage:
                    </span>
                    { !localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ?
                    (checkDefaultConfigChanged('stageOvhdNeutralizerStream') &&
                    <Tooltip title={setToolTipText('stageOvhdNeutralizerStream')}>
                       <Avatar className='info-avatar info-avatar-neutrinj'  sx={{ width: 24, height: 24 }}>
                     <WarningIcon style={{color: '#cb6e17', marginTop:'-4px'}} fontSize="small" />
                     </Avatar>
                    </Tooltip>)
                     : ''                  
                    }
                  </div>

                  <div>
                    <span slot="left--first" className="typography--label-small typography--color-primary">
                      {neutralizerInjectionStage}
                    </span>
                  </div>
                </div>

                <div style={{ display: 'flex', width: '30%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}></div>
              </div>
              </>
              }
            </div>
          </div>
          <div style={{ width: '50%', marginLeft: '5px' }}>
            <BhCard class="preview-frame">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '75px',
                }}
              >
                <BhTitleWrapper type="label" header="Configuration preview"></BhTitleWrapper>
                <div>
                  {configType && (
                    <ConfigurationPreview type={unitType === 'coker' || unitType === 'fcc' ? '1A': configType} isFromConfig={false} idName="stage-preview" unitType={unitType}></ConfigurationPreview>
                  )}
                </div>
              </div>
            </BhCard>
           </div>
        </div>
      </BhCard>

      <EditConfigurationModal
        isConfigOpen={isConfigOpen}
        closeConfigModal={closeConfigModal}
        getConfigData={getConfigData}
        getConfigDataForSandBox={getConfigDataForSandBox}
      ></EditConfigurationModal>
    </React.Fragment>
  );
}
