/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import { Stage, Layer, Wedge, Rect, Circle, Line, Text } from 'react-konva';
import './PrimaryReport.scss';
import { getColorIndicators } from './PrimaryReportHelper';
import { BEFORE_WASH, AFTER_WASH, MONTHS } from '../../constants/report-constants';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getTempSign } from '../../utils/utils';

export default function PrimaryReport(props: any) {
  const svgRef: any = React.createRef();
  const [defaultConfig, setDefaultConfig] = useState<any>({});
  const [configType, setConfigType] = useState('');
  const [waterWash, setWaterWash] = useState<any>();
  const [neutralizerInjected, setNeutralizerInjected] = useState<any>();
  const [colorIndicators, setColorIndicators] = useState<any>();
  const [primaryReportData, setPrimaryReportData] = useState<any>();
  const [ionicInput, setIonicInput] = useState<any>();
  const [defaultUOM, setDefaultUOM] = useState<any>();
  const [neutralizerInjectedStage, setNeutralizerInjectedStage] = useState<any>();
  const [waterWashInjectedStage, setWaterWashInjectedStage] = useState<any>();
  const [reportDate, setReportDate] = useState<any>();
  const [subHeadingReport, setSubHeadingReport] = useState<any>();
  const [subUnitName, setSubUnitName] = useState<any>();
  const [rowData, setRowData] = useState<any>();
  const [unitType, setUnitType] = useState<string>('');
  const {subText, mainText} = props;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(80, 108, 101, 1)',
      fontFamily: 'Poppins,sans-serif',
      fontWeight: 600,
      fontSize: '1.25rem',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#f2f6f5',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '& th': {
      color: 'rgba(80, 108, 101, 1)',
      fontFamily: 'Poppins,sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
    },
    '& td': {
      color: 'black',
      fontFamily: 'Poppins,sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
    },
  }));

  function createTempTable(
    name: any,
    towerTop: any,
    towerTopDelta: any,
    hotDrum: any,
    hotDrumDelta: any,
    exchanger: any,
    exchangerDelta: any
  ) {
    return { name, towerTop, hotDrum, towerTopDelta, hotDrumDelta, exchanger, exchangerDelta };
  }

  const getReportData = () => {
    if (props.primaryReportData) {
      setPrimaryReportData(props.primaryReportData);
    } else {
      var temp: any = localStorage.getItem('primaryReportData') ? localStorage.getItem('primaryReportData') : '{}';
      setPrimaryReportData(JSON.parse(temp));
    }
    if (props.inputData) {
      setIonicInput(props.inputData);
    } else {
      var temp: any = localStorage.getItem('ionicInput') ? localStorage.getItem('ionicInput') : '{}';
      setIonicInput(JSON.parse(temp));
    }
    if (props.defaultUOM) {
      setDefaultUOM(props.defaultUOM);
    } else {
      var temp: any = localStorage.getItem('defaultUOM') ? localStorage.getItem('defaultUOM') : '{}';
      setDefaultUOM(JSON.parse(temp));
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  useEffect(() => {
    if (primaryReportData && Object.keys(primaryReportData).length > 0) {
      var key: any = Object.keys(primaryReportData)[0];
      var tempDate: any = `${new Date(key).getDate()} ${MONTHS[new Date(key).getMonth()]} ${new Date(key).getFullYear()} `;

      setSubHeadingReport(tempDate);
      setReportDate(key);
      var defaultData: any = '';
      if (props.configData) {
        defaultData = props.configData;
      } else if (localStorage.getItem('pageName') === 'All Simulation') {
        defaultData = localStorage.getItem('configDetails') ? localStorage.getItem('configDetails') : '{}';
        defaultData = JSON.parse(defaultData);
      } else {
        defaultData = localStorage.getItem('defaultConfig') ? localStorage.getItem('defaultConfig') : '';
        defaultData = JSON.parse(defaultData);
      }
      setConfigType(defaultData.refinery.unit[0].subUnit[0].configType);
      setUnitType(defaultData.refinery.unit[0].unitType.trim().toLowerCase());
      let subUnitName =
      ((defaultData.refinery.unit[0].subUnit[0].subUnitType && defaultData.refinery.unit[0].subUnit[0].subUnitType.trim().toLowerCase() === 'atm tower overhead') ||
      (defaultData.refinery.unit[0].subUnit[0].typeSubunit && defaultData.refinery.unit[0].subUnit[0].typeSubunit.trim().toLowerCase() === 'atm tower overhead'))
        ? ' Atmospheric Tower Overhead'
        : ((defaultData.refinery.unit[0].subUnit[0].subUnitType && defaultData.refinery.unit[0].subUnit[0].subUnitType.trim().toLowerCase() === 'prefractionator overhead') ||
        (defaultData.refinery.unit[0].subUnit[0].typeSubunit && defaultData.refinery.unit[0].subUnit[0].typeSubunit.trim().toLowerCase() === 'prefractionator overhead') ? 
        ' Prefractionator Overhead':
        ((defaultData.refinery.unit[0].subUnit[0].subUnitType && defaultData.refinery.unit[0].subUnit[0].subUnitType.trim().toLowerCase() === 'fractionator overhead') ||
        (defaultData.refinery.unit[0].subUnit[0].typeSubunit && defaultData.refinery.unit[0].subUnit[0].typeSubunit.trim().toLowerCase() === 'fractionator overhead') ? 
        'Fractionator Overhead':
        ((defaultData.refinery.unit[0].subUnit[0].subUnitType && defaultData.refinery.unit[0].subUnit[0].subUnitType.trim().toLowerCase() === 'main fractionator overhead') ||
        (defaultData.refinery.unit[0].subUnit[0].typeSubunit && defaultData.refinery.unit[0].subUnit[0].typeSubunit.trim().toLowerCase() === 'main fractionator overhead') ? 
        'Main Fractionator Overhead' : defaultData.refinery.unit[0].subUnit[0].subUnitType)));
        let unitTypeName = defaultData.refinery.unit[0].unitType.trim().toLowerCase() === 'crude'
        ? ' Crude Unit'
        : defaultData.refinery.unit[0].unitType;
          setSubUnitName(unitTypeName + ' | ' + subUnitName);
      if (defaultData.refinery.unit[0].subUnit[0].configType === '2A' || defaultData.refinery.unit[0].subUnit[0].configType === '2B') {
        setNeutralizerInjectedStage(defaultData.refinery.unit[0].subUnit[0].stageOvhdNeutralizerStream);
        setWaterWashInjectedStage(defaultData.refinery.unit[0].subUnit[0].stageOvhdWashStream);
      }
      setWaterWash(defaultData.refinery.unit[0].subUnit[0].waterWash);
      setNeutralizerInjected(defaultData.refinery.unit[0].subUnit[0].injectedNeutralizerStream);
      setDefaultConfig(defaultData);
      setColorIndicators(
        getColorIndicators(primaryReportData, key, ionicInput, defaultData.refinery.unit[0].subUnit[0], defaultUOM.temp_UOM)
      );
      const rows = [
        createTempTable(
          'Water Dew Point',
          primaryReportData[key].towerTop.waterDewPointTemp,
          primaryReportData[key].towerTop.waterDewPointTempDiff,
          primaryReportData[key].hotDrum ? primaryReportData[key].hotDrum.waterDewPointTemp : '',
          primaryReportData[key].hotDrum ? primaryReportData[key].hotDrum.waterDewPointTempDiff : '',
          primaryReportData[key].towerTop.waterDewPointTemp,
          primaryReportData[key].towerTop.waterDewPointTempDiff
        ),
        createTempTable(
          'Ammonia salt',
          primaryReportData[key].towerTop.ammoniaSaltTemp,
          primaryReportData[key].towerTop.ammoniaSaltTempDiff,
          primaryReportData[key].hotDrum ? primaryReportData[key].hotDrum.ammoniaSaltTemp : '',
          primaryReportData[key].hotDrum ? primaryReportData[key].hotDrum.ammoniaSaltTempDiff : '',
          primaryReportData[key].dewPoint.ammoniaSaltTempDP,
          primaryReportData[key].dewPoint.ammoniaSaltTempDiffDP
        ),
        defaultData.refinery.unit[0].unitType.trim().toLowerCase() !== 'coker' && defaultData.refinery.unit[0].unitType.trim().toLowerCase() !== 'fcc' ? createTempTable(
          'Neutralizer salt',
          primaryReportData[key].towerTop.neutralizerSaltTemp,
          primaryReportData[key].towerTop.neutralizerSaltTempDiff,
          primaryReportData[key].hotDrum ? primaryReportData[key].hotDrum.neutralizerSaltTemp : '',
          primaryReportData[key].hotDrum ? primaryReportData[key].hotDrum.neutralizerSaltTempDiff : '',
          primaryReportData[key].dewPoint.neutralizerSaltTempDP,
          primaryReportData[key].dewPoint.neutralizerSaltTempDiffDP
        ) : createTempTable('Relative Humidity', primaryReportData[key].towerTop.relativeHumidity,'','','','',''),
        createTempTable(
          'Tramp amine salt',
          primaryReportData[key].towerTop.trampAmineSaltTemp,
          primaryReportData[key].towerTop.trampAmineSaltTempDiff,
          primaryReportData[key].hotDrum ? primaryReportData[key].hotDrum.trampAmineSaltTemp : '',
          primaryReportData[key].hotDrum ? primaryReportData[key].hotDrum.trampAmineSaltTempDiff : '',
          primaryReportData[key].dewPoint.trampAmineSaltTempDP,
          primaryReportData[key].dewPoint.trampAmineSaltTempDiffDP
        ),
        createTempTable(
          'Steam amine salt',
          primaryReportData[key].towerTop.steamAmineSaltTemp,
          primaryReportData[key].towerTop.steamAmineSaltTempDiff,
          primaryReportData[key].hotDrum ? primaryReportData[key].hotDrum.steamAmineSaltTemp : '',
          primaryReportData[key].hotDrum ? primaryReportData[key].hotDrum.steamAmineSaltTempDiff : '',
          primaryReportData[key].dewPoint.steamAmineSaltTempDP,
          primaryReportData[key].dewPoint.steamAmineSaltTempDiffDP
        ),
      ];
      setRowData(rows);
    }
  }, [primaryReportData]);

  const getWaterWashLine = () => {
    var waterWashLines: any = '';
    if (waterWash) {
      waterWashLines = (
        <>
          <Circle x={920} y={70} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
          <Text
            x={930}
            y={65}
            text={'Water wash'}
            fontFamily="Poppins"
            fontSize={14}
            width={100}
            fontStyle="bold"
            height={100}
            fill="black"
          />
        </>
      );
    }
    return waterWashLines;
  };
  const getNeutralizerWashLines = () => {
    var neutralizerWashLines: any = '';

    if (waterWash) {
      if (
        (neutralizerInjectedStage === '1' || neutralizerInjectedStage == '2') &&
        waterWashInjectedStage === '1' &&
        neutralizerInjected?.toLowerCase() === BEFORE_WASH
      ) {
        neutralizerWashLines = (
          <>
            {/* Neutralizer */}
            <Line points={[100, 120, 190, 120, 190, 240, 235, 240]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
            {/* Water wash lines */}
            <Line points={[900, 70, 355, 70, 355, 110]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
            <Rect x={350} y={110} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
            <Line points={[355, 120, 355, 200]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
          </>
        );
      } else if (
        (neutralizerInjectedStage === '1' || neutralizerInjectedStage === '2') &&
        waterWashInjectedStage === '1' &&
        neutralizerInjected?.toLowerCase() === AFTER_WASH
      ) {
        neutralizerWashLines = (
          <>
            {/* Neutralizer */}
            <Line points={[100, 120, 355, 120]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
            {/* Water wash lines */}
            <Line points={[900, 70, 355, 70, 355, 115]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
            <Rect x={350} y={115} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
            <Line points={[355, 125, 355, 200]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
          </>
        );
      } else if (
        neutralizerInjectedStage === '1' &&
        waterWashInjectedStage === '2' &&
        (neutralizerInjected?.toLowerCase() === BEFORE_WASH || neutralizerInjected?.toLowerCase() === AFTER_WASH)
      ) {
        neutralizerWashLines = (
          <>
            {/* Neutralizer */}
            <Line points={[100, 120, 190, 120, 190, 240, 235, 240]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
            {/* Water wash lines */}
            <Line points={[900, 70, 715, 70, 715, 110]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
            <Rect x={710} y={110} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
            <Line points={[715, 120, 715, 200]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
          </>
        );
      } else if (neutralizerInjectedStage === '2' && waterWashInjectedStage === '2' && neutralizerInjected?.toLowerCase() === AFTER_WASH) {
        neutralizerWashLines = (
          <>
            {/* Neutralizer */}
            <Line points={[100, 120, 712, 120]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
            {/* Water wash lines */}
            <Line points={[900, 70, 715, 70, 715, 115]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
            <Rect x={710} y={115} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
            <Line points={[715, 125, 715, 200]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
          </>
        );
      } else if (neutralizerInjectedStage === '2' && waterWashInjectedStage === '2' && neutralizerInjected?.toLowerCase() === BEFORE_WASH) {
        neutralizerWashLines = (
          <>
            {/* Neutralizer */}
            <Line points={[100, 120, 580, 120, 580, 255, 630, 255]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
            {/* Water wash lines */}
            <Line points={[900, 70, 715, 70, 715, 115]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
            <Rect x={710} y={115} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
            <Line points={[715, 125, 715, 200]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
          </>
        );
      }
    } else {
      if (neutralizerInjectedStage === '1') {
        neutralizerWashLines = <Line points={[100, 120, 200, 120, 200, 242, 245, 242]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />;
      } else {
        neutralizerWashLines = <Line points={[100, 120, 600, 120, 600, 255, 640, 255]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />;
      }
    }
    return neutralizerWashLines;
  };

  return (
    <React.Fragment>
      {Object.keys(defaultConfig).length > 0 && (
        <div style={{ minHeight: '1975px' }}>
           <div className="corrosion-risk-div">
            <label className="corrosion-risk-refinery">{mainText}</label>
          </div>
          <div className="corrosion-risk-div margin-top-22">
            <label className="corrosion-risk-refinery font-size-30">{subText}</label>
          </div>
          {props.modeType === 'Actual' ? (
          <div className="corrosion-risk-div margin-top-22">
          <label className="corrosion-risk-label">Corrosion risk report &nbsp; </label>
          <label className="primary-subheading">{subHeadingReport}</label>
          </div>
          ):(
            <div className="corrosion-risk-div sandbox-title">
            <label className="corrosion-risk-label margin-left-13">Corrosion risk report (Sandbox) &nbsp; </label>
            <label className="primary-subheading">{subHeadingReport} , {props.sandBoxSimulationName}</label>
          </div>
          )}
          <div className="primary-report-pdf-unit">
            <p className="primary-report-label">Primary report</p>
            <p className="primary-report-atm">{subUnitName}</p>
          </div>
          <div className="report-diagram-pdf">
            <Stage width={1500} height={680} ref={svgRef} id="reportpdf">
              {configType === '1A' || configType === '1B' ? (
                <Layer>
                  {/* Tower Top */}
                  <Circle
                    x={240}
                    y={295}
                    width={80}
                    fill={`rgb(${colorIndicators.towerTop.colorRed}, ${colorIndicators.towerTop.colorGreen}, ${colorIndicators.towerTop.colorBlue})`}
                    stroke={'rgba(20, 125, 100, 1)'}
                  />
                  {/* Tower */}

                  <Rect
                    x={200}
                    y={300}
                    width={80}
                    height={200}
                    fill={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                    shadowBlur={0}
                    stroke={'rgba(20, 125, 100, 1)'} // border color
                  />
                  <Wedge
                    x={201}
                    y={480}
                    radius={43.5}
                    angle={90}
                    fill={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                    rotation={360}
                    stroke={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                    strokeWidth={1}
                  />
                  <Wedge x={280} y={510} radius={41.5} angle={180} fill="white" rotation={-180} stroke={'white'} strokeWidth={1} />

                  {/* Neutralizer namings */}
                  { (unitType !== 'coker' && unitType !== 'fcc') && (
                  <>
                  <Circle x={90} y={107} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                  <Text
                    x={100}
                    y={100}
                    text={'Neutralizer'}
                    fontFamily="Poppins"
                    fontSize={20}
                    width={200}
                    fontStyle="bold"
                    height={100}
                    fill="black"
                  />
                  <Text
                    x={100}
                    y={120}
                    text={ionicInput.overheadWater.neutralizerName}
                    fontFamily="Poppins"
                    fontSize={20}
                    width={300}
                    height={100}
                    fill="rgba(80, 108, 101, 1)"
                  />
                  </>
                  )}
                  {/* Tower Top */}
                  <Circle x={90} y={305} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                  <Text
                    x={100}
                    y={300}
                    text={'Tower Top'}
                    fontFamily="Poppins"
                    fontSize={20}
                    width={100}
                    fontStyle="bold"
                    height={100}
                    fill="black"
                  />
                  {/* Neutralizer with water wash*/}
                  {waterWash ? (
                    <>
                      {/* Neutralizer line */}
                      { (unitType !== 'coker' && unitType !== 'fcc') && (
                      <>
                      {neutralizerInjected?.toLowerCase() === BEFORE_WASH ? (
                        <Line points={[100, 150, 295, 150, 295, 200]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
                      ) : (
                        <Line points={[100, 150, 700, 150, 700, 229]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
                      )}
                       </>
                      )}

                      {/* Wash lines */}

                      <Line points={[800, 234, 705, 234]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
                      <Line points={[695, 234, 600, 234]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
                      <Rect x={695} y={229} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                      {/* Water wash label */}
                      <Circle x={830} y={230} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />

                      <Text
                        x={840}
                        y={222}
                        text={'Water wash'}
                        fontFamily="Poppins"
                        fontSize={20}
                        width={100}
                        fontStyle="bold"
                        height={100}
                        fill="black"
                      />
                    </>
                  ) : (
                    (unitType !== 'coker' && unitType !== 'fcc') && 
                    <Line points={[100, 150, 295, 150, 295, 200]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
                  )}

                  {/* Reflux */}
                  <Line
                    points={[350, 525, 350, 355, 293, 355]}
                    stroke={`rgb(${colorIndicators.refluxPipe.colorRed}, ${colorIndicators.refluxPipe.colorGreen}, ${colorIndicators.refluxPipe.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Line //arrow
                    points={[290, 350, 290, 362, 286, 357]}
                    strokeWidth={4}
                    stroke="black"
                    closed={true}
                  />
                  <Text
                    x={300}
                    y={540}
                    text={ionicInput.liquidAssayStreams.isWetReflux ? 'Wet Reflux' : 'Dry Reflux'}
                    fontFamily="Poppins"
                    fontSize={20}
                    width={100}
                    fontStyle="bold"
                    height={100}
                    fill="black"
                  />

                  {/* Ovhd 1 */}
                  <Line
                    points={[240, 254, 240, 200, 290, 200]}
                    stroke={`rgb(${colorIndicators.ovhd1.colorRed}, ${colorIndicators.ovhd1.colorGreen}, ${colorIndicators.ovhd1.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={290} y={195} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                  {/* Ovhd 2 */}
                  <Line
                    points={[300, 200, 600, 200, 600, 230]}
                    stroke={`rgb(${colorIndicators.ovhd2.colorRed}, ${colorIndicators.ovhd2.colorGreen}, ${colorIndicators.ovhd2.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={595} y={230} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                  {/* Ovhd 3 */}
                  <Line
                    points={[600, 240, 600, 400]}
                    stroke={`rgb(${colorIndicators.ovhd3.colorRed}, ${colorIndicators.ovhd3.colorGreen}, ${colorIndicators.ovhd3.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={595} y={400} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                  {/* Ovhd 4 */}
                  <Line
                    points={[600, 410, 600, 450, 630, 450]}
                    stroke={`rgb(${colorIndicators.ovhd4.colorRed}, ${colorIndicators.ovhd4.colorGreen}, ${colorIndicators.ovhd4.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={630} y={445} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
                  {/* Ovhd 5 */}
                  <Line
                    points={[640, 450, 705, 450]}
                    stroke={`rgb(${colorIndicators.ovhd5.colorRed}, ${colorIndicators.ovhd5.colorGreen}, ${colorIndicators.ovhd5.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Line //arrow
                    points={[708, 445, 713, 450, 708, 455]}
                    strokeWidth={5}
                    stroke="black"
                    closed={true}
                  />
                  {/* Exchanger */}
                  <Circle
                    x={762}
                    y={450}
                    width={90}
                    fill={`rgb(${colorIndicators.exchanger.colorRed}, ${colorIndicators.exchanger.colorGreen}, ${colorIndicators.exchanger.colorBlue})`}
                    stroke={'rgba(20, 125, 100, 1)'}
                  />
                  <Circle x={830} y={445} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                  <Text
                    x={840}
                    y={440}
                    text={'Exchanger'}
                    fontFamily="Poppins"
                    fontSize={20}
                    width={200}
                    fontStyle="bold"
                    height={100}
                    fill="black"
                  />
                </Layer>
              ) : (
                <Layer>
                  {/* Tower Top */}
                  <Circle
                    x={240}
                    y={317}
                    width={80}
                    fill={`rgb(${colorIndicators.towerTop.colorRed}, ${colorIndicators.towerTop.colorGreen}, ${colorIndicators.towerTop.colorBlue})`}
                    stroke={'rgba(20, 125, 100, 1)'}
                  />
                  {/* Tower */}

                  <Rect
                    x={200}
                    y={325}
                    width={80}
                    height={200}
                    fill={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                    shadowBlur={0}
                    stroke={'rgba(20, 125, 100, 1)'} // border color
                  />
                  <Wedge
                    x={201}
                    y={510}
                    radius={43.5}
                    angle={90}
                    fill={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                    rotation={360}
                    stroke={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                    strokeWidth={1}
                  />
                  <Wedge x={280} y={540} radius={41.5} angle={180} fill="white" rotation={-180} stroke={'white'} strokeWidth={1} />

                  {/* Neutralizer namings */}
                  <Circle x={90} y={77} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                  <Text
                    x={100}
                    y={70}
                    text={'Neutralizer'}
                    fontFamily="Poppins"
                    fontSize={20}
                    width={150}
                    fontStyle="bold"
                    height={100}
                    fill="black"
                  />
                  <Text
                    x={100}
                    y={90}
                    text={ionicInput.overheadWater.neutralizerName}
                    fontFamily="Poppins"
                    fontSize={20}
                    width={400}
                    height={100}
                    fill="rgba(80, 108, 101, 1)"
                  />

                  {/* Tower Top */}
                  <Circle x={80} y={305} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                  <Text
                    x={90}
                    y={300}
                    text={'Tower Top'}
                    fontFamily="Poppins"
                    fontSize={20}
                    width={120}
                    fontStyle="bold"
                    height={100}
                    fill="black"
                  />
                  {/* Neutralizer with water wash*/}
                  <>
                    {/* Neutralizer line */}
                    {(unitType !== 'coker' && unitType !== 'fcc') && getNeutralizerWashLines()}
                    {/* Water wash label */}

                    {getWaterWashLine()}
                  </>
                  {/* Reflux */}
                  <Line
                    points={[350, 525, 350, 355, 293, 355]}
                    stroke={`rgb(${colorIndicators.refluxPipe.colorRed}, ${colorIndicators.refluxPipe.colorGreen}, ${colorIndicators.refluxPipe.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Line //arrow
                    points={[290, 350, 290, 362, 286, 357]}
                    strokeWidth={4}
                    stroke="black"
                    closed={true}
                  />
                  <Text
                    x={300}
                    y={540}
                    text={ionicInput.liquidAssayStreams.isWetReflux ? 'Wet Reflux' : 'Dry Reflux'}
                    fontFamily="Poppins"
                    fontSize={20}
                    width={200}
                    fontStyle="bold"
                    height={100}
                    fill="black"
                  />

                  {/* Ovhd 1 */}
                  <Line
                    points={[240, 277, 240, 245]}
                    stroke={`rgb(${colorIndicators.ovhd1.colorRed}, ${colorIndicators.ovhd1.colorGreen}, ${colorIndicators.ovhd1.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={235} y={237} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                  {/* Ovhd 2 */}
                  <Line
                    points={[240, 237, 240, 200, 350, 200]}
                    stroke={`rgb(${colorIndicators.ovhd2.colorRed}, ${colorIndicators.ovhd2.colorGreen}, ${colorIndicators.ovhd2.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={350} y={195} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                  {/* Ovhd 3 */}
                  <Line
                    points={[360, 200, 475, 200]}
                    stroke={`rgb(${colorIndicators.ovhd3.colorRed}, ${colorIndicators.ovhd3.colorGreen}, ${colorIndicators.ovhd3.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={475} y={195} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                  {/* Ovhd 4 */}
                  <Line
                    points={[485, 200, 550, 200, 550, 230]}
                    stroke={`rgb(${colorIndicators.ovhd4.colorRed}, ${colorIndicators.ovhd4.colorGreen}, ${colorIndicators.ovhd4.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={545} y={230} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                  {/* Ovhd 5 */}
                  <Line
                    points={[550, 240, 550, 275]}
                    stroke={`rgb(${colorIndicators.ovhd5.colorRed}, ${colorIndicators.ovhd5.colorGreen}, ${colorIndicators.ovhd5.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Line //arrow
                    points={[545, 275, 555, 275, 550, 280]}
                    strokeWidth={5}
                    stroke="black"
                    closed={true}
                  />
                  {/* Exchanger */}
                  <Circle
                    x={552}
                    y={322}
                    width={75}
                    fill={`rgb(${colorIndicators.exchanger.colorRed}, ${colorIndicators.exchanger.colorGreen}, ${colorIndicators.exchanger.colorBlue})`}
                    stroke={'rgba(20, 125, 100, 1)'}
                  />
                  <Circle x={320} y={235} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                  <Text
                    x={330}
                    y={230}
                    text={'Exchanger'}
                    fontFamily="Poppins"
                    fontSize={20}
                    width={200}
                    fontStyle="bold"
                    height={100}
                    fill="black"
                  />

                  {/* 1Stage 1 */}
                  <Line
                    points={[553, 360, 553, 420]}
                    stroke={`rgb(${colorIndicators.oneStage1.colorRed}, ${colorIndicators.oneStage1.colorGreen}, ${colorIndicators.oneStage1.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Line //arrow
                    points={[548, 420, 558, 420, 553, 425]}
                    strokeWidth={5}
                    stroke="black"
                    closed={true}
                  />

                  {/* Hot Drum */}
                  <Circle
                    x={540}
                    y={465}
                    width={70}
                    fill={`rgb(${colorIndicators.hotDrumLeft.colorRed}, ${colorIndicators.hotDrumLeft.colorGreen}, ${colorIndicators.hotDrumLeft.colorBlue})`}
                    stroke={'rgba(20, 125, 100, 1)'}
                  />
                  <Circle
                    x={640}
                    y={465}
                    width={70}
                    fill={`rgb(${colorIndicators.hotDrumRight.colorRed}, ${colorIndicators.hotDrumRight.colorGreen}, ${colorIndicators.hotDrumRight.colorBlue})`}
                    stroke={'rgba(20, 125, 100, 1)'}
                  />
                  <Rect
                    x={545}
                    y={430}
                    width={90}
                    height={70}
                    fill={`rgb(${colorIndicators.hotDrumCenter.colorRed}, ${colorIndicators.hotDrumCenter.colorGreen}, ${colorIndicators.hotDrumCenter.colorBlue})`}
                    shadowBlur={0}
                    stroke={'rgba(20, 125, 100, 1)'} // border color
                  />
                  {/* Hot drum namings */}
                  <Circle x={705} y={465} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                  <Text
                    x={715}
                    y={460}
                    text={'Hot drum'}
                    fontFamily="Poppins"
                    fontSize={20}
                    width={100}
                    fontStyle="bold"
                    height={100}
                    fill="black"
                  />
                  {/* 2Stage 1 */}
                  <Line
                    points={[635, 429, 635, 260]}
                    stroke={`rgb(${colorIndicators.twoStage1.colorRed}, ${colorIndicators.twoStage1.colorGreen}, ${colorIndicators.twoStage1.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={630} y={250} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                  {/* 2Stage 2 */}
                  <Line
                    points={[635, 250, 635, 200, 720, 200]}
                    stroke={`rgb(${colorIndicators.twoStage2.colorRed}, ${colorIndicators.twoStage2.colorGreen}, ${colorIndicators.twoStage2.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={710} y={195} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                  {/* 2Stage 3 */}
                  <Line
                    points={[720, 200, 800, 200, 820, 200]}
                    stroke={`rgb(${colorIndicators.twoStage3.colorRed}, ${colorIndicators.twoStage3.colorGreen}, ${colorIndicators.twoStage3.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={820} y={195} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                  {/* 2Stage 4 */}
                  <Line
                    points={[830, 200, 900, 200, 900, 230]}
                    stroke={`rgb(${colorIndicators.twoStage4.colorRed}, ${colorIndicators.twoStage4.colorGreen}, ${colorIndicators.twoStage4.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Rect x={895} y={230} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                  {/* 2Stage 5 */}
                  <Line
                    points={[900, 240, 900, 280]}
                    stroke={`rgb(${colorIndicators.twoStage5.colorRed}, ${colorIndicators.twoStage5.colorGreen}, ${colorIndicators.twoStage5.colorBlue})`}
                    strokeWidth={5}
                  />
                  <Line //arrow
                    points={[895, 280, 905, 280, 900, 285]}
                    strokeWidth={5}
                    stroke="black"
                    closed={true}
                  />
                  {/* Exchanger 2 */}
                  <Circle
                    x={900}
                    y={327}
                    width={75}
                    fill={`rgb(${colorIndicators.exchanger2.colorRed}, ${colorIndicators.exchanger2.colorGreen}, ${colorIndicators.exchanger2.colorBlue})`}
                    stroke={'rgba(20, 125, 100, 1)'}
                  />
                </Layer>
              )}
            </Stage>
          </div>
          <div style={{ margin: '6%' }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell align="right">Tower top T / ΔT</StyledTableCell>
                    <StyledTableCell align="right">Exchanger T / ΔT</StyledTableCell>
                    {configType === '2A' || configType === '2B' ? (
                      <StyledTableCell
                        style={{
                          color: 'rgba(80, 108, 101, 1)',
                          fontFamily: 'Poppins,sans-serif',
                          fontWeight: 600,
                        }}
                        align="right"
                      >
                        Hot drum T / ΔT
                      </StyledTableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData.map((row: any) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      {row.name !== 'Relative Humidity' ?
                      (Math.round(row.towerTop) < -40 ? (
                        configType === '2A' || configType === '2B' ? (
                          <StyledTableCell style={{ paddingLeft: '12.5%' }}>N/A</StyledTableCell>
                        ) : (
                          <StyledTableCell style={{ paddingLeft: '19.5%' }}>N/A</StyledTableCell>
                        )
                      ) : (
                        Math.round(row.towerTop) > 0 ? (
                        <StyledTableCell align="right">
                          {Math.round(row.towerTop)} {'°' + defaultUOM.temp_UOM} / {getTempSign(row.towerTopDelta)}
                          {Math.round(row.towerTopDelta)} {'°' + defaultUOM.temp_UOM}
                        </StyledTableCell>
                      ):
                    (
                      <StyledTableCell style={{ paddingLeft: '19.5%' }}>
                      {Math.round(row.towerTop)} {'°' + defaultUOM.temp_UOM} / {getTempSign(row.towerTopDelta)}
                      {Math.round(row.towerTopDelta)} {'°' + defaultUOM.temp_UOM}
                    </StyledTableCell>
                    )))
                    :(
                      <StyledTableCell style={{ paddingLeft: '19.5%' }}>
                      {Math.round(row.towerTop)}%
                    </StyledTableCell>
                    )}
                     {row.name !== 'Relative Humidity' ?
                      (Math.round(row.exchanger) < -40 ? (
                        configType === '2A' || configType === '2B' ? (
                          <StyledTableCell style={{ paddingLeft: '13.5%' }}>N/A</StyledTableCell>
                        ) : (
                          <StyledTableCell style={{ paddingLeft: '21.5%' }}>N/A</StyledTableCell>
                        )
                      ) : row.name === 'Water Dew Point' ? (
                        configType === '2A' || configType === '2B' ? (
                          <StyledTableCell style={{ paddingLeft: '13.5%' }}>
                            {Math.round(row.exchanger)} {'°' + defaultUOM.temp_UOM}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell style={{ paddingLeft: '21.5%' }}>
                            {Math.round(row.exchanger)} {'°' + defaultUOM.temp_UOM}
                          </StyledTableCell>
                        )
                      ) : (
                        <StyledTableCell align="right">
                          {Math.round(row.exchanger)} {'°' + defaultUOM.temp_UOM} / {getTempSign(row.exchangerDelta)}
                          {Math.round(row.exchangerDelta)} {'°' + defaultUOM.temp_UOM}
                        </StyledTableCell>
                      )):
                      (
                        <StyledTableCell style={{ paddingLeft: '21.5%' }}>N/A</StyledTableCell>
                      )}
                      {configType === '2A' || configType === '2B' ? (
                        Math.round(row.hotDrum) < -40 ? (
                          <StyledTableCell style={{ paddingLeft: '12.5%' }}>N/A</StyledTableCell>
                        ) : (
                          <StyledTableCell align="right">
                            {Math.round(row.hotDrum)} {'°' + defaultUOM.temp_UOM} / {getTempSign(row.hotDrumDelta)}
                            {Math.round(row.hotDrumDelta)} {'°' + defaultUOM.temp_UOM}
                          </StyledTableCell>
                        )
                      ) : null}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="overhead-line-div">
            <p className="overhead-header">Overhead Line</p>
            <div className="overhead-div" style={{ display: 'flex' }}>
              <p className="overhead-label">Required Wash Rate</p>
              <p className="overhead-val">
                {Math.round(primaryReportData[reportDate].overHeadLine.requiredWashRate)} {defaultUOM.washRate_UOM}
              </p>
              {(unitType !== 'coker' && unitType !== 'fcc') ? 
              (<>
              <p className="overhead-label-50">Neutralizer Salt Formation Temp (After Injection)</p>
              <p className="overhead-val">
                {Math.round(primaryReportData[reportDate].overHeadLine.neutralizerSaltTempOL)} {'°' + defaultUOM.temp_UOM}
              </p>
              </>):
               (<>
                <p className="overhead-label-50">% of Wash Rate Condensed</p>
                <p className="overhead-val">
                {Math.round(primaryReportData[reportDate].dewPoint.nh4HSInDrumWaterPercentage * 100)}%
                </p>
                </>)
              }
            </div>
            <div className="overhead-div" style={{ display: 'flex' }}>
              <p className="overhead-label">Wash Rate Excess Over Saturation</p>
              <p className="overhead-val"> {Math.round(primaryReportData[reportDate].overHeadLine.washRateCondensedPerc * 100)}%</p>
              {(unitType !== 'coker' && unitType !== 'fcc') ? 
              (<>
              <p className="overhead-label-50">Neutralizer Salt ΔT (After Injection)</p>
              <p className="overhead-val">
                {getTempSign(primaryReportData[reportDate].overHeadLine.neutralizerSaltTempDiffOL)}
                {Math.round(primaryReportData[reportDate].overHeadLine.neutralizerSaltTempDiffOL)} {'°' + defaultUOM.temp_UOM}
              </p>
              </>)
              :
              (<>
              <p className="overhead-label-50">% NH4HS after wash</p>
              <p className="overhead-val">
                <>
                {Math.round(primaryReportData[reportDate].overHeadLine.nh4HSAfterWashPerc * 100) / 100}%
               </>
              </p>
              </>)
              }
            </div>
            <div className="overhead-div" style={{ display: 'flex' }}>
              <p style={{ width: '35%' }} className="overhead-label">
                Velocity
              </p>
              <p className="overhead-val">
                {' '}
                {Math.round(primaryReportData[reportDate].overHeadLine.velocity)} {defaultUOM.velocity_UOM}
              </p>
            </div>
          </div>
          <hr className="seperator-line" style={{ marginTop: '10%' }} />
          <div style={{ marginLeft: '5%' }}>
            <p className="footer-bh-pdf">bakerhughes.com</p>
            <div style={{ marginTop: '0.5rem', display: 'flex' }}>
              <p className="footer-copyright-pdf">Copyright 2024 Baker Hughes Company. All rights reserved.</p>
              <p className="footer-page-num-pdf">Page 01</p>
            </div>
          </div>
        </div>
      )}
      <div className="recommendations-container">
        <div className="corrosion-risk-pdf-header">
          <p className="corrosion-risk-header">TOPGUARD™ Corrosion Risk Monitor</p>
          <img
            className="bh-logo-pdf"
            src="/assets/images/bh_pdf_logo.jpg
          "
          ></img>
        </div>
        <div className="corrosion-risk-div">
            <label className="corrosion-risk-refinery">{mainText}</label>
          </div>
          <div className="corrosion-risk-div margin-top-22">
            <label className="corrosion-risk-refinery font-size-30">{subText}</label>
          </div>
          {props.modeType === 'Actual' ? (
          <div className="corrosion-risk-div margin-top-22">
          <label className="corrosion-risk-label">Corrosion risk report &nbsp; </label>
          <label className="primary-subheading">{subHeadingReport}</label>
          </div>
          ):(
            <div className="corrosion-risk-div sandbox-title">
            <label className="corrosion-risk-label margin-left-13">Corrosion risk report (Sandbox) &nbsp; </label>
            <label className="primary-subheading">{subHeadingReport} , {props.sandBoxSimulationName}</label>
          </div>
          )}
        <hr className="seperator-line" />
        <p className="recommendations-header">Recommendations:</p>
        <div className="comments-val">{props.recommComments}</div>
      </div>
      <hr className="seperator-line"></hr>
      <div style={{ marginLeft: '5%' }}>
        <p className="footer-bh-pdf">bakerhughes.com</p>
        <div style={{ marginTop: '0.5rem', display: 'flex' }}>
          <p className="footer-copyright-pdf">Copyright 2024 Baker Hughes Company. All rights reserved.</p>
          <p className="footer-page-num-pdf">Page 02</p>
        </div>
      </div>
    </React.Fragment>
  );
}
