/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import {
  BhAccordion,
  BhSpinner,
  BhCard,
  BhDialog,
  BhDropdown,
  BhFormMessage,
  BhTextInput,
  BhTitleWrapper,
  BhTooltip,
} from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import simulationDataForm from '../../hooks/simulationDataForm';
import './SimulationData.scss';
import * as rangeConstants from '../../constants/input-range';
import { changeToShortFormat } from '../../utils/utils';
import { APIEnum } from '../../constants/api-enum';
import apiRequest from '../../services/api-helper';
import { Backdrop } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ionicInputFor1A } from './helper/InputForOneA';
import { ionicInputFor1B } from './helper/InputForOneB';
import { ionicInputFor2A } from './helper/InputForTwoA';
import { ionicInputFor2B } from './helper/InputForTwoB';
import {
  getLabel,
  getToolTipForOverheadWaterToSWS,
  getToolTipForTotalSSWToDesalterWashWater,
  getToolTipForWaterToDesalter,
  validateSimulationData,
  validateSimulationManual,
} from './helper/helper';

declare const window: any;

export default function SimulationData() {
  const navigate = useNavigate();
  const [subText, setSubText] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [simulationData, setSimulationData] = useState<any>({});
  const [crudeChargeUnits, setCrudeChargeUnits] = useState<any>({});
  const [productUnits, setProductUnits] = useState<any>({});
  const [refluxProductUnits, setRefluxProductUnits] = useState<any>({});
  const [neutralizerData, setNeutralizerData] = useState<any>({});
  const [neutralizerUnits, setNeutralizerUnits] = useState<any>({});
  const [deslaterTempUnits, setDeslaterTempUnits] = useState<any>({});
  const [deslaterTempUOM, setDeslaterTempUOM] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setSuccessFlag] = useState(false);
  const [steamTowerUnits, setSteamTowerUnits] = useState<any>({});
  const [steamUnits, setSteamUnits] = useState<any>({});
  const [amineUnits, setAmineUnits] = useState<any>({});
  const [pressureUnits, setPressureUnits] = useState<any>({});
  const [washRateUnits, setWashRateUnits] = useState<any>({});
  const [offGasUnits, setOffGasUnits] = useState<any>({});
  const [configType, setConfigType] = useState('');
  const [isDeleteDialogueOpen, setisDeleteDialogueOpen] = useState<boolean>(false);
  const [isErrorDialogueOpen, setIsErrorDialogueOpen] = useState<boolean>(false);
  const [errorHeader, setErrorHeader] = useState('');
  const [today, setToday] = useState<Date>(new Date());
  const [wetRefluxItems, setWetRefluxItems] = useState<any>();
  const [distillationTypeItems, setDistillationTypeItems] = useState<any>();

  const [expandedPanelDesalter, setexpandedDesalter] = useState(true);
  const [expandedPanelTower, setexpandedPanelTower] = useState(false);
  const [expandedPanelOverhead, setexpandedPanelOverhead] = useState(false);
  const [expandedPanelHydrocarbon, setexpandedPanelHydrocarbon] = useState(false);
  const [expandedPanelOffgas, setexpandedPanelOffgas] = useState(false);
  const [expandedPanelOverheadWater, setexpandedPanelOverheadWater] = useState(false);

  const [brineSampleTempErrorText, setBrineSampleTempErrorText] = useState('');
  const [ohTempErrorText, setOhtempTempErrorText] = useState('');
  const [washTempErrorText, setWashTempErrorText] = useState('');
  const [washProcessTempErrorText, setWashProcessTempErrorText] = useState('');
  const [velocityTempErrorText, setVelocityTempErrorText] = useState('');
  const [drumTempErrorText, setDrumTempErrorText] = useState('');
  const [distillation0ErrorText, setDistillation0ErrorText] = useState('');
  const [distillation0_1ErrorText, setDistillation0_1ErrorText] = useState('');
  const [outsideNeutralizerText, setOutsideNeutralizerText] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalExternalErrorText, setTotalExternalErrorText] = useState('');

  const [simulationId, setSimulationId] = useState(searchParams?.get('id'));
  const [formattedDate, setFormattedDate] = useState('');
  const [subcategoryInputMapping, setSubcategoryInputMapping] = useState<any>({});
  const [isDefaultCrudeChargeUnitChanged, setDefaultCrudeChargeUnitChanged] = useState(false);
  const [isDefaultOhPressUnitChanged, setDefaultOhPressUnitChanged] = useState(false);
  const [isDefaultSteam6UnitChanged, setDefaultSteam6UnitChanged] = useState(false);
  const [isDefaultSteam5UnitChanged, setDefaultSteam5UnitChanged] = useState(false);
  const [isDefaultSteam4UnitChanged, setDefaultSteam4UnitChanged] = useState(false);
  const [isDefaultSteam3UnitChanged, setDefaultSteam3UnitChanged] = useState(false);
  const [isDefaultSteam2UnitChanged, setDefaultSteam2UnitChanged] = useState(false);
  const [isDefaultSteam1UnitChanged, setDefaultSteam1UnitChanged] = useState(false);
  const [isDefaultStrippingSteamUnitChanged, setDefaultStrippingSteamUnitChanged] = useState(false);
  const [isDefaultDrumPressUnitChanged, setDefaultDrumPressUnitChanged] = useState(false);
  const [isDefaultOffGasUnitChanged, setDefaultOffGasUnitChanged] = useState(false);
  const [isDefaultRefluxUnitChanged, setDefaultRefluxUnitChanged] = useState(false);
  const [isDefaultProductUnitChanged, setDefaultProductUnitChanged] = useState(false);
  const [isDefaultReflux1UnitChanged, setDefaultReflux1UnitChanged] = useState(false);
  const [isDefaultProduct1UnitChanged, setDefaultProduct1UnitChanged] = useState(false);
  const [isDefaultNeutralizerRateUnitChanged, setDefaultNeutralizerRateUnitChanged] = useState(false);
  const [isDefaultWashRateUnitChanged, setDefaultWashRateUnitChanged] = useState(false);
  const [isDefaultVelocityPressUnitChanged, setDefaultVelocityPressUnitChanged] = useState(false);
  const [isDefaultWashProcessPressUnitChanged, setDefaultWashProcessPressUnitChanged] = useState(false);
  const [isDefaultDesalterTempUnitChanged, setDefaultDesalterTempUnitChanged] = useState(false);
  // new
  const [isDefaultCombinedRefluxFlowChanged, setDefaultCombinedRefluxFlowChanged] = useState(false);

  //sandbox mode changes
  const [simulationName, setSimulationName] = useState('');
  const [comments, setComments] = useState('');

  //coker
  const [unitType, setUnitType] = useState<string>('');
  const [isWithDesalter, setWithDesalter] = useState(false);

  const handleDesalterPanelChange = (event: any, isExpanded: boolean) => {
    if ((unitType !== 'coker' && unitType !== 'fcc') || ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter)) {
      setexpandedDesalter(isExpanded ? false : true);
      if (!isExpanded) {
        setexpandedPanelTower(false);
        setexpandedPanelOverhead(false);
        setexpandedPanelHydrocarbon(false);
        setexpandedPanelOverheadWater(false);
        setexpandedPanelOffgas(false);
      }
    }
  };

  const handleTowerPanelChange = (event: any, isExpanded: boolean) => {
    setexpandedPanelTower(isExpanded ? false : true);
    if (!isExpanded) {
      setexpandedDesalter(false);
      setexpandedPanelOverhead(false);
      setexpandedPanelHydrocarbon(false);
      setexpandedPanelOverheadWater(false);
      setexpandedPanelOffgas(false);
    }
  };

  const handleOverheadPanelChange = (event: any, isExpanded: boolean) => {
    setexpandedPanelOverhead(isExpanded ? false : true);
    if (!isExpanded) {
      setexpandedDesalter(false);
      setexpandedPanelTower(false);
      setexpandedPanelHydrocarbon(false);
      setexpandedPanelOverheadWater(false);
      setexpandedPanelOffgas(false);
    }
  };

  const handleHydrocarbonPanelChange = (event: any, isExpanded: boolean) => {
    setexpandedPanelHydrocarbon(isExpanded ? false : true);
    if (!isExpanded) {
      setexpandedDesalter(false);
      setexpandedPanelTower(false);
      setexpandedPanelOverhead(false);
      setexpandedPanelOverheadWater(false);
      setexpandedPanelOffgas(false);
    }
  };

  const handleOverheadWaterPanelChange = (event: any, isExpanded: boolean) => {
    setexpandedPanelOverheadWater(isExpanded ? false : true);
    if (!isExpanded) {
      setexpandedDesalter(false);
      setexpandedPanelTower(false);
      setexpandedPanelOverhead(false);
      setexpandedPanelHydrocarbon(false);
      setexpandedPanelOffgas(false);
    }
  };

  const handleOffgasPanelChange = (event: any, isExpanded: boolean) => {
    setexpandedPanelOffgas(isExpanded ? false : true);
    if (!isExpanded) {
      setexpandedDesalter(false);
      setexpandedPanelTower(false);
      setexpandedPanelOverhead(false);
      setexpandedPanelHydrocarbon(false);
      setexpandedPanelOverheadWater(false);
    }
  };

  //Final submit function
  const formSimulationData = () => {};

  //Custom hook call
  const { handleChange, values, errors, handleSubmit, validate, isFormValid } = simulationDataForm(formSimulationData);

  useEffect(() => {
    if (localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true') {
      let simulationDetails = localStorage.getItem('sandboxsimulation') && JSON.parse(localStorage.getItem('sandboxsimulation')!);
      if (simulationDetails && Object.keys(simulationDetails).length) {
        setSimulationName(simulationDetails.name);
        setComments(simulationDetails.comment);
      }
    }
    let configFromStorage: any = JSON.parse(localStorage.getItem('defaultConfig')!);
    if (configFromStorage && configFromStorage.refinery) {
      if (configFromStorage.refinery.unit && configFromStorage.refinery.unit[0]) {
        setUnitType(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase());
        if (configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'coker') {
          setWithDesalter(configFromStorage.refinery.unit[0].subUnit[0].cokerType === 'CD' ? true : false);
          configFromStorage.refinery.unit[0].subUnit[0].cokerType === 'CK' && setexpandedPanelTower(true);
        } else if (configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc') {
          setWithDesalter(configFromStorage.refinery.unit[0].subUnit[0].fccType === 'FD' ? true : false);
          configFromStorage.refinery.unit[0].subUnit[0].fccType === 'FC' && setexpandedPanelTower(true);
        } else setWithDesalter(false);
      }
    }
    setSubcategoryInputMapping(JSON.parse(localStorage.getItem('categoryInputMapping')!));
    setSimulationId(simulationId);
    setSubText(localStorage.getItem('subtitle')!);
    setConfigType(localStorage.getItem('configType')!);
    bindCrudeChargeUnitDropdown();
    bindNeutralizerDropDown();
    bindAmineUnitDropdown();
    bindDesalterTempUnitsDropDown();
    bindSteamTowerUnitsDropDown();
    bindSteamUnitsDropDown();
    bindPressureUnits();
    bindRefluxHotProductUnitDropdown();
    bindProductUnitDropdown();
    bindWashRateUnits();
    bindOffGasUnits(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase());
    bindNeutralizerUnits();
    bindSimulationData(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase());
    let wetReflux = [
      {
        label: 'TRUE',
        value: true,
      },
      {
        label: 'FALSE',
        value: false,
      },
    ];
    setWetRefluxItems(wetReflux);
    let distillationTypes = [
      {
        label: 'TBP',
        value: 'TBP',
      },
      {
        label: 'D86',
        value: 'D86',
      },
      {
        label: 'D2887',
        value: 'D2887',
      },
    ];
    setDistillationTypeItems(distillationTypes);
    if (!searchParams?.get('id')) {
      setRangeValues();
    }
  }, []);

  const bindNeutralizerUnits = () => {
    let neutralizerUnits = require('../../constants/neutralizer-units.json');
    setNeutralizerUnits(neutralizerUnits);
    let defaultUnitFromStorage: any;
    let defaultUnitMin;
    let defaultUnitMax;
    let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
    defaultUnitFromStorage =
      categoryInputMapping &&
      Object.keys(categoryInputMapping).length &&
      categoryInputMapping.Overhead_Neutralizer_Rate &&
      Object.keys(categoryInputMapping.Overhead_Neutralizer_Rate).length &&
      categoryInputMapping.Overhead_Neutralizer_Rate[0] &&
      categoryInputMapping.Overhead_Neutralizer_Rate[0].unitSymbol;
    defaultUnitMin =
      neutralizerUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      neutralizerUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
    defaultUnitMax =
      neutralizerUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      neutralizerUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
    //setting first item as selected in case of manual entry create screen
    if (localStorage.getItem('manualInput') === null && !searchParams?.get('id')) {
      setManualInput('neutra-rate-unit', defaultUnitFromStorage, defaultUnitMin, defaultUnitMax);
    } else if (localStorage.getItem('manualInput') !== null) {
      let manualInput = JSON.parse(localStorage.getItem('manualInput')!);
      let newSimulationObj;
      newSimulationObj = {
        ...manualInput,
        overheadWater: { ...manualInput.overheadWater },
      };
      newSimulationObj.overheadWater.neutralizerRate_UOM = defaultUnitFromStorage;
      newSimulationObj.overheadWater.neutralizerRateMin = defaultUnitMin;
      newSimulationObj.overheadWater.neutralizerRateMax = defaultUnitMax;
      newSimulationObj.overheadWater.outsideNeutralizer_UOM = defaultUnitFromStorage;
      newSimulationObj.overheadWater.outsideNeutralizerMin = defaultUnitMin;
      newSimulationObj.overheadWater.outsideNeutralizerMax = defaultUnitMax;
      localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
      setSimulationData(newSimulationObj);
    }
  };

  const bindOffGasUnits = (unitType: any) => {
    let offGasUnits =
      unitType !== 'coker' && unitType !== 'fcc'
        ? require('../../constants/offgas-units.json')
        : require('../../constants/offgas-units-coker.json');
    setOffGasUnits(offGasUnits);
    let defaultUnitFromStorage: any;
    let defaultUnitMin;
    let defaultUnitMax;
    let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
    defaultUnitFromStorage =
      categoryInputMapping &&
      Object.keys(categoryInputMapping).length &&
      Object.keys(categoryInputMapping.Overhead_Gas_Flow).length &&
      categoryInputMapping.Overhead_Gas_Flow[0] &&
      categoryInputMapping.Overhead_Gas_Flow[0].unitSymbol;
    defaultUnitMin =
      offGasUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      offGasUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
    defaultUnitMax =
      offGasUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      offGasUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
    //setting first item as selected in case of manual entry create screen
    if (localStorage.getItem('manualInput') === null && !searchParams?.get('id')) {
      setManualInput('off-gas-unit', defaultUnitFromStorage, defaultUnitMin, defaultUnitMax);
    } else if (localStorage.getItem('manualInput') !== null) {
      let manualInput = JSON.parse(localStorage.getItem('manualInput')!);
      let newSimulationObj;
      newSimulationObj = {
        ...manualInput,
        offGasData: { ...manualInput.offGasData },
      };
      newSimulationObj.offGasData.offGas_UOM = defaultUnitFromStorage;
      newSimulationObj.offGasData.offGasMin = defaultUnitMin;
      newSimulationObj.offGasData.offGasMax = defaultUnitMax;
      localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
      setSimulationData(newSimulationObj);
    }
  };

  const bindWashRateUnits = () => {
    let washRateUnits = require('../../constants/washrate-units.json');
    setWashRateUnits(washRateUnits);
    let defaultUnitFromStorage: any;
    let defaultUnitMin;
    let defaultUnitMax;
    let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
    defaultUnitFromStorage =
      categoryInputMapping &&
      Object.keys(categoryInputMapping).length &&
      Object.keys(categoryInputMapping.Overhead_Water_Wash_Rate).length &&
      categoryInputMapping.Overhead_Water_Wash_Rate[0] &&
      categoryInputMapping.Overhead_Water_Wash_Rate[0].unitSymbol;
    defaultUnitMin =
      washRateUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      washRateUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
    defaultUnitMax =
      washRateUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      washRateUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
    //setting first item as selected in case of manual entry create screen
    if (localStorage.getItem('manualInput') === null && !searchParams?.get('id')) {
      setManualInput('wash-rate-unit', defaultUnitFromStorage, defaultUnitMin, defaultUnitMax);
    } else if (localStorage.getItem('manualInput') !== null) {
      let manualInput = JSON.parse(localStorage.getItem('manualInput')!);
      let newSimulationObj;
      newSimulationObj = {
        ...manualInput,
        overheadConditions: { ...manualInput.overheadConditions },
      };
      newSimulationObj.overheadConditions.washRate_UOM = defaultUnitFromStorage;
      newSimulationObj.overheadConditions.totalExternalWashRate_UOM = defaultUnitFromStorage;
      newSimulationObj.overheadConditions.washRateMin = defaultUnitMin;
      newSimulationObj.overheadConditions.washRateMax = defaultUnitMax;
      newSimulationObj.overheadConditions.totalExternalWashRateMax_UOM = defaultUnitFromStorage;
      newSimulationObj.overheadConditions.totalExternalWashRateMin = defaultUnitMin;
      newSimulationObj.overheadConditions.totalExternalWashRateMax = newSimulationObj.overheadConditions.washRate
        ? newSimulationObj.overheadConditions.washRate
        : defaultUnitMax;
      localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
      setSimulationData(newSimulationObj);
    }
  };

  const bindProductUnitDropdown = () => {
    let productunits = require('../../constants/cold-product-units.json');
    setProductUnits(productunits);
    let defaultUnitFromStorage: any;
    let defaultUnitMin;
    let defaultUnitMax;
    let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
    if (categoryInputMapping && Object.keys(categoryInputMapping).length && Object.keys(categoryInputMapping.Overhead_Liquid_Flow).length) {
      categoryInputMapping.Overhead_Liquid_Flow.forEach((prodItem: any) => {
        if (prodItem.input === 'Product') {
          defaultUnitFromStorage = prodItem.unitSymbol;
          defaultUnitMin =
            productunits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
            productunits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
          defaultUnitMax =
            productunits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
            productunits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
        }
      });
    }
    //setting first item as selected in case of manual entry create screen
    if (localStorage.getItem('manualInput') === null && !searchParams?.get('id')) {
      setManualInput('product-unit', defaultUnitFromStorage, defaultUnitMin, defaultUnitMax);
    } else if (localStorage.getItem('manualInput') !== null) {
    }
  };

  const bindRefluxHotProductUnitDropdown = () => {
    let refluxhotproductunits = require('../../constants/reflux-units.json');
    setRefluxProductUnits(refluxhotproductunits);
    let defaultUnitFromStorage: any;
    let defaultUnitMin: any;
    let defaultUnitMax: any;
    let defaultRefluxUomDetails: any;
    let defaultProductUomDetails: any;
    let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
    if (categoryInputMapping && Object.keys(categoryInputMapping).length && Object.keys(categoryInputMapping.Overhead_Liquid_Flow).length) {
      categoryInputMapping.Overhead_Liquid_Flow.forEach((prodItem: any) => {
        if (prodItem.input === 'Reflux') {
          defaultUnitFromStorage = prodItem.unitSymbol;
          defaultUnitMin =
            refluxhotproductunits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
            refluxhotproductunits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
          defaultUnitMax =
            refluxhotproductunits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
            refluxhotproductunits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
          defaultRefluxUomDetails = {
            defaultUnitFromStorage: defaultUnitFromStorage,
            defaultUnitMin: defaultUnitMin,
            defaultUnitMax: defaultUnitMax,
          };
        }
        if (prodItem.input === 'Product') {
          defaultProductUomDetails = {
            defaultUnitFromStorage: prodItem.unitSymbol,
            defaultUnitMin:
              refluxhotproductunits.filter((m: any) => m.label === prodItem.unitSymbol)[0] &&
              refluxhotproductunits.filter((m: any) => m.label === prodItem.unitSymbol)[0].min,
            defaultUnitMax:
              refluxhotproductunits.filter((m: any) => m.label === prodItem.unitSymbol)[0] &&
              refluxhotproductunits.filter((m: any) => m.label === prodItem.unitSymbol)[0].max,
          };
        }
      });
    }
    //setting first item as selected in case of manual entry create screen
    if (localStorage.getItem('manualInput') === null && !searchParams?.get('id')) {
      setManualInput('reflux-unit', defaultUnitFromStorage, defaultUnitMin, defaultUnitMax);
      setManualInput('reflux-unit_1', defaultUnitFromStorage, defaultUnitMin, defaultUnitMax);
      setProductRefluxRange(refluxhotproductunits, defaultRefluxUomDetails, defaultProductUomDetails);
    } else if (localStorage.getItem('manualInput') !== null) {
      setProductRefluxRange(refluxhotproductunits, defaultRefluxUomDetails, defaultProductUomDetails);
    }
  };

  const bindPressureUnits = () => {
    let pressureUnits = require('../../constants/pressure-units.json');
    setPressureUnits(pressureUnits);
    let defaultUnitFromStorage: any;
    let defaultUnitMin;
    let defaultUnitMax;
    let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
    defaultUnitFromStorage =
      categoryInputMapping &&
      Object.keys(categoryInputMapping).length &&
      Object.keys(categoryInputMapping.Atm_Tower_Top_Pressure).length &&
      categoryInputMapping.Atm_Tower_Top_Pressure[0] &&
      categoryInputMapping.Atm_Tower_Top_Pressure[0].unitSymbol;
    defaultUnitMin =
      pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
    defaultUnitMax =
      pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
    //setting first item as selected in case of manual entry create screen
    if (localStorage.getItem('manualInput') === null && !searchParams?.get('id')) {
      setManualInput('oh-press-unit', defaultUnitFromStorage, defaultUnitMin, defaultUnitMax);
    } else if (localStorage.getItem('manualInput') !== null) {
      let manualInput = JSON.parse(localStorage.getItem('manualInput')!);
      let newSimulationObj;
      newSimulationObj = {
        ...manualInput,
        overheadConditions: { ...manualInput.overheadConditions },
      };
      newSimulationObj.overheadConditions.OHPress_UOM = defaultUnitFromStorage;
      newSimulationObj.overheadConditions.OHPressMin = defaultUnitMin;
      newSimulationObj.overheadConditions.OHPressMax = defaultUnitMax;
      defaultUnitFromStorage =
        categoryInputMapping &&
        Object.keys(categoryInputMapping).length &&
        Object.keys(categoryInputMapping.Pressure_before_Water_Wash).length &&
        categoryInputMapping.Pressure_before_Water_Wash[0] &&
        categoryInputMapping.Pressure_before_Water_Wash[0].unitSymbol;
      defaultUnitMin =
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
      defaultUnitMax =
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
      newSimulationObj.overheadConditions.washProcessPress_UOM = defaultUnitFromStorage;
      newSimulationObj.overheadConditions.washProcessPressMin = defaultUnitMin;
      newSimulationObj.overheadConditions.washProcessPressMax = defaultUnitMax;
      defaultUnitFromStorage =
        categoryInputMapping &&
        Object.keys(categoryInputMapping).length &&
        Object.keys(categoryInputMapping.Pressure_at_Velocity_Calc).length &&
        categoryInputMapping.Pressure_at_Velocity_Calc[0] &&
        categoryInputMapping.Pressure_at_Velocity_Calc[0].unitSymbol;
      defaultUnitMin =
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
      defaultUnitMax =
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
      newSimulationObj.overheadConditions.velocityPressMin = defaultUnitMin;
      newSimulationObj.overheadConditions.velocityPressMax = defaultUnitMax;
      newSimulationObj.overheadConditions.velocityPress_UOM = defaultUnitFromStorage;
      defaultUnitFromStorage =
        categoryInputMapping &&
        Object.keys(categoryInputMapping).length &&
        Object.keys(categoryInputMapping.Hot_Drum_Pressure).length &&
        categoryInputMapping.Hot_Drum_Pressure[0] &&
        categoryInputMapping.Hot_Drum_Pressure[0].unitSymbol;
      defaultUnitMin =
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
      defaultUnitMax =
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        pressureUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
      newSimulationObj.overheadConditions.drumPressMin = defaultUnitMin;
      newSimulationObj.overheadConditions.drumPressMax = defaultUnitMax;
      newSimulationObj.overheadConditions.drumPress_UOM = defaultUnitFromStorage;
      localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
      setSimulationData(newSimulationObj);
    }
  };

  const bindSteamTowerUnitsDropDown = () => {
    let defaultUnitFromStorage: any;
    let defaultUnitMin;
    let defaultUnitMax;
    let steamUnits = require('../../constants/steam-tower-units.json');
    setSteamTowerUnits(steamUnits);
    let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
    defaultUnitFromStorage =
      categoryInputMapping &&
      Object.keys(categoryInputMapping).length &&
      categoryInputMapping.Stripping_Steam_to_Tower &&
      Object.keys(categoryInputMapping.Stripping_Steam_to_Tower).length &&
      categoryInputMapping.Stripping_Steam_to_Tower[0] &&
      categoryInputMapping.Stripping_Steam_to_Tower[0].unitSymbol;
    defaultUnitMin =
      steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
    defaultUnitMax =
      steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
    //setting first item as selected in case of manual entry create screen
    if (localStorage.getItem('manualInput') === null && !searchParams?.get('id')) {
      setManualInput('steam-tower-unit', defaultUnitFromStorage, defaultUnitMin, defaultUnitMax);
    } else if (localStorage.getItem('manualInput') !== null) {
      let manualInput = JSON.parse(localStorage.getItem('manualInput')!);
      let newSimulationObj;
      newSimulationObj = {
        ...manualInput,
        towerData: { ...manualInput.towerData },
      };
      newSimulationObj.towerData.steamTower_UOM = defaultUnitFromStorage;
      newSimulationObj.towerData.steamTowerMin = defaultUnitMin;
      newSimulationObj.towerData.steamTowerMax = defaultUnitMax;
      setSimulationData(newSimulationObj);
      localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
    }
  };

  const bindSteamUnitsDropDown = () => {
    let steamUnits = require('../../constants/steam-units.json');
    let defaultUnitFromStorage: any;
    let defaultUnitMin;
    let defaultUnitMax;
    setSteamUnits(steamUnits);
    let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
    defaultUnitFromStorage =
      categoryInputMapping &&
      Object.keys(categoryInputMapping).length &&
      categoryInputMapping.Stripping_Steam_to_Tower &&
      Object.keys(categoryInputMapping.Stripping_Steam_to_Tower).length &&
      categoryInputMapping.Stripping_Steam_to_Tower[0] &&
      categoryInputMapping.Stripping_Steam_to_Tower[0].unitSymbol;
    defaultUnitMin =
      steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
    defaultUnitMax =
      steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
      steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
    //setting first item as selected in case of manual entry create screen
    if (localStorage.getItem('manualInput') === null && !searchParams?.get('id')) {
      setManualInput('steam-tower-unit', defaultUnitFromStorage, defaultUnitMin, defaultUnitMax);
    } else if (localStorage.getItem('manualInput') !== null) {
      let manualInput = JSON.parse(localStorage.getItem('manualInput')!);
      let newSimulationObj;
      newSimulationObj = {
        ...manualInput,
        towerData: { ...manualInput.towerData },
      };
      defaultUnitFromStorage =
        categoryInputMapping &&
        Object.keys(categoryInputMapping).length &&
        categoryInputMapping.First_Side_Stripper_Steam &&
        Object.keys(categoryInputMapping.First_Side_Stripper_Steam).length &&
        categoryInputMapping.First_Side_Stripper_Steam[0] &&
        categoryInputMapping.First_Side_Stripper_Steam[0].unitSymbol;
      defaultUnitMin =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
      defaultUnitMax =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
      newSimulationObj.towerData.steam1SS_UOM = defaultUnitFromStorage;
      newSimulationObj.towerData.steam1SSMin = defaultUnitMin;
      newSimulationObj.towerData.steam1SSMax = defaultUnitMax;
      defaultUnitFromStorage =
        categoryInputMapping &&
        Object.keys(categoryInputMapping).length &&
        categoryInputMapping.Second_Side_Stripper_Steam &&
        Object.keys(categoryInputMapping.Second_Side_Stripper_Steam).length &&
        categoryInputMapping.Second_Side_Stripper_Steam[0] &&
        categoryInputMapping.Second_Side_Stripper_Steam[0].unitSymbol;
      defaultUnitMin =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
      defaultUnitMax =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
      newSimulationObj.towerData.steam2SS_UOM = defaultUnitFromStorage;
      newSimulationObj.towerData.steam2SSMin = defaultUnitMin;
      newSimulationObj.towerData.steam2SSMax = defaultUnitMax;
      defaultUnitFromStorage =
        categoryInputMapping &&
        Object.keys(categoryInputMapping).length &&
        categoryInputMapping.Third_Side_Stripper_Steam &&
        Object.keys(categoryInputMapping.Third_Side_Stripper_Steam).length &&
        categoryInputMapping.Third_Side_Stripper_Steam[0] &&
        categoryInputMapping.Third_Side_Stripper_Steam[0].unitSymbol;
      defaultUnitMin =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
      defaultUnitMax =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
      newSimulationObj.towerData.steam3SS_UOM = defaultUnitFromStorage;
      newSimulationObj.towerData.steam3SSMin = defaultUnitMin;
      newSimulationObj.towerData.steam3SSMax = defaultUnitMax;
      defaultUnitFromStorage =
        categoryInputMapping &&
        Object.keys(categoryInputMapping).length &&
        categoryInputMapping.Fourth_Side_Stripper_Steam &&
        Object.keys(categoryInputMapping.Fourth_Side_Stripper_Steam).length &&
        categoryInputMapping.Fourth_Side_Stripper_Steam[0] &&
        categoryInputMapping.Fourth_Side_Stripper_Steam[0].unitSymbol;
      defaultUnitMin =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
      defaultUnitMax =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
      newSimulationObj.towerData.steam4SS_UOM = defaultUnitFromStorage;
      newSimulationObj.towerData.steam4SSMin = defaultUnitMin;
      newSimulationObj.towerData.steam4SSMax = defaultUnitMax;
      defaultUnitFromStorage =
        categoryInputMapping &&
        Object.keys(categoryInputMapping).length &&
        categoryInputMapping.Fifth_Side_Stripper_Steam &&
        Object.keys(categoryInputMapping.Fifth_Side_Stripper_Steam).length &&
        categoryInputMapping.Fifth_Side_Stripper_Steam[0] &&
        categoryInputMapping.Fifth_Side_Stripper_Steam[0].unitSymbol;
      defaultUnitMin =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
      defaultUnitMax =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
      newSimulationObj.towerData.steam5SS_UOM = defaultUnitFromStorage;
      newSimulationObj.towerData.steam5SSMin = defaultUnitMin;
      newSimulationObj.towerData.steam5SSMax = defaultUnitMax;
      defaultUnitFromStorage =
        categoryInputMapping &&
        Object.keys(categoryInputMapping).length &&
        categoryInputMapping.Sixth_Side_Stripper_Steam &&
        Object.keys(categoryInputMapping.Sixth_Side_Stripper_Steam).length &&
        categoryInputMapping.Sixth_Side_Stripper_Steam[0] &&
        categoryInputMapping.Sixth_Side_Stripper_Steam[0].unitSymbol;
      defaultUnitMin =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
      defaultUnitMax =
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        steamUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
      newSimulationObj.towerData.steam6SS_UOM = defaultUnitFromStorage;
      newSimulationObj.towerData.steam6SSMin = defaultUnitMin;
      newSimulationObj.towerData.steam6SSMax = defaultUnitMax;
      setSimulationData(newSimulationObj);
      localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
    }
  };

  const bindDesalterTempUnitsDropDown = () => {
    let defaultUnitFromStorage: any;
    let defaultUnitMin;
    let defaultUnitMax;
    let deslaterTempUnits = require('../../constants/temperature-units.json');
    let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
    if (categoryInputMapping && Object.keys(categoryInputMapping).length && Object.keys(categoryInputMapping.Temperature).length) {
      categoryInputMapping.Temperature.forEach((tempItem: any) => {
        if (tempItem.input === 'Desalter Temperature') {
          defaultUnitFromStorage = '°' + tempItem.unitSymbol;
          defaultUnitMin =
            deslaterTempUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
            deslaterTempUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
          defaultUnitMax =
            deslaterTempUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
            deslaterTempUnits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
        }
      });
    }
    setDeslaterTempUnits(deslaterTempUnits);
    //setting first item as selected in case of manual entry create screen
    if (localStorage.getItem('manualInput') === null && !searchParams?.get('id')) {
      setManualInput('desalter-temp-unit', defaultUnitFromStorage, defaultUnitMin, defaultUnitMax);
      setDeslaterTempUOM(defaultUnitFromStorage);
    } else if (localStorage.getItem('manualInput') !== null) {
      let manualInput = JSON.parse(localStorage.getItem('manualInput')!);
      let configType = localStorage.getItem('configType')!;
      let newSimulationObj;
      newSimulationObj = {
        ...manualInput,
        desalter: {
          ...manualInput.desalter,
        },
      };
      newSimulationObj.desalter.desalterTemperature_UOM = defaultUnitFromStorage;
      newSimulationObj.desalter.desalterTemperatureMin = defaultUnitMin;
      newSimulationObj.desalter.desalterTemperatureMax = defaultUnitMax;
      if (defaultUnitFromStorage === '°F') {
        newSimulationObj.desalter.brineSampleTemperatureMin = rangeConstants.BRINE_SAMPLE_TEMP_MIN_F;
        newSimulationObj.desalter.brineSampleTemperatureMax = rangeConstants.BRINE_SAMPLE_TEMP_MAX_F;

        newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxTempMin = rangeConstants.COMBINED_REFLUX_TEMPERATURE_MIN_F;
        newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxTempMax = rangeConstants.COMBINED_REFLUX_TEMPERATURE_MAX_F;

        newSimulationObj.overheadConditions.OHTempMin = rangeConstants.OH_TEMP_MIN_F;
        newSimulationObj.overheadConditions.OHTempMax = rangeConstants.OH_TEMP_MAX_F;
        newSimulationObj.overheadConditions.washTempMin = rangeConstants.BRINE_SAMPLE_TEMP_MIN_F;
        newSimulationObj.overheadConditions.washTempMax = rangeConstants.BRINE_SAMPLE_TEMP_MAX_F;
        newSimulationObj.overheadConditions.washProcessTempMin = rangeConstants.WASH_PROCESS_TEMP_MIN_F;
        newSimulationObj.overheadConditions.washProcessTempMax = rangeConstants.WASH_PROCESS_TEMP_MAX_F;
        newSimulationObj.overheadConditions.velocityTempMin = rangeConstants.WASH_PROCESS_TEMP_MIN_F;
        newSimulationObj.overheadConditions.velocityTempMax = rangeConstants.WASH_PROCESS_TEMP_MAX_F;
        newSimulationObj.overheadConditions.drumTempMin = rangeConstants.WASH_PROCESS_TEMP_MIN_F;
        newSimulationObj.overheadConditions.drumTempMax = rangeConstants.WASH_PROCESS_TEMP_MAX_F;
        if (configType === '1A' || configType === '1B') {
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation.distillationMin = rangeConstants.DISTILLATION0_MIN_F;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation.distillationMax = rangeConstants.DISTILLATION0_MAX_F;
        } else if (configType === '2A' || configType === '2B') {
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation.hotDistillationMin = rangeConstants.DISTILLATION0_MIN_F;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation.hotDistillationMax = rangeConstants.DISTILLATION0_MAX_F;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation.coldDistillationMin = rangeConstants.DISTILLATION0_MIN_F;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation.coldDistillationMax = rangeConstants.DISTILLATION0_MAX_F;
        }
      } else {
        newSimulationObj.desalter.brineSampleTemperatureMin = rangeConstants.BRINE_SAMPLE_TEMP_MIN_C;
        newSimulationObj.desalter.brineSampleTemperatureMax = rangeConstants.BRINE_SAMPLE_TEMP_MAX_C;
        newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxTempMin = rangeConstants.COMBINED_REFLUX_TEMPERATURE_MIN_C;
        newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxTempMax = rangeConstants.COMBINED_REFLUX_TEMPERATURE_MAX_C;
        newSimulationObj.overheadConditions.OHTempMin = rangeConstants.OH_TEMP_MIN_C;
        newSimulationObj.overheadConditions.OHTempMax = rangeConstants.OH_TEMP_MAX_C;
        newSimulationObj.overheadConditions.washTempMin = rangeConstants.BRINE_SAMPLE_TEMP_MIN_C;
        newSimulationObj.overheadConditions.washTempMax = rangeConstants.BRINE_SAMPLE_TEMP_MAX_C;
        newSimulationObj.overheadConditions.washProcessTempMin = rangeConstants.WASH_PROCESS_TEMP_MIN_C;
        newSimulationObj.overheadConditions.washProcessTempMax = rangeConstants.WASH_PROCESS_TEMP_MAX_C;
        newSimulationObj.overheadConditions.velocityTempMin = rangeConstants.WASH_PROCESS_TEMP_MIN_C;
        newSimulationObj.overheadConditions.velocityTempMax = rangeConstants.WASH_PROCESS_TEMP_MAX_C;
        newSimulationObj.overheadConditions.drumTempMin = rangeConstants.WASH_PROCESS_TEMP_MIN_C;
        newSimulationObj.overheadConditions.drumTempMax = rangeConstants.WASH_PROCESS_TEMP_MAX_C;
        if (configType === '1A' || configType === '1B') {
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation.distillationMin = rangeConstants.DISTILLATION0_MIN_C;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation.distillationMax = rangeConstants.DISTILLATION0_MAX_C;
        } else if (configType === '2A' || configType === '2B') {
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation.hotDistillationMin = rangeConstants.DISTILLATION0_MIN_C;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation.hotDistillationMax = rangeConstants.DISTILLATION0_MAX_C;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation.coldDistillationMin = rangeConstants.DISTILLATION0_MIN_C;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation.coldDistillationMax = rangeConstants.DISTILLATION0_MAX_C;
        }
      }
      newSimulationObj.overheadConditions.OHTemp_UOM = defaultUnitFromStorage;
      localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
      setSimulationData(newSimulationObj);
      setDeslaterTempUOM(defaultUnitFromStorage);
    }
  };

  const bindNeutralizerDropDown = () => {
    let neutralizerdata = require('../../constants/neutralizer-data.json');
    setNeutralizerData(neutralizerdata);
  };

  const bindAmineUnitDropdown = () => {
    let amineunits = require('../../constants/amine-data.json');
    setAmineUnits(amineunits);
  };

  const setProductRefluxRange = (productrefluxunits: any, defaultRefluxUomDetails: any, defaultProductUomDetails: any) => {
    let manualInput = JSON.parse(localStorage.getItem('manualInput')!);
    if (manualInput !== null) {
      let newSimulationObj;
      let configType = localStorage.getItem('configType')!;
      if (configType === '1A' || configType === '1B') {
        newSimulationObj = {
          ...manualInput,
          desalter: {
            ...manualInput.desalter,
          },
          liquidAssayStreams: {
            ...manualInput.liquidAssayStreams,
            hydrocarbonLiquid: {
              ...manualInput.liquidAssayStreams.hydrocarbonLiquid,
              distillation: { ...manualInput.liquidAssayStreams.hydrocarbonLiquid?.distillation },
            },
          },
        };
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.reflux_UOM = defaultRefluxUomDetails.defaultUnitFromStorage;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.refluxMin = defaultRefluxUomDetails.defaultUnitMin;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.refluxMax = defaultRefluxUomDetails.defaultUnitMax;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.product_UOM = defaultProductUomDetails.defaultUnitFromStorage;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.productMin = defaultProductUomDetails.defaultUnitMin;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.productMax = defaultProductUomDetails.defaultUnitMax;
        newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxFlow_UOM = defaultProductUomDetails.defaultUnitFromStorage;
      } else {
        newSimulationObj = {
          ...manualInput,
          desalter: {
            ...manualInput.desalter,
          },
          liquidAssayStreams: {
            ...manualInput.liquidAssayStreams,
            hydrocarbonLiquidHot: {
              ...manualInput.liquidAssayStreams.hydrocarbonLiquidHot,
              distillation: { ...manualInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation },
            },
            hydrocarbonLiquidCold: {
              ...manualInput.liquidAssayStreams.hydrocarbonLiquidCold,
              distillation: { ...manualInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation },
            },
          },
        };
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.reflux_UOM = defaultRefluxUomDetails?.defaultUnitFromStorage;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.hotRefluxMin = defaultRefluxUomDetails?.defaultUnitMin;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.hotRefluxMax = defaultRefluxUomDetails?.defaultUnitMax;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.product_UOM = defaultProductUomDetails?.defaultUnitFromStorage;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.hotProductMin = defaultProductUomDetails?.defaultUnitMin;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.hotProductMax = defaultProductUomDetails?.defaultUnitMax;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.reflux_UOM = defaultRefluxUomDetails?.defaultUnitFromStorage;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.coldRefluxMin = defaultRefluxUomDetails?.defaultUnitMin;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.coldRefluxMax = defaultRefluxUomDetails?.defaultUnitMax;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.product_UOM = defaultProductUomDetails?.defaultUnitFromStorage;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.coldProductMin = defaultProductUomDetails?.defaultUnitMin;
        newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.coldProductMax = defaultProductUomDetails?.defaultUnitMax;
        newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxFlow_UOM = defaultProductUomDetails?.defaultUnitFromStorage;
      }
      let formatedDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(today);
      newSimulationObj.inputDate = formatedDate;
      setSimulationData(newSimulationObj);
      localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
    }
  };

  const bindCrudeChargeUnitDropdown = () => {
    let crudechargeunits = require('../../constants/crudecharge-units.json');
    setCrudeChargeUnits(crudechargeunits);
    //setting first item as selected in case of manual entry create screen
    if (localStorage.getItem('manualInput') === null && !searchParams?.get('id')) {
      let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
      let defaultUnitFromStorage =
        categoryInputMapping &&
        Object.keys(categoryInputMapping).length &&
        categoryInputMapping.Crude_Charge_Rate &&
        Object.keys(categoryInputMapping.Crude_Charge_Rate).length &&
        categoryInputMapping.Crude_Charge_Rate[0] &&
        categoryInputMapping.Crude_Charge_Rate[0].unitSymbol;
      let defaultUnitMin =
        crudechargeunits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        crudechargeunits.filter((m: any) => m.label === defaultUnitFromStorage)[0].min;
      let defaultUnitMax =
        crudechargeunits.filter((m: any) => m.label === defaultUnitFromStorage)[0] &&
        crudechargeunits.filter((m: any) => m.label === defaultUnitFromStorage)[0].max;
      setManualInput('crude-charge-unit', defaultUnitFromStorage, defaultUnitMin, defaultUnitMax);
    }
  };

  const bindSimulationData = (unitType: any) => {
    let dataFromStorage = JSON.parse(localStorage.getItem('simulationData')!);
    if (dataFromStorage && dataFromStorage.length) {
      //manual entry edit
      if (searchParams?.get('id')) {
        let desalterTempUnit;
        let simData = dataFromStorage.filter((item: any) => item.simulationId === parseInt(searchParams?.get('id')!))[0];
        //desalter temp unit is showing as units in brine temp, oh press ... textboxes
        if(unitType !== 'fcc' && unitType !== 'coker')
          desalterTempUnit =
          simData && simData.desalter && simData.desalter.desalterTemperature_UOM && simData.desalter.desalterTemperature_UOM === 'F'
            ? '°F'
            : simData.desalter.desalterTemperature_UOM === 'C'
            ? '°C'
            : '';
        else
        desalterTempUnit =
          simData && simData.overheadConditions && simData.overheadConditions.OHTemp_UOM && simData.overheadConditions.OHTemp_UOM === 'F'
            ? '°F'
            : simData.overheadConditions.OHTemp_UOM === 'C'
            ? '°C'
            : '';
        setDeslaterTempUOM(desalterTempUnit);
        setFormattedDate(
          new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(new Date(simData.inputDate))
        );
        setSimulationData(simData);
        //in order to hide validation message during page refresh if the input has data
        resetErrorText(simData, true);
      }
    }
    //manual entry create
    if (!searchParams?.get('id')) {
      if (localStorage.getItem('manualInput') !== null) {
        let dataFromStorage = JSON.parse(localStorage.getItem('manualInput')!);
        if (dataFromStorage && Object.keys(dataFromStorage).length > 0) {
          setSimulationData(dataFromStorage);
          //in order to hide validation message during page refresh if the input has data
          resetErrorText(dataFromStorage, false);
        }
      }
    }
  };

  const resetErrorText = (dataFromStorage: any, isEdit: boolean) => {
    let configType = localStorage.getItem('configType');
    if (
      (!isEdit && dataFromStorage.desalter.crudeCharge && dataFromStorage.desalter.crudeCharge.length > 0) ||
      (isEdit && dataFromStorage.desalter.crudeCharge)
    )
      errors.crudecharge = null;
    if (
      (!isEdit && dataFromStorage.desalter.crudeGravity && dataFromStorage.desalter.crudeGravity.length > 0) ||
      (isEdit && dataFromStorage.desalter.crudeGravity)
    )
      errors.crudegravity = null;
    if (
      (!isEdit &&
        dataFromStorage.desalter.washPercentageData.washPercentage &&
        dataFromStorage.desalter.washPercentageData.washPercentage.length > 0) ||
      (isEdit && Object.keys(dataFromStorage.desalter).length && dataFromStorage.desalter.washPercentageData.washPercentage)
    )
      errors.washpercentage = null;
    if (
      (!isEdit && dataFromStorage.desalter.desalterTemperature && dataFromStorage.desalter.desalterTemperature.length > 0) ||
      (isEdit && dataFromStorage.desalter.desalterTemperature)
    )
      errors.desaltertemp = null;
    if (
      (!isEdit && dataFromStorage.desalter.brinepHData.brinepH && dataFromStorage.desalter.brinepHData.brinepH.length > 0) ||
      (isEdit && Object.keys(dataFromStorage.desalter).length && dataFromStorage.desalter.brinepHData.brinepH)
    )
      errors.brineph = null;
    if (configType === '1B' || configType === '2B') {
      if (
        (!isEdit && dataFromStorage.desalter.brinepHData.brinepH2 && dataFromStorage.desalter.brinepHData.brinepH2.length > 0) ||
        (isEdit && dataFromStorage.desalter.brinepHData.brinepH2)
      )
        errors.brineph2 = null;
      if (
        (!isEdit &&
          dataFromStorage.desalter.washPercentageData.washPercentage2 &&
          dataFromStorage.desalter.washPercentageData.washPercentage2.length > 0) ||
        (isEdit && dataFromStorage.desalter.washPercentageData.washPercentage2)
      )
        errors.wash2percentage = null;
    }
    if (
      (!isEdit && dataFromStorage.desalter.brineSampleTemperature && dataFromStorage.desalter.brineSampleTemperature.length > 0) ||
      (isEdit && dataFromStorage.desalter.brineSampleTemperature)
    )
      errors.brinesampletemp = null;
    if (
      (!isEdit && dataFromStorage.towerData.steamTower && dataFromStorage.towerData.steamTower.length > 0) ||
      (isEdit && dataFromStorage.towerData.steamTower)
    )
      errors.steamtower = null;
    if (
      (!isEdit && dataFromStorage.overheadConditions.washTemp && dataFromStorage.overheadConditions.washTemp.length > 0) ||
      (isEdit && dataFromStorage.overheadConditions.washTemp)
    )
      errors.washtemp = null;
    if (
      (!isEdit && dataFromStorage.overheadConditions.washProcessTemp && dataFromStorage.overheadConditions.washProcessTemp.length > 0) ||
      (isEdit && dataFromStorage.overheadConditions.washProcessTemp)
    )
      errors.washprocesstemp = null;
    if (
      (!isEdit && dataFromStorage.overheadConditions.velocityTemp && dataFromStorage.overheadConditions.velocityTemp.length > 0) ||
      (isEdit && dataFromStorage.overheadConditions.velocityTemp)
    )
      errors.velocitytemp = null;
    if (
      (!isEdit && dataFromStorage.overheadConditions.drumTemp && dataFromStorage.overheadConditions.drumTemp.length > 0) ||
      (isEdit && dataFromStorage.overheadConditions.drumTemp)
    )
      errors.drumtemp = null;
    if (configType === '1A' || configType === '1B') {
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.product &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.product.length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.product)
      )
        errors.product = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.gravity &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.gravity.length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.gravity)
      )
        errors.gravity = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.distillation[10] &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.distillation[10].length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.distillation[10])
      )
        errors.distillation10 = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.distillation[50] &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.distillation[50].length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.distillation[50])
      )
        errors.distillation50 = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.distillation[90] &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.distillation[90].length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquid.distillation[90])
      )
        errors.distillation90 = null;
    } else {
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.product &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.product.length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.product >= 0)
      )
        errors.product = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.product &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.product.length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.product >= 0)
      )
        errors.product_1 = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.gravity &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.gravity.length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.gravity)
      )
        errors.gravity = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.gravity &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.gravity.length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.gravity)
      )
        errors.gravity_1 = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.distillation[10] &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.distillation[10].length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.distillation[10])
      )
        errors.distillation10 = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.distillation[10] &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.distillation[10].length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.distillation[10])
      )
        errors.distillation10_1 = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.distillation[50] &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.distillation[50].length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.distillation[50])
      )
        errors.distillation50 = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.distillation[50] &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.distillation[50].length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.distillation[50])
      )
        errors.distillation50_1 = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.distillation[90] &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.distillation[90].length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquidHot.distillation[90])
      )
        errors.distillation90 = null;
      if (
        (!isEdit &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.distillation[90] &&
          dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.distillation[90].length > 0) ||
        (isEdit && dataFromStorage.liquidAssayStreams.hydrocarbonLiquidCold.distillation[90])
      )
        errors.distillation90_1 = null;
    }
    if (
      (!isEdit && dataFromStorage.overheadWater.pHData.pH && dataFromStorage.overheadWater.pHData.pH.length > 0) ||
      (isEdit && dataFromStorage.overheadWater.pHData.pH)
    )
      errors.ph = null;
    if (configType === '2A' || configType === '2B') {
      if (
        (!isEdit && dataFromStorage.overheadWater.pHData.pH_2 && dataFromStorage.overheadWater.pHData.pH_2.length > 0) ||
        (isEdit && dataFromStorage.overheadWater.pHData.pH_2)
      )
        errors.ph2 = null;
    }

    if (
      (!isEdit &&
        dataFromStorage.overheadWater.combinedRefluxData.combinedRefluxFlow &&
        dataFromStorage.overheadWater.combinedRefluxData.combinedRefluxFlow.length > 0) ||
      (isEdit && dataFromStorage.overheadWater.combinedRefluxData.combinedRefluxFlow)
    )
      errors.combinedRefluxFlow = null;

    if (
      (!isEdit &&
        dataFromStorage.overheadWater.combinedRefluxData.combinedRefluxTemp &&
        dataFromStorage.overheadWater.combinedRefluxData.combinedRefluxTemp.length > 0) ||
      (isEdit && dataFromStorage.overheadWater.combinedRefluxData.combinedRefluxTemp)
    )
      errors.combinedRefluxTemperature = null;

    if (
      (!isEdit && dataFromStorage.desalter.chargeGravity && dataFromStorage.desalter.chargeGravity.length > 0) ||
      (isEdit && dataFromStorage.desalter.chargeGravity)
    )
      errors.chargeGravity = null;

      if (
        (!isEdit && dataFromStorage.overheadConditions.washRate && dataFromStorage.overheadConditions.washRate.length > 0) ||
        (isEdit && dataFromStorage.overheadConditions.washRate)
      )
        errors.washrate = null;
  };

  const updateNewSimulationDataApiCall = (resultant: any) => {
    let simulationInput: any = { assetConfig: '', ionicInput: '' };
    let ionicInput: any = [];
    let assetConfig = {
      refineryID: '',
      refineryType: '',
      refineryName: '',
      unitID: '',
      unitType: '',
      unitName: '',
      subUnitId: '',
      subUnitType: '',
      subUnitName: '',
      configId: 0,
      customerName: '',
      customerId: '',
    };
    let configFromStorage: any = JSON.parse(localStorage.getItem('defaultConfig')!);
    if (configFromStorage && configFromStorage.refinery) {
      if (configFromStorage.refinery.unit && configFromStorage.refinery.unit.length > 0) {
        configFromStorage.refinery.unit.map((unitItem: any) => {
          if (unitItem.subUnit && unitItem.subUnit.length > 0) {
            unitItem.subUnit.map((subUnitItem: any) => {
              assetConfig.configId = subUnitItem.configId;
              assetConfig.subUnitName = subUnitItem.subUnitName;
              assetConfig.subUnitType = subUnitItem.typeSubunit;
              assetConfig.subUnitId = subUnitItem.subUnitId;
              assetConfig.unitName = unitItem.unitName;
              assetConfig.unitID = unitItem.unitId;
              assetConfig.unitType = unitItem.unitType;
              assetConfig.refineryName = configFromStorage.refinery.refineryName;
              assetConfig.refineryID = configFromStorage.refinery.refineryId;
              assetConfig.refineryType = configFromStorage.refinery.refineryType;
              assetConfig.customerName = configFromStorage.refinery.customerName;
              assetConfig.customerId = configFromStorage.refinery.customerId;
            });
          }
        });
      }
    }
    let resultData: any = [];

    if (resultant) {
      resultData.push(resultant);
    }

    let configType = localStorage.getItem('configType')!;
    if (resultData && resultData.length) {
      resultData.forEach((element: any) => {
        let formatedDate = changeToShortFormat(new Date(element.inputDate));
        switch (configType) {
          case '1A':
            ionicInput.push(ionicInputFor1A(formatedDate, element, unitType, isWithDesalter, false));
            break;
          case '1B':
            ionicInput.push(ionicInputFor1B(formatedDate, element, false));
            break;
          case '2A':
            ionicInput.push(ionicInputFor2A(formatedDate, element, false));
            break;
          case '2B':
            ionicInput.push(ionicInputFor2B(formatedDate, element, false));
            break;
        }
        Object.assign(ionicInput[0], { simulationId: element.simulationId });
      });
    }
    simulationInput.assetConfig = assetConfig;
    simulationInput.ionicInput = ionicInput;
    //add new block in case of sandbox mode
    if (localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true') {
      simulationInput.sandBoxModelDetails = {
        sandBoxSimulationName: simulationName,
        sandBoxSimulationComments: comments,
      };
    }
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulation/draft`;
    let isError = false;
    apiRequest(
      encodeURI(apiUrl),
      simulationInput,
      APIEnum.POST,
      true,
      localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' ? 'SandBox' : 'Actual'
    )
      .then((result) => {
        if (result && result.data && result.data.inputData && result.data.inputData.ionicInput.length) {
          if (searchParams?.get('id')) {
            //update the edited item in local storage
            let dataFromStorage = JSON.parse(localStorage.getItem('simulationData')!);
            if (dataFromStorage && dataFromStorage.length) {
              let filterResult = dataFromStorage.reduce((matchedItems: any, item: any) => {
                if (item.simulationId === parseInt(searchParams?.get('id')!)) {
                  matchedItems.push(result.data.inputData.ionicInput[0]);
                } else {
                  matchedItems.push(item);
                }
                return matchedItems;
              }, []);
              localStorage.setItem('simulationData', JSON.stringify(filterResult));
            }
          }
          //manual entry create scenario
          else {
            let dataFromStorage = JSON.parse(localStorage.getItem('simulationData')!);
            if (dataFromStorage && dataFromStorage.length) {
              localStorage.setItem('simulationData', JSON.stringify([...dataFromStorage, ...result.data.inputData.ionicInput]));
            }
            //local storage is empty
            else {
              localStorage.setItem('simulationData', JSON.stringify(result.data.inputData.ionicInput));
            }
          }
        } else {
          setIsErrorDialogueOpen(!isErrorDialogueOpen);
          isError = true;
          result && result.errors && result.errors[0] && result.errors[0].description
            ? setErrorHeader(result.errors[0].description)
            : setErrorHeader('Error');
          if (result && result.errors && result.errors[0] && result.errors[0].fields && result.errors[0].fields[0]) {
            let dataKey = Object.keys(result.errors[0].fields[0])[0]!;
            let counter = 1;
            let errorInfo: string = '';
            for (let index = 0; index < result.errors[0].fields[0][dataKey].length; index++) {
              errorInfo +=
                counter +
                '. ' +
                result.errors[0].fields[0][dataKey][index] +
                '                                                                                        ';
            }
            setErrorMessage(errorInfo);
          }
        }
      })
      .catch((error: { message: any }) => {
        isError = true;
        setIsErrorDialogueOpen(!isErrorDialogueOpen);
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        !isError && navigate('/new-simulation');
      });
  };

  const handleCtaClick = (event: any) => {
    switch (event.detail) {
      case 'btnSaveChanges':
        //if form is valid
        let hasNotNullElements = !Object.values(errors).every((o) => o === null);
        if (!hasNotNullElements) {
          //filter storage data based on date and update that record
          let dataFromStorage = JSON.parse(localStorage.getItem('simulationData')!);
          //edit manual entry
          if (dataFromStorage && dataFromStorage.length) {
            if (searchParams?.get('id')) {
              setIsLoading(true);
              updateNewSimulationDataApiCall(simulationData);
            } else {
              //create simulation data scenario
              let configType = localStorage.getItem('configType');
              let isMandatoryFieldsFilled = validateSimulationData(configType, unitType, values, isWithDesalter);
              if (isFormValid && Object.keys(values).length > 0 && isMandatoryFieldsFilled) {
                let manualInput = JSON.parse(localStorage.getItem('manualInput')!);
                setIsLoading(true);
                updateNewSimulationDataApiCall(manualInput);
              }
            }
          }
          //create manual entry
          else {
            let manualInput = JSON.parse(localStorage.getItem('manualInput')!);
            if (manualInput && manualInput.inputDate) {
              let configType = localStorage.getItem('configType');
              let isMandatoryFieldsFilled = validateSimulationManual(configType, unitType, simulationData, isWithDesalter);
              if (isFormValid && Object.keys(simulationData).length > 0 && isMandatoryFieldsFilled) {
                setIsLoading(true);
                updateNewSimulationDataApiCall(manualInput);
              }
              //form not valid
              else {
              }
            }
          }
        }
        //some field has error
        else {
        }
        break;
      case 'btnCancel':
        localStorage.removeItem('manualInput');
        navigate('/new-simulation');
        break;
      case 'btnDeleteInput':
        setisDeleteDialogueOpen(!isDeleteDialogueOpen);
        break;
      case '':
        localStorage.removeItem('manualInput');
        navigate('/new-simulation');
        break;
    }
    //steam5SSref.current?.focus();
  };

  const handleClose = (event: CustomEvent) => {
    setisDeleteDialogueOpen(!isDeleteDialogueOpen);
  };

  const handleDialogueClose = (event: CustomEvent) => {
    setIsErrorDialogueOpen(!isErrorDialogueOpen);
  };

  const deleteApiCall = (simulationId: any) => {
    const simulationIds = [];
    simulationIds.push(simulationId);
    const crmDataIds = { crmDataIds: simulationIds };
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulation/draft`;
    let isError = false;
    apiRequest(
      encodeURI(apiUrl),
      crmDataIds,
      APIEnum.DELETE,
      true,
      localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' ? 'SandBox' : 'Actual'
    )
      .then((result) => {
        if (result && result.responseStaus && result.responseStaus.status === 'SUCCESS') {
          if (searchParams?.get('id')) {
            //update the deleted item in local storage
            let dataFromStorage = JSON.parse(localStorage.getItem('simulationData')!);
            if (dataFromStorage && dataFromStorage.length) {
              let result = dataFromStorage.reduce((matchedItems: any, item: any) => {
                if (item.simulationId !== parseInt(searchParams?.get('id')!)) {
                  matchedItems.push(item);
                }
                return matchedItems;
              }, []);
              localStorage.setItem('simulationData', JSON.stringify(result));
              setisDeleteDialogueOpen(!isDeleteDialogueOpen);
              if (!localStorage.getItem('simulationData') || JSON.parse(localStorage.getItem('simulationData')!).length == 0) {
                localStorage.removeItem('simulationData');
                localStorage.removeItem('sandboxsimulation');
              }
            }
          }
        } else {
          isError = true;
          setIsErrorDialogueOpen(!isErrorDialogueOpen);
          result &&
          result.responseStaus &&
          result.responseStaus.errorResponse &&
          result.responseStaus.errorResponse[0] &&
          result.responseStaus.errorResponse[0].message
            ? setErrorMessage(result.responseStaus.errorResponse[0].message)
            : setErrorMessage('Bad Request');
        }
      })
      .catch((error: { message: any }) => {
        isError = true;
        setIsErrorDialogueOpen(!isErrorDialogueOpen);
        setErrorMessage(error.message);
      })
      .finally(() => {
        !isError && navigate('/new-simulation');
      });
  };

  const handleDialogueCtaClick = (event: any) => {
    if (event.detail === 'btnCancel') {
      // setisDeleteDialogueOpen(false);
      window.location.reload();
    } else if (event.detail === 'btnDelete') {
      //filter storage data based on date and update that record
      let result: any = [];
      let dataFromLocalStorage = JSON.parse(localStorage.getItem('simulationData')!);
      if (dataFromLocalStorage && dataFromLocalStorage.length) {
        dataFromLocalStorage.filter((item: any) => {
          if (item.simulationId === parseInt(searchParams?.get('id')!)) {
            result.push(item);
          }
        });
        result.length && deleteApiCall(result[0].simulationId);
      }
    } else {
      setisDeleteDialogueOpen(!isDeleteDialogueOpen);
      if (!localStorage.getItem('simulationData') || JSON.parse(localStorage.getItem('simulationData')!).length == 0)
        localStorage.removeItem('simulationData');
      localStorage.removeItem('manualInput');
      navigate('/new-simulation');
    }
  };
  const validateInputFields = (name: string, value: any, minVal: any, maxVal: any, isMandatory: any) => {
    const mandatoryText = 'This field is mandatory';
    const rangeText = 'The value entered is out of range.';
    let errorText;
    if (isMandatory) {
      if (!value) {
        errorText = mandatoryText;
      } else if (value) {
        errorText = null;
        if (parseFloat(value) >= parseFloat(minVal) && parseFloat(value) <= parseFloat(maxVal)) {
          errorText = null;
        } else {
          errorText = rangeText;
        }
      } else {
        errorText = null;
      }
    } else {
      if (value) {
        errorText = null;
        if (parseFloat(value) >= parseFloat(minVal) && parseFloat(value) <= parseFloat(maxVal)) {
          errorText = null;
        } else {
          errorText = rangeText;
        }
      } else if (!value) {
        errorText = rangeText;
      } else {
        errorText = null;
      }
    }
    switch (name) {
      case 'brinesampletemp':
        setBrineSampleTempErrorText(errorText!);
        errors.brinesampletemp = '';
        break;
      case 'ohtemp':
        setOhtempTempErrorText(errorText!);
        break;
      case 'washtemp':
        setWashTempErrorText(errorText!);
        break;
      case 'washprocesstemp':
        setWashProcessTempErrorText(errorText!);
        break;
      case 'velocitytemp':
        setVelocityTempErrorText(errorText!);
        break;
      case 'drumtemp':
        setDrumTempErrorText(errorText!);
        break;
      case 'distillation0':
        setDistillation0ErrorText(errorText!);
        break;
      case 'distillation0_1':
        setDistillation0_1ErrorText(errorText!);
        break;
      case 'outsideneutralizer':
        setOutsideNeutralizerText(errorText!);
        break;
    }
  };

  const handleDropDownChange = (event: any) => {
    let newSimulationObj;
    //edit screen
    if (simulationData && Object.keys(simulationData).length > 0) {
      if (configType === '1A' || configType === '1B') {
        newSimulationObj = {
          ...simulationData,
          desalter: { ...simulationData.desalter },
          overheadConditions: { ...simulationData.overheadConditions },
          towerData: { ...simulationData.towerData },
          liquidAssayStreams: {
            ...simulationData.liquidAssayStreams,
            hydrocarbonLiquid: {
              ...simulationData.liquidAssayStreams.hydrocarbonLiquid,
              distillation: { ...simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation },
            },
          },
          offGasData: { ...simulationData.offGasData },
          overheadWater: { ...simulationData.overheadWater },
        };
      } else {
        newSimulationObj = {
          ...simulationData,
          desalter: { ...simulationData.desalter },
          overheadConditions: { ...simulationData.overheadConditions },
          towerData: { ...simulationData.towerData },
          liquidAssayStreams: {
            ...simulationData.liquidAssayStreams,
            hydrocarbonLiquidHot: {
              ...simulationData.liquidAssayStreams.hydrocarbonLiquidHot,
              distillation: { ...simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation },
            },
            hydrocarbonLiquidCold: {
              ...simulationData.liquidAssayStreams.hydrocarbonLiquidCold,
              distillation: { ...simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation },
            },
            //distillation: { ...simulationData.hydrocarbonLiquid.distillation },
          },
          offGasData: { ...simulationData.offGasData },
          overheadWater: { ...simulationData.overheadWater },
        };
      }
    }
    let minValue, maxValue;
    if (
      event.target.id !== 'wet-reflux' &&
      event.target.id !== 'distillation' &&
      event.target.id !== 'distillation_1' &&
      event.target.id !== 'neutralizer' &&
      event.target.id !== 'tramp-amine' &&
      event.target.id !== 'steam-amine'
    ) {
      minValue = JSON.parse(event.target.menuItems).filter((m: any) => m.value === event.target.selectedValue)[0].min;
      maxValue = JSON.parse(event.target.menuItems).filter((m: any) => m.value === event.target.selectedValue)[0].max;
    }
    switch (event.target.id) {
      case 'crude-charge-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          subcategoryInputMapping.Crude_Charge_Rate &&
          Object.keys(subcategoryInputMapping.Crude_Charge_Rate).length &&
          subcategoryInputMapping.Crude_Charge_Rate[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Crude_Charge_Rate[0].unitSymbol) {
            setDefaultCrudeChargeUnitChanged(true);
          } else setDefaultCrudeChargeUnitChanged(false);
        }
        //manual entry edit case
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.desalter.crudeChargeMin = minValue;
          newSimulationObj.desalter.crudeChargeMax = maxValue;
          newSimulationObj.desalter.crudeCharge_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('crudecharge', newSimulationObj.desalter.crudeCharge, minValue, maxValue, 'NA', 'NA', true);
          setexpandedDesalter(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'desalter-temp-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Temperature).length &&
          subcategoryInputMapping.Temperature[0].unitSymbol
        ) {
          if (event.target.value !== '°' + subcategoryInputMapping.Temperature[0].unitSymbol) {
            setDefaultDesalterTempUnitChanged(true);
          } else setDefaultDesalterTempUnitChanged(false);
        }
        //manual entry edit case
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.desalter.desalterTemperatureMin = minValue;
          newSimulationObj.desalter.desalterTemperatureMax = maxValue;
          newSimulationObj.desalter.desalterTemperature_UOM = event.target.value === '°C' ? 'C' : event.target.value === '°F' ? 'F' : '';
          setDeslaterTempUOM(event.target.value);
          //need to update the following min max values in case of deslater tempearture unit dropdown change
          //brine sample temp, oh temp, wash temp ,wash process temp, velocity temp, drum temp and distillation
          //brine sample temp range updation
          let brinesampletempunits = require('../../constants/brinesample-units.json');
          let brineSampleTemperatureMin = brinesampletempunits.filter((m: any) => m.label === event.target.value)[0].min;
          let brineSampleTemperatureMax = brinesampletempunits.filter((m: any) => m.label === event.target.value)[0].max;
          newSimulationObj.desalter.brineSampleTemperatureMin = brineSampleTemperatureMin;
          newSimulationObj.desalter.brineSampleTemperatureMax = brineSampleTemperatureMax;
          //oh temp range updation
          let ohtempunits = require('../../constants/ohtemp-units.json');
          let OHTempMin = ohtempunits.filter((m: any) => m.label === event.target.value)[0].min;
          let OHTempMax = ohtempunits.filter((m: any) => m.label === event.target.value)[0].max;
          newSimulationObj.overheadConditions.OHTempMin = OHTempMin;
          newSimulationObj.overheadConditions.OHTempMax = OHTempMax;
          // combine reflux temp
          let refluxTemp = require('../../constants/combined-reflux.json');
          let refluxMin = refluxTemp.filter((m: any) => m.label === event.target.value)[0].min;
          let refluxMax = refluxTemp.filter((m: any) => m.label === event.target.value)[0].max;
          newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxTempMin = refluxMin;
          newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxTempMax = refluxMax;
          // wash temp range updation
          newSimulationObj.overheadConditions.washTempMin = brineSampleTemperatureMin;
          newSimulationObj.overheadConditions.washTempMax = brineSampleTemperatureMax;
          //wash process temp range updation
          let washprocesstempunits = require('../../constants/washprocess-temp-units.json');
          let washProcessTempMin = washprocesstempunits.filter((m: any) => m.label === event.target.value)[0].min;
          let washProcessTempMax = washprocesstempunits.filter((m: any) => m.label === event.target.value)[0].max;
          newSimulationObj.overheadConditions.washProcessTempMin = washProcessTempMin;
          newSimulationObj.overheadConditions.washProcessTempMax = washProcessTempMax;
          //velocity temp range updation
          newSimulationObj.overheadConditions.velocityTempMin = washProcessTempMin;
          newSimulationObj.overheadConditions.velocityTempMax = washProcessTempMax;
          //drum temp rage updation
          newSimulationObj.overheadConditions.drumTempMin = washProcessTempMin;
          newSimulationObj.overheadConditions.drumTempMax = washProcessTempMax;
          //distillation range updation
          let distillationunits = require('../../constants/distillation-units.json');
          let distillationMin = distillationunits.filter((m: any) => m.label === event.target.value)[0].min;
          let distillationMax = distillationunits.filter((m: any) => m.label === event.target.value)[0].max;
          if (configType === '1A' || configType === '1B') {
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation.distillationMin = distillationMin;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation.distillationMax = distillationMax;
          } else {
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation.hotDistillationMin = distillationMin;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation.hotDistillationMax = distillationMax;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation.coldDistillationMin = distillationMin;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation.coldDistillationMax = distillationMax;
          }
          setSimulationData(newSimulationObj);
          validate('desaltertemp', newSimulationObj.desalter.desalterTemperature, minValue, maxValue, 'NA', 'NA', true);
          validateInputFields(
            'brinesampletemp',
            newSimulationObj.desalter.brineSampleTemperature,
            brineSampleTemperatureMin,
            brineSampleTemperatureMax,
            true
          );
          validateInputFields('ohtemp', newSimulationObj.overheadConditions.OHTemp, OHTempMin, OHTempMax, false);
          validateInputFields(
            'washtemp',
            newSimulationObj.overheadConditions.washTemp,
            brineSampleTemperatureMin,
            brineSampleTemperatureMax,
            true
          );
          validateInputFields(
            'washprocesstemp',
            newSimulationObj.overheadConditions.washProcessTemp,
            washProcessTempMin,
            washProcessTempMax,
            true
          );
          validateInputFields(
            'velocitytemp',
            newSimulationObj.overheadConditions.velocityTemp,
            washProcessTempMin,
            washProcessTempMax,
            true
          );
          validateInputFields('drumtemp', newSimulationObj.overheadConditions.drumTemp, washProcessTempMin, washProcessTempMax, true);
          if (configType === '1A' || configType === '1B') {
            validateInputFields(
              'distillation0',
              newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[0],
              distillationMin,
              distillationMax,
              false
            );
          } else {
            validateInputFields(
              'distillation0',
              newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[0],
              distillationMin,
              distillationMax,
              false
            );
            validateInputFields(
              'distillation0_1',
              newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[0],
              distillationMin,
              distillationMax,
              false
            );
          }
          setexpandedDesalter(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'steam-tower-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          subcategoryInputMapping.Stripping_Steam_to_Tower &&
          Object.keys(subcategoryInputMapping.Stripping_Steam_to_Tower).length &&
          subcategoryInputMapping.Stripping_Steam_to_Tower[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Stripping_Steam_to_Tower[0].unitSymbol) {
            setDefaultStrippingSteamUnitChanged(true);
          } else setDefaultStrippingSteamUnitChanged(false);
        }
        //manual entry edit case
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.towerData.steamTowerMin = minValue;
          newSimulationObj.towerData.steamTowerMax = maxValue;
          newSimulationObj.towerData.steamTower_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('steamtower', newSimulationObj.towerData.steamTower, minValue, maxValue, 'NA', 'NA', true);
          setexpandedPanelTower(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'steam1ss-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          subcategoryInputMapping.First_Side_Stripper_Steam &&
          Object.keys(subcategoryInputMapping.First_Side_Stripper_Steam).length &&
          subcategoryInputMapping.First_Side_Stripper_Steam[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.First_Side_Stripper_Steam[0].unitSymbol) {
            setDefaultSteam1UnitChanged(true);
          } else setDefaultSteam1UnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.towerData.steam1SSMin = minValue;
          newSimulationObj.towerData.steam1SSMax = maxValue;
          newSimulationObj.towerData.steam1SS_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('steam1ss', newSimulationObj.towerData.steam1SS, minValue, maxValue, 'NA', 'NA', false);
          setexpandedPanelTower(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'steam2ss-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          subcategoryInputMapping.Second_Side_Stripper_Steam &&
          Object.keys(subcategoryInputMapping.Second_Side_Stripper_Steam).length &&
          subcategoryInputMapping.Second_Side_Stripper_Steam[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Second_Side_Stripper_Steam[0].unitSymbol) {
            setDefaultSteam2UnitChanged(true);
          } else setDefaultSteam2UnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.towerData.steam2SSMin = minValue;
          newSimulationObj.towerData.steam2SSMax = maxValue;
          newSimulationObj.towerData.steam2SS_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('steam2ss', newSimulationObj.towerData.steam2SS, minValue, maxValue, 'NA', 'NA', false);
          setexpandedPanelTower(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'steam3ss-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          subcategoryInputMapping.Third_Side_Stripper_Steam &&
          Object.keys(subcategoryInputMapping.Third_Side_Stripper_Steam).length &&
          subcategoryInputMapping.Third_Side_Stripper_Steam[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Third_Side_Stripper_Steam[0].unitSymbol) {
            setDefaultSteam3UnitChanged(true);
          } else setDefaultSteam3UnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.towerData.steam3SSMin = minValue;
          newSimulationObj.towerData.steam3SSMax = maxValue;
          newSimulationObj.towerData.steam3SS_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('steam3ss', newSimulationObj.towerData.steam3SS, minValue, maxValue, 'NA', 'NA', false);
          setexpandedPanelTower(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'steam4ss-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          subcategoryInputMapping.Fourth_Side_Stripper_Steam &&
          Object.keys(subcategoryInputMapping.Fourth_Side_Stripper_Steam).length &&
          subcategoryInputMapping.Fourth_Side_Stripper_Steam[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Fourth_Side_Stripper_Steam[0].unitSymbol) {
            setDefaultSteam4UnitChanged(true);
          } else setDefaultSteam4UnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.towerData.steam4SSMin = minValue;
          newSimulationObj.towerData.steam4SSMax = maxValue;
          newSimulationObj.towerData.steam4SS_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('steam4ss', newSimulationObj.towerData.steam4SS, minValue, maxValue, 'NA', 'NA', false);
          setexpandedPanelTower(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'steam5ss-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          subcategoryInputMapping.Fifth_Side_Stripper_Steam &&
          Object.keys(subcategoryInputMapping.Fifth_Side_Stripper_Steam).length &&
          subcategoryInputMapping.Fifth_Side_Stripper_Steam[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Fifth_Side_Stripper_Steam[0].unitSymbol) {
            setDefaultSteam5UnitChanged(true);
          } else setDefaultSteam5UnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.towerData.steam5SSMin = minValue;
          newSimulationObj.towerData.steam5SSMax = maxValue;
          newSimulationObj.towerData.steam5SS_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('steam5ss', newSimulationObj.towerData.steam5SS, minValue, maxValue, 'NA', 'NA', false);
          setexpandedPanelTower(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'steam6ss-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Sixth_Side_Stripper_Steam).length &&
          subcategoryInputMapping.Sixth_Side_Stripper_Steam[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Sixth_Side_Stripper_Steam[0].unitSymbol) {
            setDefaultSteam6UnitChanged(true);
          } else setDefaultSteam6UnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.towerData.steam6SSMin = minValue;
          newSimulationObj.towerData.steam6SSMax = maxValue;
          newSimulationObj.towerData.steam6SS_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('steam6ss', newSimulationObj.towerData.steam6SS, minValue, maxValue, 'NA', 'NA', false);
          setexpandedPanelTower(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'oh-press-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Atm_Tower_Top_Pressure).length &&
          subcategoryInputMapping.Atm_Tower_Top_Pressure[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Atm_Tower_Top_Pressure[0].unitSymbol) {
            setDefaultOhPressUnitChanged(true);
          } else setDefaultOhPressUnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.overheadConditions.OHPressMin = minValue;
          newSimulationObj.overheadConditions.OHPressMax = maxValue;
          newSimulationObj.overheadConditions.OHPress_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('ohpress', newSimulationObj.overheadConditions.OHPress, minValue, maxValue, 'NA', 'NA', false);
          setexpandedPanelOverhead(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'wash-rate-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Overhead_Water_Wash_Rate).length &&
          subcategoryInputMapping.Overhead_Water_Wash_Rate[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Overhead_Water_Wash_Rate[0].unitSymbol) {
            setDefaultWashRateUnitChanged(true);
          } else setDefaultWashRateUnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.overheadConditions.washRateMin = minValue;
          newSimulationObj.overheadConditions.washRateMax = maxValue;
          newSimulationObj.overheadConditions.washRate_UOM = event.target.value;
          newSimulationObj.overheadConditions.totalExternalWashRate_UOM = event.target.value;
          newSimulationObj.overheadConditions.totalExternalWashRateMax = maxValue;
          setSimulationData(newSimulationObj);
          validate('washrate', newSimulationObj.overheadConditions.washRate, minValue, maxValue, 'NA', 'NA', false);
          //validateNew('washrate', newSimulationObj.overheadConditions.washRate, minValue, maxValue);
          setexpandedPanelOverhead(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'washprocess-press-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Pressure_before_Water_Wash).length &&
          subcategoryInputMapping.Pressure_before_Water_Wash[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Pressure_before_Water_Wash[0].unitSymbol) {
            setDefaultWashProcessPressUnitChanged(true);
          } else setDefaultWashProcessPressUnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.overheadConditions.washProcessPressMin = minValue;
          newSimulationObj.overheadConditions.washProcessPressMax = maxValue;
          newSimulationObj.overheadConditions.washProcessPress_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('washprocesspress', newSimulationObj.overheadConditions.washProcessPress, minValue, maxValue, 'NA', 'NA', false);
          //validateNew('washrate', newSimulationObj.overheadConditions.washRate, minValue, maxValue);
          setexpandedPanelOverhead(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'velocity-press-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Pressure_at_Velocity_Calc).length &&
          subcategoryInputMapping.Pressure_at_Velocity_Calc[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Pressure_at_Velocity_Calc[0].unitSymbol) {
            setDefaultVelocityPressUnitChanged(true);
          } else setDefaultVelocityPressUnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.overheadConditions.velocityPressMin = minValue;
          newSimulationObj.overheadConditions.velocityPressMax = maxValue;
          newSimulationObj.overheadConditions.velocityPress_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('velocitypress', newSimulationObj.overheadConditions.velocityPress, minValue, maxValue, 'NA', 'NA', false);
          setexpandedPanelOverhead(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'drum-press-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Hot_Drum_Pressure).length &&
          subcategoryInputMapping.Hot_Drum_Pressure[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Hot_Drum_Pressure[0].unitSymbol) {
            setDefaultDrumPressUnitChanged(true);
          } else setDefaultDrumPressUnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.overheadConditions.drumPressMin = minValue;
          newSimulationObj.overheadConditions.drumPressMax = maxValue;
          newSimulationObj.overheadConditions.drumPress_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('drumpress', newSimulationObj.overheadConditions.drumPress, minValue, maxValue, 'NA', 'NA', false);
          setexpandedPanelOverhead(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'reflux-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow).length &&
          subcategoryInputMapping.Overhead_Liquid_Flow[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Overhead_Liquid_Flow[0].unitSymbol) {
            setDefaultRefluxUnitChanged(true);
          } else setDefaultRefluxUnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          if (configType === '1A' || configType === '1B') {
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.refluxMin = minValue;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.refluxMax = maxValue;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.reflux_UOM = event.target.value;
            setSimulationData(newSimulationObj);
            validate('reflux', newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.reflux, minValue, maxValue, 'NA', 'NA', false);
          } else {
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.hotRefluxMin = minValue;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.hotRefluxMax = maxValue;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.reflux_UOM = event.target.value;
            setSimulationData(newSimulationObj);
            validate('reflux', newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.reflux, minValue, maxValue, 'NA', 'NA', false);
          }
          setexpandedPanelHydrocarbon(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'reflux-unit_1':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow).length &&
          subcategoryInputMapping.Overhead_Liquid_Flow[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Overhead_Liquid_Flow[0].unitSymbol) {
            setDefaultReflux1UnitChanged(true);
          } else setDefaultReflux1UnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.coldRefluxMin = minValue;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.coldRefluxMax = maxValue;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.reflux_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('reflux_1', newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.reflux, minValue, maxValue, 'NA', 'NA', false);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'product-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow).length &&
          subcategoryInputMapping.Overhead_Liquid_Flow[1].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Overhead_Liquid_Flow[1].unitSymbol) {
            setDefaultProductUnitChanged(true);
          } else setDefaultProductUnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          if (configType === '1A' || configType === '1B') {
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.productMin = minValue;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.productMax = maxValue;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.product_UOM = event.target.value;
            setSimulationData(newSimulationObj);
            validate('product', newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.product, minValue, maxValue, 'NA', 'NA', true);
          } else {
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.hotProductMin = minValue;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.hotProductMax = maxValue;
            newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.product_UOM = event.target.value;
            setSimulationData(newSimulationObj);
            validate('product', newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.product, minValue, maxValue, 'NA', 'NA', true);
          }
          setexpandedPanelHydrocarbon(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'product-unit_1':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow).length &&
          subcategoryInputMapping.Overhead_Liquid_Flow[1].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Overhead_Liquid_Flow[1].unitSymbol) {
            setDefaultProduct1UnitChanged(true);
          } else setDefaultProduct1UnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.coldProductMin = minValue;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.coldProductMax = maxValue;
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.product_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('product_1', newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.product, minValue, maxValue, 'NA', 'NA', true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'off-gas-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Overhead_Gas_Flow).length &&
          subcategoryInputMapping.Overhead_Gas_Flow[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Overhead_Gas_Flow[0].unitSymbol) {
            setDefaultOffGasUnitChanged(true);
          } else setDefaultOffGasUnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.offGasData.offGasMin = minValue;
          newSimulationObj.offGasData.offGasMax = maxValue;
          newSimulationObj.offGasData.offGas_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('offgas', newSimulationObj.offGasData.offGas, minValue, maxValue, 'NA', 'NA', false);
          setexpandedPanelOffgas(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'neutra-rate-unit':
        //in case of default unit changed, setting a flag to show the message
        if (
          Object.keys(subcategoryInputMapping).length &&
          subcategoryInputMapping.Overhead_Neutralizer_Rate &&
          Object.keys(subcategoryInputMapping.Overhead_Neutralizer_Rate).length &&
          subcategoryInputMapping.Overhead_Neutralizer_Rate[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Overhead_Neutralizer_Rate[0].unitSymbol) {
            setDefaultNeutralizerRateUnitChanged(true);
          } else setDefaultNeutralizerRateUnitChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.overheadWater.neutralizerRateMin = minValue;
          newSimulationObj.overheadWater.neutralizerRateMax = maxValue;
          newSimulationObj.overheadWater.neutralizerRate_UOM = event.target.value;
          newSimulationObj.overheadWater.outsideNeutralizerMin = minValue;
          newSimulationObj.overheadWater.outsideNeutralizerMax = maxValue;
          newSimulationObj.overheadWater.outsideNeutralizer_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          validate('neutralizerrate', newSimulationObj.overheadWater.neutralizerRate, minValue, maxValue, 'NA', 'NA', false);
          validateInputFields('outsideneutralizer', newSimulationObj.overheadWater.outsideNeutralizer, minValue, maxValue, false);
          setexpandedPanelOverheadWater(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'wet-reflux':
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.liquidAssayStreams.isWetReflux = event.detail;
          setSimulationData(newSimulationObj);
          setexpandedPanelOverhead(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'distillation':
        if (simulationData && Object.keys(simulationData).length > 0) {
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillationType = event.target.value)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillationType = event.target.value);
          setSimulationData(newSimulationObj);
          setexpandedPanelHydrocarbon(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'distillation_1':
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillationType = event.target.value;
          setSimulationData(newSimulationObj);
          setexpandedPanelHydrocarbon(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'neutralizer':
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.overheadWater.neutralizerName = event.target.value;
          setSimulationData(newSimulationObj);
          setexpandedPanelOverheadWater(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'tramp-amine':
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.overheadWater.trampAmineData.trampAmine = event.target.value;
          setSimulationData(newSimulationObj);
          setexpandedPanelOverheadWater(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'steam-amine':
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.overheadWater.steamAmineData.steamAmine = event.target.value;
          setSimulationData(newSimulationObj);
          setexpandedPanelOverheadWater(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
      case 'combined-reflux-unit':
        if (
          Object.keys(subcategoryInputMapping).length &&
          Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow).length &&
          subcategoryInputMapping.Overhead_Liquid_Flow[0].unitSymbol
        ) {
          if (event.target.value !== subcategoryInputMapping.Overhead_Liquid_Flow[0].unitSymbol) {
            setDefaultCombinedRefluxFlowChanged(true);
          } else setDefaultCombinedRefluxFlowChanged(false);
        }
        if (simulationData && Object.keys(simulationData).length > 0) {
          newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxFlow_UOM = event.target.value;
          setSimulationData(newSimulationObj);
          setexpandedPanelOverheadWater(true);
          if (!searchParams?.get('id')) {
            localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
          }
        }
        //manual entry create case
        else {
          setManualInput(event.target.id, event.target.value, minValue, maxValue);
        }
        break;
    }
  };

  const setDesalterRangeValues = (newSimulationObj: any) => {
    newSimulationObj.desalter.crudeGravityMin = rangeConstants.CRUDE_GRAVITY_MIN;
    newSimulationObj.desalter.crudeGravityMax = rangeConstants.CRUDE_GRAVITY_MAX;
    newSimulationObj.desalter.washPercentageData.washPercentageMin = rangeConstants.WASH1_PERCENTAGE_MIN;
    newSimulationObj.desalter.washPercentageData.washPercentageMax = rangeConstants.WASH1_PERCENTAGE_MAX;
    newSimulationObj.desalter.washPercentageData.washPercentageMin1 = rangeConstants.WASH1_PERCENTAGE_MIN1;
    newSimulationObj.desalter.washPercentageData.washPercentageMax1 = rangeConstants.WASH1_PERCENTAGE_MAX1;
    newSimulationObj.desalter.brineRecyclePercentageMin = rangeConstants.BRINE_RECYCLE_MIN;
    newSimulationObj.desalter.brineRecyclePercentageMax = rangeConstants.BRINE_RECYCLE_MAX;
    newSimulationObj.desalter.percentageAtmH2OtoDesalterMin = rangeConstants.PERCENTAGE_ATMH2O_DESALTER_MIN_B;
    newSimulationObj.desalter.percentageAtmH2OtoDesalterMax = rangeConstants.PERCENTAGE_ATMH2O_DESALTER_MAX_B;
    newSimulationObj.desalter.percentageAtmH2OtoSWSMin = rangeConstants.PERCENTAGE_ATMH2O_SWS_MIN_1B;
    newSimulationObj.desalter.percentageAtmH2OtoSWSMax = rangeConstants.PERCENTAGE_ATMH2O_SWS_MAX_1B;
    newSimulationObj.desalter.percentageSSWtoDesalterMin = rangeConstants.PERCENTAGE_SSW_DESALTER_MIN;
    newSimulationObj.desalter.percentageSSWtoDesalterMax = rangeConstants.PERCENTAGE_SSW_DESALTER_MAX;
    newSimulationObj.desalter.brinepHData.brinepHMin = rangeConstants.BRINE_PH_MIN;
    newSimulationObj.desalter.brinepHData.brinepHMax = rangeConstants.BRINE_PH_MAX;
    newSimulationObj.desalter.brinepHData.brine2PHMin = rangeConstants.BRINE_PH_MIN;
    newSimulationObj.desalter.brinepHData.brine2PHMax = rangeConstants.BRINE_PH_MAX;
    newSimulationObj.desalter.desCrudeBSWData.desCrudeBSWMin = rangeConstants.DESCRUDE_BSW_MIN;
    newSimulationObj.desalter.desCrudeBSWData.desCrudeBSWMax = rangeConstants.DESCRUDE_BSW_MAX;
    newSimulationObj.desalter.chargeGravityMin = rangeConstants.CHARGE_GRAVITY_MIN;
    newSimulationObj.desalter.chargeGravityMax = rangeConstants.CHARGE_GRAVITY_MAX;
  };

  const setHydroCarbonLiquidRangeValues = (newSimulationObj: any) => {
    newSimulationObj.liquidAssayStreams.isWetReflux = true;
    let configType = localStorage.getItem('configType')!;
    if (configType === '1A' || configType === '1B') {
      newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.gravityMin = rangeConstants.GRAVITY_MIN;
      newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.gravityMax = rangeConstants.GRAVITY_MAX;
      newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillationType = 'TBP';
    } else {
      newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.hotGravityMin = rangeConstants.GRAVITY_MIN;
      newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.hotGravityMax = rangeConstants.GRAVITY_MAX;
      newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.coldGravityMin = rangeConstants.GRAVITY1_MIN;
      newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.coldGravityMax = rangeConstants.GRAVITY1_MAX;
      newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillationType = 'TBP';
      newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillationType = 'TBP';
    }
  };

  const setOffGasRangeValues = (newSimulationObj: any) => {
    newSimulationObj.offGasData.H2Min = rangeConstants.OFFGAS_MIN;
    newSimulationObj.offGasData.H2Max = rangeConstants.OFFGAS_MAX;
    newSimulationObj.offGasData.H2SMin = rangeConstants.OFFGAS_MIN;
    newSimulationObj.offGasData.H2SMax = rangeConstants.OFFGAS_MAX;
    newSimulationObj.offGasData.CO2Min = rangeConstants.OFFGAS_MIN;
    newSimulationObj.offGasData.CO2Max = rangeConstants.OFFGAS_MAX;
    newSimulationObj.offGasData.C1Min = rangeConstants.OFFGAS_MIN;
    newSimulationObj.offGasData.C1Max = rangeConstants.OFFGAS_MAX;
    newSimulationObj.offGasData.C2Min = rangeConstants.OFFGAS_MIN;
    newSimulationObj.offGasData.C2Max = rangeConstants.OFFGAS_MAX;
    newSimulationObj.offGasData.C3Min = rangeConstants.OFFGAS_MIN;
    newSimulationObj.offGasData.C3Max = rangeConstants.OFFGAS_MAX;
    newSimulationObj.offGasData.iC4Min = rangeConstants.OFFGAS_MIN;
    newSimulationObj.offGasData.iC4Max = rangeConstants.OFFGAS_MAX;
    newSimulationObj.offGasData.nC4Min = rangeConstants.OFFGAS_MIN;
    newSimulationObj.offGasData.nC4Max = rangeConstants.OFFGAS_MAX;
    newSimulationObj.offGasData.iC5Min = rangeConstants.OFFGAS_MIN;
    newSimulationObj.offGasData.iC5Max = rangeConstants.OFFGAS_MAX;
    newSimulationObj.offGasData.nC5Min = rangeConstants.OFFGAS_MIN;
    newSimulationObj.offGasData.nC5Max = rangeConstants.OFFGAS_MAX;
    newSimulationObj.offGasData.nC6Min = rangeConstants.OFFGAS_MIN;
    newSimulationObj.offGasData.nC6Max = rangeConstants.OFFGAS_MAX;
  };

  const setOverheadWaterRangeValues = (newSimulationObj: any) => {
    let configType = localStorage.getItem('configType')!;
    newSimulationObj.overheadWater.pHData.PHMin = rangeConstants.PH_MIN;
    newSimulationObj.overheadWater.pHData.PHMax = rangeConstants.PH_MAX;
    newSimulationObj.overheadWater.clData.chlorideMin = rangeConstants.CL_MIN;
    newSimulationObj.overheadWater.clData.chlorideMax = rangeConstants.CL_MAX;
    newSimulationObj.overheadWater.NH3Data.NH3Min = rangeConstants.NH3_MIN;
    newSimulationObj.overheadWater.NH3Data.NH3Max = rangeConstants.NH3_MAX;
    newSimulationObj.overheadWater.sulfideMin = rangeConstants.SULFIDE_MIN;
    newSimulationObj.overheadWater.sulfideMax = rangeConstants.SULFIDE_MAX;
    newSimulationObj.overheadWater.neutralizerName = 'Ammonia Gas';
    newSimulationObj.overheadWater.trampAmineData.trampAmineppmMin = rangeConstants.AMINE_PPM_MIN;
    newSimulationObj.overheadWater.trampAmineData.trampAmineppmMax = rangeConstants.AMINE_PPM_MAX;
    newSimulationObj.overheadWater.steamAmineData.steamAmineppmMin = rangeConstants.AMINE_PPM_MIN;
    newSimulationObj.overheadWater.steamAmineData.steamAmineppmMax =
      configType === '1B' ? rangeConstants.STEAM_AMINE_PPM_MAX : rangeConstants.AMINE_PPM_MAX;
    newSimulationObj.overheadWater.trampAmineData.trampAmine = 'None';
    newSimulationObj.overheadWater.steamAmineData.steamAmine = 'None';
    newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxFlowMin = rangeConstants.COMBINED_REFLUX_FLOW_MIN;
    newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxFlowMax = rangeConstants.COMBINED_REFLUX_FLOW_MAX;
  };

  const setOverheadWaterRangeValuesManual = () => {
    setManualInput('ph', '', rangeConstants.PH_MIN, rangeConstants.PH_MAX);
    setManualInput('ph2', '', rangeConstants.PH_MIN, rangeConstants.PH_MAX);
    setManualInput('cl', '', rangeConstants.CL_MIN, rangeConstants.CL_MAX);
    setManualInput('cl2', '', rangeConstants.CL_MIN, rangeConstants.CL_MAX);
    setManualInput('cl', '', rangeConstants.CL_MIN, rangeConstants.CL_MAX);
    setManualInput('cl2', '', rangeConstants.CL_MIN, rangeConstants.CL_MAX);
    setManualInput('nh3', '', rangeConstants.NH3_MIN, rangeConstants.NH3_MAX);
    setManualInput('nh32', '', rangeConstants.NH3_MIN, rangeConstants.NH3_MAX);
    setManualInput('trampamine', '', rangeConstants.AMINE_PPM_MIN, rangeConstants.AMINE_PPM_MAX);
    setManualInput('trampamine2', '', rangeConstants.AMINE_PPM_MIN, rangeConstants.AMINE_PPM_MAX);
    setManualInput(
      'steamamine',
      '',
      rangeConstants.AMINE_PPM_MIN,
      configType === '1B' ? rangeConstants.STEAM_AMINE_PPM_MAX : rangeConstants.AMINE_PPM_MAX
    );
    setManualInput('steamamine2', '', rangeConstants.AMINE_PPM_MIN, rangeConstants.AMINE_PPM_MAX);
  };
  const handleBackDropClose = () => {
    setIsLoading(false);
  };

  const setOffGasRangeValuesManual = () => {
    setManualInput('h2', '', rangeConstants.OFFGAS_MIN, rangeConstants.OFFGAS_MAX);
    setManualInput('h2s', '', rangeConstants.OFFGAS_MIN, rangeConstants.OFFGAS_MAX);
    setManualInput('co2', '', rangeConstants.OFFGAS_MIN, rangeConstants.OFFGAS_MAX);
    setManualInput('c1', '', rangeConstants.OFFGAS_MIN, rangeConstants.OFFGAS_MAX);
    setManualInput('c2', '', rangeConstants.OFFGAS_MIN, rangeConstants.OFFGAS_MAX);
    setManualInput('c3', '', rangeConstants.OFFGAS_MIN, rangeConstants.OFFGAS_MAX);
    setManualInput('ic4', '', rangeConstants.OFFGAS_MIN, rangeConstants.OFFGAS_MAX);
    setManualInput('nc4', '', rangeConstants.OFFGAS_MIN, rangeConstants.OFFGAS_MAX);
    setManualInput('ic5', '', rangeConstants.OFFGAS_MIN, rangeConstants.OFFGAS_MAX);
    setManualInput('nc5', '', rangeConstants.OFFGAS_MIN, rangeConstants.OFFGAS_MAX);
    setManualInput('nc6', '', rangeConstants.OFFGAS_MIN, rangeConstants.OFFGAS_MAX);
  };
  const setDesalterRangeValuesManual = () => {
    setManualInput('crudegravity', '', rangeConstants.CRUDE_GRAVITY_MIN, rangeConstants.CRUDE_GRAVITY_MAX);
    setManualInput('washpercentage', '', rangeConstants.WASH1_PERCENTAGE_MIN, rangeConstants.WASH1_PERCENTAGE_MAX);
    setManualInput('wash2percentage', '', rangeConstants.WASH1_PERCENTAGE_MIN, rangeConstants.WASH1_PERCENTAGE_MAX);
    setManualInput('brinerecycle', '', rangeConstants.BRINE_RECYCLE_MIN, rangeConstants.BRINE_RECYCLE_MAX);
    setManualInput(
      'percentageAtmH2OtoDesalter',
      '',
      rangeConstants.PERCENTAGE_ATMH2O_DESALTER_MIN_B,
      rangeConstants.PERCENTAGE_ATMH2O_DESALTER_MAX_B
    );
    setManualInput('percentageAtmH2OtoSWS', '', rangeConstants.PERCENTAGE_ATMH2O_SWS_MIN_1B, rangeConstants.PERCENTAGE_ATMH2O_SWS_MAX_1B);
    setManualInput('percentageSSWtoDesalter', '', rangeConstants.PERCENTAGE_SSW_DESALTER_MIN, rangeConstants.PERCENTAGE_SSW_DESALTER_MAX);
    setManualInput('brineph', '', rangeConstants.BRINE_PH_MIN, rangeConstants.BRINE_PH_MAX);
    setManualInput('brineph2', '', rangeConstants.BRINE_PH_MIN, rangeConstants.BRINE_PH_MAX);
    // if(deslaterTempUOM === '°F')
    // setManualInput('brinesampletemp', '', rangeConstants.BRINE_SAMPLE_TEMP_MIN_F, rangeConstants.BRINE_SAMPLE_TEMP_MAX_F);
    // else
    // setManualInput('brinesampletemp', '', rangeConstants.BRINE_SAMPLE_TEMP_MIN_C, rangeConstants.BRINE_SAMPLE_TEMP_MAX_C);
    setManualInput('descrude', '', rangeConstants.DESCRUDE_BSW_MIN, rangeConstants.DESCRUDE_BSW_MAX);
    setManualInput('descrude1', '', rangeConstants.DESCRUDE_BSW_MIN, rangeConstants.DESCRUDE_BSW_MAX);
    setManualInput('descrude2', '', rangeConstants.DESCRUDE_BSW_MIN, rangeConstants.DESCRUDE_BSW_MAX);
  };
  const setHydroCarbonLiquidRangeValuesManual = () => {
    setManualInput('gravity', '', rangeConstants.GRAVITY_MIN, rangeConstants.GRAVITY_MAX);
    setManualInput('gravity1', '', rangeConstants.GRAVITY1_MIN, rangeConstants.GRAVITY1_MAX);
    // setManualInput('distillation0_1', '', rangeConstants.DISTILLATION0_MIN_F, rangeConstants.DISTILLATION0_MAX_F);
    // setManualInput('distillation0', '', rangeConstants.DISTILLATION0_MIN_F, rangeConstants.DISTILLATION0_MAX_F);
  };

  const setRangeValues = () => {
    let configType = localStorage.getItem('configType')!;
    if (localStorage.getItem('manualInput') !== null) {
      let manualInput = JSON.parse(localStorage.getItem('manualInput')!);
      let newSimulationObj;
      if (configType === '1A' || configType === '1B') {
        newSimulationObj = {
          ...manualInput,
          desalter: {
            ...manualInput.desalter,
            washPercentageData: { ...manualInput.desalter.washPercentageData },
            brinepHData: { ...manualInput.desalter.brinepHData },
            desCrudeBSWData: { ...manualInput.desalter.desCrudeBSWData },
          },
          overheadConditions: { ...manualInput.overheadConditions },
          liquidAssayStreams: {
            ...manualInput.liquidAssayStreams,
            hydrocarbonLiquid: { ...manualInput.liquidAssayStreams.hydrocarbonLiquid },
            distillation: { ...manualInput.liquidAssayStreams.hydrocarbonLiquid.distillation },
          },
          offGasData: { ...manualInput.offGasData },
          overheadWater: {
            ...manualInput.overheadWater,
            pHData: { ...manualInput.overheadWater.pHData },
            clData: { ...manualInput.overheadWater.clData },
            NH3Data: { ...manualInput.overheadWater.NH3Data },
          },
        };
      } else {
        newSimulationObj = {
          ...manualInput,
          desalter: {
            ...manualInput.desalter,
            washPercentageData: { ...manualInput.desalter.washPercentageData },
            brinepHData: { ...manualInput.desalter.brinepHData },
            desCrudeBSWData: { ...manualInput.desalter.desCrudeBSWData },
          },
          overheadConditions: { ...manualInput.overheadConditions },
          liquidAssayStreams: {
            ...manualInput.liquidAssayStreams,
            hydrocarbonLiquidHot: {
              ...manualInput.liquidAssayStreams.hydrocarbonLiquidHot,
              distillation: { ...manualInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation },
            },
            hydrocarbonLiquidCold: {
              ...manualInput.liquidAssayStreams.hydrocarbonLiquidCold,
              distillation: { ...manualInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation },
            },
            //distillation: { ...manualInput.hydrocarbonLiquid.distillation },
          },
          offGasData: { ...manualInput.offGasData },
          overheadWater: {
            ...manualInput.overheadWater,
            pHData: { ...manualInput.overheadWater.pHData },
            clData: { ...manualInput.overheadWater.clData },
            NH3Data: { ...manualInput.overheadWater.NH3Data },
          },
        };
      }
      //desalter
      setDesalterRangeValues(newSimulationObj);
      //hydro carbon liquid
      setHydroCarbonLiquidRangeValues(newSimulationObj);
      //off gas
      setOffGasRangeValues(newSimulationObj);
      //overhead water
      setOverheadWaterRangeValues(newSimulationObj);
      localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
      setSimulationData(newSimulationObj);
    } else {
      //desalter
      setDesalterRangeValuesManual();
      //hydro carbon liquid
      setHydroCarbonLiquidRangeValuesManual();
      //off gas
      setOffGasRangeValuesManual();
      //overhead water
      setOverheadWaterRangeValuesManual();
    }
  };
  const setManualInput = (id: string, value: any, min: any, max: any) => {
    let configType = localStorage.getItem('configType')!;
    let manualInput = {
      inputDate: id === 'date' ? value : '',
      desalter: {
        brinepHData: {},
        desCrudeBSWData: {},
        crudeCharge_UOM: id === 'crude-charge-unit' ? value : '',
        crudeChargeMin: id === 'crudecharge' || id === 'crude-charge-unit' ? min : '',
        crudeChargeMax: id === 'crudecharge' || id === 'crude-charge-unit' ? max : '',
        washPercentageData: {},
      },
      liquidAssayStreams:
        configType === '1A' || configType === '1B'
          ? {
              hydrocarbonLiquid: {
                reflux_UOM: id === 'reflux-unit' ? value : '',
                refluxMin: id === 'reflux' || id === 'reflux-unit' ? min : '',
                refluxMax: id === 'reflux' || id === 'reflux-unit' ? max : '',
                product_UOM: id === 'product-unit' ? value : '',
                productMin: id === 'product' || id === 'product-unit' ? min : '',
                productMax: id === 'product' || id === 'product-unit' ? max : '',
                distillation: {
                  0: id === 'distillation0' ? value : '',
                },
              },
              isWetReflux: '',
            }
          : {
              hydrocarbonLiquidHot: {
                reflux_UOM: id === 'reflux-unit' ? value : '',
                hotRefluxMin: id === 'reflux' || id === 'reflux-unit' ? min : '',
                hotRefluxMax: id === 'reflux' || id === 'reflux-unit' ? max : '',
                product_UOM: id === 'product-unit' ? value : '',
                hotProductMin: id === 'product' || id === 'product-unit' ? min : '',
                hotProductMax: id === 'product' || id === 'product-unit' ? max : '',
                distillation: {
                  0: id === 'distillation0' ? value : '',
                },
              },
              hydrocarbonLiquidCold: {
                reflux_UOM: id === 'reflux-unit_1' ? value : '',
                coldRefluxMin: id === 'reflux_1' || id === 'reflux-unit_1' ? min : '',
                coldRefluxMax: id === 'reflux_1' || id === 'reflux-unit_1' ? max : '',
                product_UOM: id === 'product-unit_1' ? value : '',
                coldProductMin: id === 'product_1' || id === 'product-unit_1' ? min : '',
                coldProductMax: id === 'product_1' || id === 'product-unit_1' ? max : '',
                distillation: {
                  0: id === 'distillation0_1' ? value : '',
                },
              },
              isWetReflux: '',
            },
      offGasData: {},
      overheadConditions: {},
      overheadWater: {
        pHData: {},
        clData: {},
        NH3Data: {},
        trampAmineData: {},
        steamAmineData: {},
        combinedRefluxData: { combinedRefluxFlow_UOM: id === 'combined-reflux-unit' ? value : '' },
      },
      towerData: {},
    };
    localStorage.setItem('manualInput', JSON.stringify(manualInput));
  };
  const handleDateChange = (event: any) => {
    let newSimulationObj;
    //manual entry edit case
    if (simulationData && Object.keys(simulationData).length > 0) {
      if (configType === '1A' || configType === '1B') {
        newSimulationObj = {
          ...simulationData,
          desalter: {
            ...simulationData.desalter,
            washPercentageData: { ...simulationData.desalter.washPercentageData },
            brinepHData: { ...simulationData.desalter.brinepHData },
            desCrudeBSWData: { ...simulationData.desalter.desCrudeBSWData },
          },
          towerData: { ...simulationData.towerData },
          overheadConditions: { ...simulationData.overheadConditions },
          liquidAssayStreams: {
            ...simulationData.liquidAssayStreams,
            hydrocarbonLiquid: {
              ...simulationData.liquidAssayStreams.hydrocarbonLiquid,
              distillation: { ...simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation },
            },
          },
          offGasData: { ...simulationData.offGasData },
          overheadWater: {
            ...simulationData.overheadWater,
            pHData: { ...simulationData.overheadWater.pHData },
            clData: { ...simulationData.overheadWater.clData },
            NH3Data: { ...simulationData.overheadWater.NH3Data },
          },
        };
      } else {
        newSimulationObj = {
          ...simulationData,
          desalter: {
            ...simulationData.desalter,
            washPercentageData: { ...simulationData.desalter.washPercentageData },
            brinepHData: { ...simulationData.desalter.brinepHData },
            desCrudeBSWData: { ...simulationData.desalter.desCrudeBSWData },
          },
          towerData: { ...simulationData.towerData },
          overheadConditions: { ...simulationData.overheadConditions },
          liquidAssayStreams: {
            ...simulationData.liquidAssayStreams,
            hydrocarbonLiquidHot: {
              ...simulationData.liquidAssayStreams.hydrocarbonLiquidHot,
              distillation: { ...simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation },
            },
            hydrocarbonLiquidCold: {
              ...simulationData.liquidAssayStreams.hydrocarbonLiquidCold,
              distillation: { ...simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation },
            },
            //distillation: { ...simulationData.hydrocarbonLiquid.distillation },
          },
          offGasData: { ...simulationData.offGasData },
          overheadWater: {
            ...simulationData.overheadWater,
            pHData: { ...simulationData.overheadWater.pHData },
            clData: { ...simulationData.overheadWater.clData },
            NH3Data: { ...simulationData.overheadWater.NH3Data },
          },
        };
      }
      let formatedDate = changeToShortFormat(new Date(event.$d));
      newSimulationObj.inputDate = formatedDate;
      setexpandedDesalter(true);
      setSimulationData(newSimulationObj);
    }
  };
  const handleInputChange = (event: any) => {
    handleChange(event);
    let newSimulationObj;
    //manual entry edit case
    if (simulationData && Object.keys(simulationData).length > 0) {
      if (configType === '1A' || configType === '1B') {
        newSimulationObj = {
          ...simulationData,
          desalter: {
            ...simulationData.desalter,
            washPercentageData: { ...simulationData.desalter.washPercentageData },
            brinepHData: { ...simulationData.desalter.brinepHData },
            desCrudeBSWData: { ...simulationData.desalter.desCrudeBSWData },
          },
          towerData: { ...simulationData.towerData },
          overheadConditions: { ...simulationData.overheadConditions },
          liquidAssayStreams: {
            ...simulationData.liquidAssayStreams,
            hydrocarbonLiquid: {
              ...simulationData.liquidAssayStreams.hydrocarbonLiquid,
              distillation: { ...simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation },
            },
          },
          offGasData: { ...simulationData.offGasData },
          overheadWater: {
            ...simulationData.overheadWater,
            pHData: { ...simulationData.overheadWater.pHData },
            clData: { ...simulationData.overheadWater.clData },
            NH3Data: { ...simulationData.overheadWater.NH3Data },
          },
        };
      } else {
        newSimulationObj = {
          ...simulationData,
          desalter: {
            ...simulationData.desalter,
            washPercentageData: { ...simulationData.desalter.washPercentageData },
            brinepHData: { ...simulationData.desalter.brinepHData },
            desCrudeBSWData: { ...simulationData.desalter.desCrudeBSWData },
          },
          towerData: { ...simulationData.towerData },
          overheadConditions: { ...simulationData.overheadConditions },
          liquidAssayStreams: {
            ...simulationData.liquidAssayStreams,
            hydrocarbonLiquidHot: {
              ...simulationData.liquidAssayStreams.hydrocarbonLiquidHot,
              distillation: { ...simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation },
            },
            hydrocarbonLiquidCold: {
              ...simulationData.liquidAssayStreams.hydrocarbonLiquidCold,
              distillation: { ...simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation },
            },
            //distillation: { ...simulationData.hydrocarbonLiquid.distillation },
          },
          offGasData: { ...simulationData.offGasData },
          overheadWater: {
            ...simulationData.overheadWater,
            pHData: { ...simulationData.overheadWater.pHData },
            clData: { ...simulationData.overheadWater.clData },
            NH3Data: { ...simulationData.overheadWater.NH3Data },
          },
        };
      }
      switch (event.target.id) {
        //Desalter Panel
        case 'crudecharge':
          newSimulationObj.desalter.crudeCharge = event.detail;
          setexpandedDesalter(true);
          break;
        case 'crudegravity':
          newSimulationObj.desalter.crudeGravity = event.detail;
          setexpandedDesalter(true);
          break;
        case 'washpercentage':
          newSimulationObj.desalter.washPercentageData.washPercentage = event.detail;
          setexpandedDesalter(true);
          break;
        case 'wash2percentage':
          newSimulationObj.desalter.washPercentageData.washPercentage2 = event.detail;
          setexpandedDesalter(true);
          break;
        case 'brinerecycle':
          newSimulationObj.desalter.brineRecyclePercentage = event.detail;
          setexpandedDesalter(true);
          break;
        case 'percentageAtmH2OtoDesalter':
          newSimulationObj.desalter.percentageAtmH2OtoDesalter = event.detail;
          setexpandedDesalter(true);
          break;
        case 'percentageAtmH2OtoSWS':
          newSimulationObj.desalter.percentageAtmH2OtoSWS = event.detail;
          setexpandedDesalter(true);
          break;
        case 'percentageSSWtoDesalter':
          newSimulationObj.desalter.percentageSSWtoDesalter = event.detail;
          setexpandedDesalter(true);
          break;
        case 'desaltertemp':
          newSimulationObj.desalter.desalterTemperature = event.detail;
          setexpandedDesalter(true);
          break;
        case 'brineph':
          newSimulationObj.desalter.brinepHData.brinepH = event.detail;
          setexpandedDesalter(true);
          break;
        case 'brineph2':
          newSimulationObj.desalter.brinepHData.brinepH2 = event.detail;
          setexpandedDesalter(true);
          break;
        case 'brinesampletemp':
          newSimulationObj.desalter.brineSampleTemperature = event.detail;
          setBrineSampleTempErrorText('');
          setexpandedDesalter(true);
          break;
        case 'descrude':
          newSimulationObj.desalter.desCrudeBSWData.desCrudeBSW = event.detail;
          setexpandedDesalter(true);
          break;
        case 'descrude1':
          newSimulationObj.desalter.desCrudeBSWData.desCrudeBSW = event.detail;
          setexpandedDesalter(true);
          break;
        case 'descrude2':
          newSimulationObj.desalter.desCrudeBSWData.desCrudeBSW2 = event.detail;
          setexpandedDesalter(true);
          break;
        //Tower Panel
        case 'steamtower':
          newSimulationObj.towerData.steamTower = event.detail;
          setexpandedPanelTower(true);
          break;
        case 'steam1ss':
          newSimulationObj.towerData.steam1SS = event.detail;
          setexpandedPanelTower(true);
          break;
        case 'steam2ss':
          newSimulationObj.towerData.steam2SS = event.detail;
          setexpandedPanelTower(true);
          break;
        case 'steam3ss':
          newSimulationObj.towerData.steam3SS = event.detail;
          setexpandedPanelTower(true);
          break;
        case 'steam4ss':
          newSimulationObj.towerData.steam4SS = event.detail;
          setexpandedPanelTower(true);
          break;
        case 'steam5ss':
          newSimulationObj.towerData.steam5SS = event.detail;
          setexpandedPanelTower(true);
          break;
        case 'steam6ss':
          newSimulationObj.towerData.steam6SS = event.detail;
          setexpandedPanelTower(true);
          break;
        //Overhead conditions
        case 'ohtemp':
          newSimulationObj.overheadConditions.OHTemp = event.detail;
          setOhtempTempErrorText('');
          setexpandedPanelOverhead(true);
          break;
        case 'ohpress':
          newSimulationObj.overheadConditions.OHPress = event.detail;
          setexpandedPanelOverhead(true);
          break;
        case 'washrate':
          newSimulationObj.overheadConditions.washRate = event.detail;
          setexpandedPanelOverhead(true);
          break;
        case 'washtemp':
          newSimulationObj.overheadConditions.washTemp = event.detail;
          setWashTempErrorText('');
          setexpandedPanelOverhead(true);
          break;
        case 'washprocesstemp':
          newSimulationObj.overheadConditions.washProcessTemp = event.detail;
          setWashProcessTempErrorText('');
          setexpandedPanelOverhead(true);
          break;
        case 'washprocesspress':
          newSimulationObj.overheadConditions.washProcessPress = event.detail;
          setexpandedPanelOverhead(true);
          break;
        case 'velocitytemp':
          newSimulationObj.overheadConditions.velocityTemp = event.detail;
          setVelocityTempErrorText('');
          setexpandedPanelOverhead(true);
          break;
        case 'velocitypress':
          newSimulationObj.overheadConditions.velocityPress = event.detail;
          setexpandedPanelOverhead(true);
          break;
        case 'drumtemp':
          newSimulationObj.overheadConditions.drumTemp = event.detail;
          setDrumTempErrorText('');
          setexpandedPanelOverhead(true);
          break;
        case 'drumpress':
          newSimulationObj.overheadConditions.drumPress = event.detail;
          setexpandedPanelOverhead(true);
          break;
        case 'reflux':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.reflux = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.reflux = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'reflux_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.reflux = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'product':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.product = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.product = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'product_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.product = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'gravity':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.gravity = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.gravity = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'gravity_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.gravity = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation0':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[0] = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[0] = event.detail);
          setDistillation0ErrorText('');
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation0_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[0] = event.detail;
          setDistillation0_1ErrorText('');
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation5':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[5] = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[5] = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation5_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[5] = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation10':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[10] = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[10] = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation10_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[10] = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation20':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[20] = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[20] = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation20_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[20] = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation30':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[30] = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[30] = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation30_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[30] = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation50':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[50] = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[50] = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation50_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[50] = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation70':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[70] = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[70] = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation70_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[70] = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation80':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[80] = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[80] = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation80_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[80] = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation90':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[90] = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[90] = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation90_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[90] = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation95':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[95] = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[95] = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation95_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[95] = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation100':
          configType === '1A' || configType === '1B'
            ? (newSimulationObj.liquidAssayStreams.hydrocarbonLiquid.distillation[100] = event.detail)
            : (newSimulationObj.liquidAssayStreams.hydrocarbonLiquidHot.distillation[100] = event.detail);
          setexpandedPanelHydrocarbon(true);
          break;
        case 'distillation100_1':
          newSimulationObj.liquidAssayStreams.hydrocarbonLiquidCold.distillation[100] = event.detail;
          setexpandedPanelHydrocarbon(true);
          break;
        case 'offgas':
          newSimulationObj.offGasData.offGas = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'h2':
          newSimulationObj.offGasData.H2 = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'h2s':
          newSimulationObj.offGasData.H2S = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'co2':
          newSimulationObj.offGasData.CO2 = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'c1':
          newSimulationObj.offGasData.C1 = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'c2':
          newSimulationObj.offGasData.C2 = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'c3':
          newSimulationObj.offGasData.C3 = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'ic4':
          newSimulationObj.offGasData.iC4 = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'nc4':
          newSimulationObj.offGasData.nC4 = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'ic5':
          newSimulationObj.offGasData.iC5 = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'nc5':
          newSimulationObj.offGasData.nC5 = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'nc6':
          newSimulationObj.offGasData.nC6 = event.detail;
          setexpandedPanelOffgas(true);
          break;
        case 'ph':
          newSimulationObj.overheadWater.pHData.pH = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'cl':
          newSimulationObj.overheadWater.clData.cl = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'nh3':
          newSimulationObj.overheadWater.NH3Data.NH3 = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'ph2':
          newSimulationObj.overheadWater.pHData.pH_2 = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'cl2':
          newSimulationObj.overheadWater.clData.cl_2 = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'nh32':
          newSimulationObj.overheadWater.NH3Data.NH3_2 = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'neutralizerrate':
          newSimulationObj.overheadWater.neutralizerRate = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'outsideneutralizer':
          newSimulationObj.overheadWater.outsideNeutralizer = event.detail;
          setOutsideNeutralizerText('');
          setexpandedPanelOverheadWater(true);
          break;
        case 'trampamine':
          newSimulationObj.overheadWater.trampAmineData.trampAmineppm = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'steamamine':
          newSimulationObj.overheadWater.steamAmineData.steamAmineppm = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'trampamine2':
          newSimulationObj.overheadWater.trampAmineData.trampAmineppm2 = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'steamamine2':
          newSimulationObj.overheadWater.steamAmineData.steamAmineppm2 = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'sulfide':
          newSimulationObj.overheadWater.sulfide = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'combinedRefluxFlow':
          newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxFlow = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'combinedRefluxTemperature':
          newSimulationObj.overheadWater.combinedRefluxData.combinedRefluxTemp = event.detail;
          setexpandedPanelOverheadWater(true);
          break;
        case 'totalExternalWashRate':
          newSimulationObj.overheadConditions.totalExternalWashRate = event.detail;
          setexpandedPanelOverhead(true);
          break;
        case 'chargeGravity':
          newSimulationObj.desalter.chargeGravity = event.detail;
          setexpandedDesalter(true);
          break;
      }
      setSimulationData(newSimulationObj);
      if (!searchParams?.get('id')) {
        localStorage.setItem('manualInput', JSON.stringify(newSimulationObj));
      }
    }
    //manual entry create case
    else {
      let min = event.target.children[0].innerText;
      let max = event.target.children[1].innerText;
      setManualInput(event.target.id, event.detail, min, max);
    }
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', marginLeft: '30px', marginRight: '30px', flexDirection: 'column' }}>
        <div style={{ height: '50px' }}>
          <BhTitleWrapper data-testid="new-sim-data-title" type="subtitle" header="Simulation data" subtext={subText}></BhTitleWrapper>
        </div>
      </div>
      <div style={{ display: 'flex', marginLeft: '30px', marginRight: '30px', flexDirection: 'column' }}>
        <form data-testid="form-submit" onSubmit={handleSubmit}>
          <BhCard
            data-testid="bh-card-test-id"
            heading={formattedDate!}
            headerActions={[{ buttonType: 'secondary', buttonLabel: 'Undo changes', buttonIcon: 'undo_18px' }]}
            footerActions={
              !simulationId
                ? [
                    {
                      type: 'secondary',
                      label: 'Cancel',
                      key: 'btnCancel',
                    },
                    {
                      type: 'primary',
                      label: 'Save changes',
                      key: 'btnSaveChanges',
                    },
                  ]
                : [
                    {
                      type: 'secondary',
                      label: 'Cancel',
                      key: 'btnCancel',
                    },
                    {
                      type: 'critical',
                      label: 'Delete input',
                      key: 'btnDeleteInput',
                    },
                    {
                      type: 'primary',
                      label: 'Save changes',
                      key: 'btnSaveChanges',
                    },
                  ]
            }
            onBhEventCtaClick={(event: any) => {
              handleCtaClick(event);
            }}
            class="sim-data-card"
            border
          >
            <BhAccordion
              data-testid="handleDesalterPanelChange-test"
              header="Desalter"
              isOpen={expandedPanelDesalter}
              iconOrientation="right"
              size="medium"
              onBhEventOpen={(event: any) => {
                handleDesalterPanelChange(event, expandedPanelDesalter);
              }}
              onBhEventClose={(event: any) => {
                handleDesalterPanelChange(event, expandedPanelDesalter);
              }}
              className={
                (unitType === 'coker' || unitType === 'fcc') && !isWithDesalter ? 'accordion-hide' : 'input-accordion-title accordion-show'
              }
            >
              <div className="input-wrapper">
                <div className="input-frame order-0">
                  <div className="date-div">
                    <div>
                      <label className="bh-text-input__label typography--label-small bh-text-input__required">Date</label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={simulationData && simulationData.inputDate ? dayjs(new Date(simulationData.inputDate)) : dayjs(today)}
                        onChange={(event: any) => handleDateChange(event)}
                        className="date-pick"
                      />
                    </LocalizationProvider>
                    <BhFormMessage
                      data-testid="bh-error"
                      isError={errors && errors.date ? true : false}
                      message={values.date ? '' : errors.date}
                      id="date-input-error"
                    ></BhFormMessage>
                  </div>
                  <div>
                    <BhTextInput
                      data-testid="CrudeChargeRate"
                      type="text"
                      class="text-unit"
                      label={(unitType === 'coker' || unitType === 'fcc') && isWithDesalter ? 'Unit Charge Rate' : 'Crude Charge Rate'}
                      id="crudecharge"
                      value={simulationData && simulationData.desalter && simulationData.desalter.crudeCharge!}
                      isRequired
                      isError={errors && errors.crudecharge ? true : false}
                      onBhEventChange={(event: any) => handleInputChange(event)}
                      onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                      onBhEventBlur={(event: any) => handleInputChange(event)}
                      message={
                        simulationData && Object.keys(simulationData).length > 0
                          ? simulationData.desalter
                            ? simulationData.desalter.crudeChargeMin + ' to ' + simulationData.desalter.crudeChargeMax
                            : ''
                          : crudeChargeUnits && crudeChargeUnits.length && crudeChargeUnits[0].min! + ' to ' + crudeChargeUnits[0].max!
                      }
                    >
                      <label id="crude-charge-min" hidden>
                        {simulationData && Object.keys(simulationData).length > 0
                          ? simulationData.desalter && simulationData.desalter.crudeChargeMin!
                          : crudeChargeUnits && crudeChargeUnits.length && crudeChargeUnits[0].min!}
                      </label>
                      <label id="crude-charge-max" hidden>
                        {simulationData && Object.keys(simulationData).length > 0
                          ? simulationData.desalter && simulationData.desalter.crudeChargeMax!
                          : crudeChargeUnits && crudeChargeUnits.length && crudeChargeUnits[0].max!}
                      </label>
                    </BhTextInput>
                    <BhFormMessage isError message={errors.crudecharge} id="crude-charge-input-error"></BhFormMessage>
                  </div>
                  <div>
                    <BhDropdown
                      data-testid="dropdown-unit-test"
                      class="dropdown-unit"
                      label="Unit"
                      menuItems={JSON.stringify(crudeChargeUnits)}
                      value={simulationData && simulationData.desalter && simulationData.desalter.crudeCharge_UOM!}
                      isRequired
                      id="crude-charge-unit"
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                    ></BhDropdown>
                    <span className="typography--body-small info-message" hidden={!isDefaultCrudeChargeUnitChanged}>
                      Default unit is{' '}
                      {subcategoryInputMapping &&
                        Object.keys(subcategoryInputMapping).length &&
                        subcategoryInputMapping.Crude_Charge_Rate &&
                        Object.keys(subcategoryInputMapping.Crude_Charge_Rate).length &&
                        Object.keys(subcategoryInputMapping.Crude_Charge_Rate[0]).length &&
                        subcategoryInputMapping.Crude_Charge_Rate[0].unitSymbol}
                    </span>
                  </div>
                  {(unitType === 'coker' || unitType === 'fcc') && isWithDesalter && (
                    <div>
                      <BhTextInput
                        type="text"
                        data-testid="chargeGravity-test"
                        label="Charge Gravity"
                        value={simulationData && simulationData.desalter && simulationData.desalter.chargeGravity!}
                        id="chargeGravity"
                        isError={errors && errors.chargeGravity ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.desalter
                            ? simulationData.desalter.chargeGravityMin + ' to ' + simulationData.desalter.chargeGravityMax
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.desalter && simulationData.desalter.chargeGravityMin!}</label>
                        <label hidden>{simulationData && simulationData.desalter && simulationData.desalter.chargeGravityMax!}</label>
                      </BhTextInput>
                      <BhFormMessage isError message={errors.chargeGravity}></BhFormMessage>
                    </div>
                  )}
                  {((unitType !== 'coker' && unitType !== 'fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) && (
                    <div>
                      <BhTooltip title="Crude gravity" message="Specific gravity or °API acceptable">
                        <BhTextInput
                          type="text"
                          label="Crude Gravity"
                          isRequired
                          data-testid="crudegravity-test"
                          id="crudegravity"
                          value={simulationData && simulationData.desalter && simulationData.desalter.crudeGravity!}
                          isError={errors && errors.crudegravity ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.desalter
                              ? simulationData.desalter.crudeGravityMin + ' to ' + simulationData.desalter.crudeGravityMax
                              : ''
                          }
                          //tooltip='Crude gravity: Specific gravity or °API acceptable'
                        >
                          <label id="crude-gravity-min" hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.crudeGravityMin!}
                          </label>
                          <label id="crude-gravity-max" hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.crudeGravityMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.crudegravity}></BhFormMessage>
                    </div>
                  )}
                </div>
                {(unitType === 'coker' || unitType === 'fcc') && isWithDesalter && (
                  <div>
                    <BhTooltip title="Desalted Charge BS&W" message="BS&W of desalted charge in volume % of desalted charge.">
                      <BhTextInput
                        type="text"
                        data-testid="descrude-test"
                        label="Desalted Charge BS&W"
                        value={
                          simulationData &&
                          simulationData.desalter &&
                          simulationData.desalter.desCrudeBSWData &&
                          simulationData.desalter.desCrudeBSWData.desCrudeBSW!
                        }
                        id="descrude"
                        isError={errors && errors.descrude ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.desalter && simulationData.desalter.desCrudeBSWData
                            ? simulationData.desalter.desCrudeBSWData.desCrudeBSWMin +
                              ' to ' +
                              simulationData.desalter.desCrudeBSWData.desCrudeBSWMax
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData &&
                            simulationData.desalter &&
                            simulationData.desalter.desCrudeBSWData &&
                            simulationData.desalter.desCrudeBSWData.desCrudeBSWMin!}
                        </label>
                        <label hidden>
                          {simulationData &&
                            simulationData.desalter &&
                            simulationData.desalter.desCrudeBSWData &&
                            simulationData.desalter.desCrudeBSWData.desCrudeBSWMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.descrude}></BhFormMessage>
                  </div>
                )}
                {((unitType !== 'coker' && unitType !== 'fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) && (
                  <div className="input-frame order-1">
                    <div>
                      <BhTooltip
                        title={
                          configType === '1A' || configType === '2A'
                            ? 'Desalter Wash Rate (% of Crude Charge)'
                            : '1st Stage Desalter Wash Rate (% of Crude)'
                        }
                        message={
                          configType === '1A' || configType === '2A'
                            ? 'Desalter Wash Rate as Volume% of Crude Charge'
                            : 'First Desalter Wash Rate as Volume% of Crude Charge'
                        }
                      >
                        <BhTextInput
                          data-testid="washpercentage-test"
                          type="text"
                          label={
                            configType === '1A' || configType === '2A'
                              ? 'Desalter Wash Rate (% of Crude Charge)'
                              : '1st Stage Desalter Wash Rate (% of Crude)'
                          }
                          isRequired
                          value={
                            simulationData &&
                            simulationData.desalter &&
                            simulationData.desalter.washPercentageData &&
                            simulationData.desalter.washPercentageData.washPercentage!
                          }
                          isError={errors && errors.washpercentage ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.desalter && Object.keys(simulationData.desalter).length
                              ? simulationData.desalter.washPercentageData.washPercentageMin +
                                ' to ' +
                                simulationData.desalter.washPercentageData.washPercentageMax +
                                ' or ' +
                                simulationData.desalter.washPercentageData.washPercentageMin1 +
                                ' to ' +
                                simulationData.desalter.washPercentageData.washPercentageMax1
                              : ''
                          }
                          id="washpercentage"
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.washPercentageData &&
                              simulationData.desalter.washPercentageData.washPercentageMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.washPercentageData &&
                              simulationData.desalter.washPercentageData.washPercentageMax!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.washPercentageData &&
                              simulationData.desalter.washPercentageData.washPercentageMin1!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.washPercentageData &&
                              simulationData.desalter.washPercentageData.washPercentageMax1!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.washpercentage}></BhFormMessage>
                    </div>
                    {(configType === '1B' || configType === '2B') && (
                      <div>
                        <BhTooltip
                          title="2nd Stage Desalter Wash Rate (% of Crude)"
                          message="Second Desalter Wash Rate as Volume% of Crude Charge"
                        >
                          <BhTextInput
                            data-testid="wash2percentage-test"
                            type="text"
                            label="2nd Stage Desalter Wash Rate (% of Crude)"
                            isRequired
                            value={
                              simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.washPercentageData &&
                              simulationData.desalter.washPercentageData.washPercentage2!
                            }
                            isError={errors && errors.wash2percentage ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.desalter
                                ? simulationData.desalter.washPercentageData.washPercentageMin +
                                  ' to ' +
                                  simulationData.desalter.washPercentageData.washPercentageMax +
                                  ' or ' +
                                  simulationData.desalter.washPercentageData.washPercentageMin1 +
                                  ' to ' +
                                  simulationData.desalter.washPercentageData.washPercentageMax1
                                : ''
                            }
                            id="wash2percentage"
                          >
                            <label hidden>
                              {simulationData &&
                                simulationData.desalter &&
                                simulationData.desalter.washPercentageData &&
                                simulationData.desalter.washPercentageData.washPercentageMin!}
                            </label>
                            <label hidden>
                              {simulationData &&
                                simulationData.desalter &&
                                simulationData.desalter.washPercentageData &&
                                simulationData.desalter.washPercentageData.washPercentageMax!}
                            </label>
                            <label hidden>
                              {simulationData &&
                                simulationData.desalter &&
                                simulationData.desalter.washPercentageData &&
                                simulationData.desalter.washPercentageData.washPercentageMin1!}
                            </label>
                            <label hidden>
                              {simulationData &&
                                simulationData.desalter &&
                                simulationData.desalter.washPercentageData &&
                                simulationData.desalter.washPercentageData.washPercentageMax1!}
                            </label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.wash2percentage}></BhFormMessage>
                      </div>
                    )}
                    <div>
                      <BhTooltip title="% of Brine Recycled to Desalter Wash Water" message="Percentage of Brine used as Desalter Wash">
                        <BhTextInput
                          data-testid="BrineRecycledtoDesalterWashWater"
                          type="text"
                          label="% of Brine Recycled to Desalter Wash Water"
                          value={simulationData && simulationData.desalter && simulationData.desalter.brineRecyclePercentage!}
                          id="brinerecycle"
                          isError={errors && errors.brinerecycle ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.desalter
                              ? simulationData.desalter.brineRecyclePercentageMin +
                                ' to ' +
                                simulationData.desalter.brineRecyclePercentageMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.brineRecyclePercentageMin!}
                          </label>
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.brineRecyclePercentageMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.brinerecycle}></BhFormMessage>
                    </div>
                    <div className={configType === '1A' || configType === '2A' ? 'div-show' : 'div-hide'}>
                      <BhTooltip
                        title="% of Overhead Water to Desalter Wash Water"
                        message={getToolTipForWaterToDesalter()}
                      >
                        <BhTextInput
                          data-testid="percentageAtmH2OtoDesalter1-test"
                          type="text"
                          label="% of Overhead Water to Desalter Wash Water"
                          value={simulationData && simulationData.desalter && simulationData.desalter.percentageAtmH2OtoDesalter!}
                          id="percentageAtmH2OtoDesalter"
                          isError={errors && errors.percentageAtmH2OtoDesalter ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.desalter
                              ? simulationData.desalter.percentageAtmH2OtoDesalterMin +
                                ' to ' +
                                simulationData.desalter.percentageAtmH2OtoDesalterMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.percentageAtmH2OtoDesalterMin!}
                          </label>
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.percentageAtmH2OtoDesalterMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.percentageAtmH2OtoDesalter}></BhFormMessage>
                    </div>
                  </div>
                )}
                {((unitType !== 'coker' && unitType !== 'fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) && (
                  <div className="input-frame order-2">
                    <div className={configType === '1B' || configType === '2B' ? 'div-show' : 'div-hide'}>
                      <BhTooltip
                        title="% of Overhead Water to Desalter Wash Water"
                        message={getToolTipForWaterToDesalter()}
                      >
                        <BhTextInput
                          data-testid="percentageAtmH2OtoDesalter-test"
                          type="text"
                          label="% of Overhead Water to Desalter Wash Water"
                          value={simulationData && simulationData.desalter && simulationData.desalter.percentageAtmH2OtoDesalter!}
                          id="percentageAtmH2OtoDesalter"
                          isError={errors && errors.percentageAtmH2OtoDesalter ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.desalter
                              ? simulationData.desalter.percentageAtmH2OtoDesalterMin +
                                ' to ' +
                                simulationData.desalter.percentageAtmH2OtoDesalterMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.percentageAtmH2OtoDesalterMin!}
                          </label>
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.percentageAtmH2OtoDesalterMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.percentageAtmH2OtoDesalter}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title="% of Overhead Water to SWS"
                        message={getToolTipForOverheadWaterToSWS()}
                      >
                        <BhTextInput
                          type="text"
                          data-testid="percentageAtmH2OtoSWS-test"
                          label="% of Overhead Water to SWS"
                          value={simulationData && simulationData.desalter && simulationData.desalter.percentageAtmH2OtoSWS!}
                          id="percentageAtmH2OtoSWS"
                          isError={errors && errors.percentageAtmH2OtoSWS ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.desalter
                              ? simulationData.desalter.percentageAtmH2OtoSWSMin + ' to ' + simulationData.desalter.percentageAtmH2OtoSWSMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.percentageAtmH2OtoSWSMin!}
                          </label>
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.percentageAtmH2OtoSWSMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.percentageAtmH2OtoSWS}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title="% of Total SSW to Desalter Wash Water"
                        message={getToolTipForTotalSSWToDesalterWashWater()}
                      >
                        <BhTextInput
                          type="text"
                          data-testid="percentageSSWtoDesalter-test"
                          label="% of Total SSW to Desalter Wash Water"
                          value={simulationData && simulationData.desalter && simulationData.desalter.percentageSSWtoDesalter!}
                          id="percentageSSWtoDesalter"
                          isError={errors && errors.percentageSSWtoDesalter ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.desalter
                              ? simulationData.desalter.percentageSSWtoDesalterMin +
                                ' to ' +
                                simulationData.desalter.percentageSSWtoDesalterMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.percentageSSWtoDesalterMin!}
                          </label>
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.percentageSSWtoDesalterMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.percentageSSWtoDesalter}></BhFormMessage>
                    </div>
                    <div className={configType === '1A' || configType === '2A' ? 'div-show-deslater' : 'div-hide'}>
                      <BhTextInput
                        type="text"
                        data-testid="desaltertemp-test"
                        class="text-unit"
                        label="Desalter Temperature"
                        isRequired
                        value={simulationData && simulationData.desalter && simulationData.desalter.desalterTemperature!}
                        id="desaltertemp"
                        isError={errors && errors.desaltertemp ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.desalter
                            ? simulationData.desalter.desalterTemperatureMin + ' to ' + simulationData.desalter.desalterTemperatureMax
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.desalter && simulationData.desalter.desalterTemperatureMin!}</label>
                        <label hidden>{simulationData && simulationData.desalter && simulationData.desalter.desalterTemperatureMax!}</label>
                      </BhTextInput>
                      <BhFormMessage isError message={errors.desaltertemp}></BhFormMessage>
                    </div>
                    <div className={configType === '1A' || configType === '2A' ? 'div-show-deslater' : 'div-hide'}>
                      <BhDropdown
                        data-testid="desalter-temp-unit-test"
                        class="dropdown-unit"
                        label="Unit"
                        //selectedValue={true}
                        menuItems={JSON.stringify(deslaterTempUnits)}
                        value={deslaterTempUOM}
                        isRequired
                        id="desalter-temp-unit"
                        onBhEventChange={(event: any) => handleDropDownChange(event)}
                      ></BhDropdown>
                      <span className="typography--body-small info-message" hidden={!isDefaultDesalterTempUnitChanged}>
                        Default unit is{' '}
                        {subcategoryInputMapping &&
                          Object.keys(subcategoryInputMapping).length &&
                          Object.keys(subcategoryInputMapping.Temperature).length &&
                          Object.keys(subcategoryInputMapping.Temperature[0]).length &&
                          '°' + subcategoryInputMapping.Temperature[0].unitSymbol}
                      </span>
                    </div>
                  </div>
                )}
                {((unitType !== 'coker' && unitType !== 'fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) && (
                  <div className="input-frame order-3">
                    <div className={configType === '1B' || configType === '2B' ? 'div-show-deslater' : 'div-hide'}>
                      <BhTextInput
                        type="text"
                        class="text-unit"
                        label="Desalter Temperature"
                        isRequired
                        value={simulationData && simulationData.desalter && simulationData.desalter.desalterTemperature!}
                        id="desaltertemp"
                        isError={errors && errors.desaltertemp ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.desalter
                            ? simulationData.desalter.desalterTemperatureMin + ' to ' + simulationData.desalter.desalterTemperatureMax
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.desalter && simulationData.desalter.desalterTemperatureMin!}</label>
                        <label hidden>{simulationData && simulationData.desalter && simulationData.desalter.desalterTemperatureMax!}</label>
                      </BhTextInput>
                      <BhFormMessage isError message={errors.desaltertemp}></BhFormMessage>
                    </div>
                    <div className={configType === '1B' || configType === '2B' ? 'div-show-deslater' : 'div-hide'}>
                      <BhDropdown
                        class="dropdown-unit"
                        label="Unit"
                        //selectedValue={true}
                        menuItems={JSON.stringify(deslaterTempUnits)}
                        value={deslaterTempUOM}
                        isRequired
                        id="desalter-temp-unit"
                        onBhEventChange={(event: any) => handleDropDownChange(event)}
                      ></BhDropdown>
                      <span className="typography--body-small info-message" hidden={!isDefaultDesalterTempUnitChanged}>
                        Default unit is{' '}
                        {subcategoryInputMapping &&
                          Object.keys(subcategoryInputMapping).length &&
                          Object.keys(subcategoryInputMapping.Temperature).length &&
                          Object.keys(subcategoryInputMapping.Temperature[0]).length &&
                          '°' + subcategoryInputMapping.Temperature[0].unitSymbol}
                      </span>
                    </div>
                    <div>
                      <BhTooltip
                        title={configType === '1A' || configType === '2A' ? 'Desalter Brine pH' : '1st Stage Desalter Brine pH'}
                        message={configType === '1A' || configType === '2A' ? 'Desalter Brine pH' : 'First Stage Desalter brine water pH'}
                      >
                        <BhTextInput
                          type="text"
                          data-testid="brineph-test"
                          label={configType === '1A' || configType === '2A' ? 'Desalter Brine pH' : '1st Stage Desalter Brine pH'}
                          isRequired
                          value={
                            simulationData &&
                            simulationData.desalter &&
                            simulationData.desalter.brinepHData &&
                            simulationData.desalter.brinepHData.brinepH!
                          }
                          id="brineph"
                          isError={errors && errors.brineph ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.desalter && simulationData.desalter.brinepHData
                              ? simulationData.desalter.brinepHData.brinepHMin + ' to ' + simulationData.desalter.brinepHData.brinepHMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.brinepHData &&
                              simulationData.desalter.brinepHData.brinepHMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.brinepHData &&
                              simulationData.desalter.brinepHData.brinepHMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.brineph}></BhFormMessage>
                    </div>
                    {(configType === '1B' || configType === '2B') && (
                      <div>
                        <BhTooltip title="2nd Stage Desalter Brine pH" message="Second Stage Desalter brine water pH">
                          <BhTextInput
                            data-testid="brineph2-test"
                            type="text"
                            label="2nd Stage Desalter Brine pH"
                            isRequired
                            value={
                              simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.brinepHData &&
                              simulationData.desalter.brinepHData.brinepH2!
                            }
                            id="brineph2"
                            isError={errors && errors.brineph2 ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.desalter && simulationData.desalter.brinepHData
                                ? simulationData.desalter.brinepHData.brinepHMin + ' to ' + simulationData.desalter.brinepHData.brinepHMax
                                : ''
                            }
                          >
                            <label hidden>
                              {simulationData &&
                                simulationData.desalter &&
                                simulationData.desalter.brinepHData &&
                                simulationData.desalter.brinepHData.brinepHMin!}
                            </label>
                            <label hidden>
                              {simulationData &&
                                simulationData.desalter &&
                                simulationData.desalter.brinepHData &&
                                simulationData.desalter.brinepHData.brinepHMax!}
                            </label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.brineph2}></BhFormMessage>
                      </div>
                    )}
                    {(configType === '1A' || configType === '2A') && (
                      <div>
                        <BhTooltip
                          title="Brine Sample Temperature"
                          message="Temperature of brine sample when pH is measured. Field Monitor accounts for temperature effects on pH."
                        >
                          <BhTextInput
                            type="text"
                            data-testid="brinesampletemp-test"
                            label="Brine Sample Temperature"
                            isRequired
                            unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                            value={simulationData && simulationData.desalter && simulationData.desalter.brineSampleTemperature!}
                            id="brinesampletemp"
                            isError={(errors && errors.brinesampletemp) || brineSampleTempErrorText ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.desalter
                                ? simulationData.desalter.brineSampleTemperatureMin +
                                  ' to ' +
                                  simulationData.desalter.brineSampleTemperatureMax
                                : ''
                            }
                          >
                            <label hidden>
                              {simulationData && simulationData.desalter && simulationData.desalter.brineSampleTemperatureMin!}
                            </label>
                            <label hidden>
                              {simulationData && simulationData.desalter && simulationData.desalter.brineSampleTemperatureMax!}
                            </label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.brinesampletemp || brineSampleTempErrorText}></BhFormMessage>
                      </div>
                    )}
                    {(configType === '1A' || configType === '2A') && (
                      <div>
                        <BhTooltip title="Desalted Crude BS&W" message="BS&W of desalted crude in volume % of desalted crude.">
                          <BhTextInput
                            type="text"
                            data-testid="descrude-test"
                            label="Desalted Crude BS&W"
                            value={
                              simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.desCrudeBSWData &&
                              simulationData.desalter.desCrudeBSWData.desCrudeBSW!
                            }
                            id="descrude"
                            isError={errors && errors.descrude ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.desalter && simulationData.desalter.desCrudeBSWData
                                ? simulationData.desalter.desCrudeBSWData.desCrudeBSWMin +
                                  ' to ' +
                                  simulationData.desalter.desCrudeBSWData.desCrudeBSWMax
                                : ''
                            }
                          >
                            <label hidden>
                              {simulationData &&
                                simulationData.desalter &&
                                simulationData.desalter.desCrudeBSWData &&
                                simulationData.desalter.desCrudeBSWData.desCrudeBSWMin!}
                            </label>
                            <label hidden>
                              {simulationData &&
                                simulationData.desalter &&
                                simulationData.desalter.desCrudeBSWData &&
                                simulationData.desalter.desCrudeBSWData.desCrudeBSWMax!}
                            </label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.descrude}></BhFormMessage>
                      </div>
                    )}
                  </div>
                )}
                {(configType === '1B' || configType === '2B') && (
                  <div className="input-frame order-4">
                    <div>
                      <BhTooltip
                        title="Brine Sample Temperature"
                        message="Temperature of brine sample when pH is measured. Field Monitor accounts for temperature effects on pH."
                      >
                        <BhTextInput
                          type="text"
                          label="Brine Sample Temperature"
                          isRequired
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          value={simulationData && simulationData.desalter && simulationData.desalter.brineSampleTemperature!}
                          id="brinesampletemp"
                          isError={(errors && errors.brinesampletemp) || brineSampleTempErrorText ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.desalter
                              ? simulationData.desalter.brineSampleTemperatureMin +
                                ' to ' +
                                simulationData.desalter.brineSampleTemperatureMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.brineSampleTemperatureMin!}
                          </label>
                          <label hidden>
                            {simulationData && simulationData.desalter && simulationData.desalter.brineSampleTemperatureMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.brinesampletemp || brineSampleTempErrorText}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title="1st Stage Desalted Crude BS&W"
                        message="BS&W of first stage desalted crude in volume % of desalted crude."
                      >
                        <BhTextInput
                          data-testid="descrude1-test"
                          label="1st Stage Desalted Crude BS&W"
                          value={
                            simulationData &&
                            simulationData.desalter &&
                            simulationData.desalter.desCrudeBSWData &&
                            simulationData.desalter.desCrudeBSWData.desCrudeBSW!
                          }
                          id="descrude1"
                          isError={errors && errors.descrude1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.desalter && simulationData.desalter.desCrudeBSWData
                              ? simulationData.desalter.desCrudeBSWData.desCrudeBSWMin +
                                ' to ' +
                                simulationData.desalter.desCrudeBSWData.desCrudeBSWMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.desCrudeBSWData &&
                              simulationData.desalter.desCrudeBSWData.desCrudeBSWMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.desCrudeBSWData &&
                              simulationData.desalter.desCrudeBSWData.desCrudeBSWMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.descrude1}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title="2nd Stage Desalted Crude BS&W"
                        message="BS&W of second stage desalted crude in volume % of desalted crude."
                      >
                        <BhTextInput
                          data-testid="descrude2-test"
                          label="2nd Stage Desalted Crude BS&W"
                          value={
                            simulationData &&
                            simulationData.desalter &&
                            simulationData.desalter.desCrudeBSWData &&
                            simulationData.desalter.desCrudeBSWData.desCrudeBSW2!
                          }
                          id="descrude2"
                          isError={errors && errors.descrude2 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.desalter && simulationData.desalter.desCrudeBSWData
                              ? simulationData.desalter.desCrudeBSWData.desCrudeBSWMin +
                                ' to ' +
                                simulationData.desalter.desCrudeBSWData.desCrudeBSWMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.desCrudeBSWData &&
                              simulationData.desalter.desCrudeBSWData.desCrudeBSWMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.desalter &&
                              simulationData.desalter.desCrudeBSWData &&
                              simulationData.desalter.desCrudeBSWData.desCrudeBSWMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.descrude2}></BhFormMessage>
                    </div>
                  </div>
                )}
              </div>
            </BhAccordion>
            <BhAccordion
              data-testid="handleTowerPanelChange-test"
              header="Tower"
              isOpen={expandedPanelTower}
              iconOrientation="right"
              size="medium"
              onBhEventOpen={(event: any) => {
                handleTowerPanelChange(event, expandedPanelTower);
              }}
              onBhEventClose={(event: any) => {
                handleTowerPanelChange(event, expandedPanelTower);
              }}
              className="input-accordion-title"
            >
              <div className="input-wrapper">
                <div className="input-frame order-0">
                  {(unitType === 'coker' || unitType === 'fcc') && !isWithDesalter && (
                    <div className="date-div-coker">
                      <div>
                        <label className="bh-text-input__label typography--label-small bh-text-input__required">Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={simulationData && simulationData.inputDate ? dayjs(new Date(simulationData.inputDate)) : dayjs(today)}
                          onChange={(event: any) => handleDateChange(event)}
                          className="date-pick"
                        />
                      </LocalizationProvider>
                      <BhFormMessage
                        data-testid="bh-error"
                        isError={errors && errors.date ? true : false}
                        message={values.date ? '' : errors.date}
                        id="date-input-error"
                      ></BhFormMessage>
                    </div>
                  )}
                  <div>
                    <BhTooltip
                      title={
                        (unitType === 'coker' || unitType === 'fcc') && isWithDesalter ? 'Total Tower Steam' : 'Stripping Steam to Tower'
                      }
                      message="Stripping steam added to the atmospheric distillation tower."
                    >
                      <BhTextInput
                        data-testid="steamtower-test"
                        type="text"
                        label={
                          (unitType === 'coker' || unitType === 'fcc') && isWithDesalter ? 'Total Tower Steam' : 'Stripping Steam to Tower'
                        }
                        class="text-unit"
                        isRequired
                        value={simulationData && simulationData.towerData && simulationData.towerData.steamTower!}
                        id="steamtower"
                        isError={errors && errors.steamtower ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.towerData
                            ? simulationData.towerData.steamTowerMin + ' to ' + simulationData.towerData.steamTowerMax
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steamTowerMin!}</label>
                        <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steamTowerMax!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.steamtower}></BhFormMessage>
                  </div>
                  <div>
                    <BhDropdown
                      data-testid="steam-tower-unit-test"
                      label="Unit"
                      class="dropdown-unit"
                      //selectedValue={true}
                      menuItems={JSON.stringify(steamTowerUnits)}
                      value={simulationData && simulationData.towerData && simulationData.towerData.steamTower_UOM!}
                      isRequired
                      id="steam-tower-unit"
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                    ></BhDropdown>
                    <span className="typography--body-small info-message" hidden={!isDefaultStrippingSteamUnitChanged}>
                      Default unit is{' '}
                      {subcategoryInputMapping &&
                        Object.keys(subcategoryInputMapping).length &&
                        subcategoryInputMapping.Stripping_Steam_to_Tower &&
                        Object.keys(subcategoryInputMapping.Stripping_Steam_to_Tower).length &&
                        Object.keys(subcategoryInputMapping.Stripping_Steam_to_Tower[0]).length &&
                        subcategoryInputMapping.Stripping_Steam_to_Tower[0].unitSymbol}
                    </span>
                  </div>
                  <div>
                    <BhTooltip
                      title={(unitType === 'coker' || unitType === 'fcc') && isWithDesalter ? 'Total SS Steam' : '1st Side Stripper Steam'}
                      message="Additional source of steam to the atmospheric tower (side stripper).These are optional as needed."
                    >
                      <BhTextInput
                        data-testid="steam1ss-test"
                        class="text-unit"
                        type="text"
                        label={
                          (unitType === 'coker' || unitType === 'fcc') && isWithDesalter ? 'Total SS Steam' : '1st Side Stripper Steam'
                        }
                        value={simulationData && simulationData.towerData && simulationData.towerData.steam1SS!}
                        id="steam1ss"
                        isError={errors && errors.steam1ss ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.towerData
                            ? simulationData.towerData.steam1SSMin + ' to ' + simulationData.towerData.steam1SSMax
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam1SSMin!}</label>
                        <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam1SSMax!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.steam1ss}></BhFormMessage>
                  </div>
                  <div>
                    <BhDropdown
                      label="Unit"
                      data-testid="steam1ss-unit-test"
                      class="dropdown-unit"
                      menuItems={JSON.stringify(steamUnits)}
                      value={simulationData && simulationData.towerData && simulationData.towerData.steam1SS_UOM!}
                      isRequired
                      id="steam1ss-unit"
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                    ></BhDropdown>
                    <span className="typography--body-small info-message" hidden={!isDefaultSteam1UnitChanged}>
                      Default unit is{' '}
                      {subcategoryInputMapping &&
                        Object.keys(subcategoryInputMapping).length &&
                        subcategoryInputMapping.First_Side_Stripper_Steam &&
                        Object.keys(subcategoryInputMapping.First_Side_Stripper_Steam).length &&
                        Object.keys(subcategoryInputMapping.First_Side_Stripper_Steam[0]).length &&
                        subcategoryInputMapping.First_Side_Stripper_Steam[0].unitSymbol}
                    </span>
                  </div>
                  {((unitType !== 'coker' && unitType !== 'fcc') || ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter)) && (
                    <>
                      <div>
                        <BhTooltip
                          title={
                            (unitType === 'coker' || unitType === 'fcc') && isWithDesalter
                              ? 'Total Furnace Steam'
                              : '2nd Side Stripper Steam'
                          }
                          message="Additional source of steam to the atmospheric tower (side stripper). These are optional as needed."
                        >
                          <BhTextInput
                            data-testid="steam2ss-test"
                            class="text-unit"
                            type="text"
                            label={
                              (unitType === 'coker' || unitType === 'fcc') && isWithDesalter
                                ? 'Total Furnace Steam'
                                : '2nd Side Stripper Steam'
                            }
                            value={simulationData && simulationData.towerData && simulationData.towerData.steam2SS!}
                            id="steam2ss"
                            isError={errors && errors.steam2ss ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.towerData
                                ? simulationData.towerData.steam2SSMin + ' to ' + simulationData.towerData.steam2SSMax
                                : ''
                            }
                          >
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam2SSMin!}</label>
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam2SSMax!}</label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.steam2ss}></BhFormMessage>
                      </div>
                      <div>
                        <BhDropdown
                          label="Unit"
                          data-testid="steam2ss-unit-test"
                          class="dropdown-unit"
                          menuItems={JSON.stringify(steamUnits)}
                          value={simulationData && simulationData.towerData && simulationData.towerData.steam2SS_UOM!}
                          isRequired
                          id="steam2ss-unit"
                          onBhEventChange={(event: any) => handleDropDownChange(event)}
                        ></BhDropdown>
                        <span className="typography--body-small info-message" hidden={!isDefaultSteam2UnitChanged}>
                          Default unit is{' '}
                          {subcategoryInputMapping &&
                            Object.keys(subcategoryInputMapping).length &&
                            subcategoryInputMapping.Second_Side_Stripper_Steam &&
                            Object.keys(subcategoryInputMapping.Second_Side_Stripper_Steam).length &&
                            Object.keys(subcategoryInputMapping.Second_Side_Stripper_Steam[0]).length &&
                            subcategoryInputMapping.Second_Side_Stripper_Steam[0].unitSymbol}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="input-frame order-1">
                  {(unitType === 'coker' || unitType === 'fcc') && !isWithDesalter && (
                    <>
                      <div>
                        <BhTooltip
                          title="2nd Side Stripper Steam"
                          message="Additional source of steam to the atmospheric tower (side stripper). These are optional as needed."
                        >
                          <BhTextInput
                            data-testid="steam2ss-test"
                            class="text-unit"
                            type="text"
                            label="2nd Side Stripper Steam"
                            value={simulationData && simulationData.towerData && simulationData.towerData.steam2SS!}
                            id="steam2ss"
                            isError={errors && errors.steam2ss ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.towerData
                                ? simulationData.towerData.steam2SSMin + ' to ' + simulationData.towerData.steam2SSMax
                                : ''
                            }
                          >
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam2SSMin!}</label>
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam2SSMax!}</label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.steam2ss}></BhFormMessage>
                      </div>
                      <div>
                        <BhDropdown
                          label="Unit"
                          data-testid="steam2ss-unit-test"
                          class="dropdown-unit"
                          menuItems={JSON.stringify(steamUnits)}
                          value={simulationData && simulationData.towerData && simulationData.towerData.steam2SS_UOM!}
                          isRequired
                          id="steam2ss-unit"
                          onBhEventChange={(event: any) => handleDropDownChange(event)}
                        ></BhDropdown>
                        <span className="typography--body-small info-message" hidden={!isDefaultSteam2UnitChanged}>
                          Default unit is{' '}
                          {subcategoryInputMapping &&
                            Object.keys(subcategoryInputMapping).length &&
                            subcategoryInputMapping.Second_Side_Stripper_Steam &&
                            Object.keys(subcategoryInputMapping.Second_Side_Stripper_Steam).length &&
                            Object.keys(subcategoryInputMapping.Second_Side_Stripper_Steam[0]).length &&
                            subcategoryInputMapping.Second_Side_Stripper_Steam[0].unitSymbol}
                        </span>
                      </div>
                    </>
                  )}
                  <div>
                    <BhTooltip
                      title={
                        (unitType === 'coker' || unitType === 'fcc') && isWithDesalter ? 'Total Other Steam' : '3rd Side Stripper Steam'
                      }
                      message="Additional source of steam to the atmospheric tower (side stripper). These are optional as needed."
                    >
                      <BhTextInput
                        data-testid="steam3ss-test"
                        class="text-unit"
                        type="text"
                        label={
                          (unitType === 'coker' || unitType === 'fcc') && isWithDesalter ? 'Total Other Steam' : '3rd Side Stripper Steam'
                        }
                        value={simulationData && simulationData.towerData && simulationData.towerData.steam3SS!}
                        id="steam3ss"
                        isError={errors && errors.steam3ss ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.towerData
                            ? simulationData.towerData.steam3SSMin + ' to ' + simulationData.towerData.steam3SSMax
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam3SSMin!}</label>
                        <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam3SSMax!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.steam3ss}></BhFormMessage>
                  </div>
                  <div>
                    <BhDropdown
                      data-testid="steam3ss-unit-test"
                      label="Unit"
                      class="dropdown-unit"
                      menuItems={JSON.stringify(steamUnits)}
                      value={simulationData && simulationData.towerData && simulationData.towerData.steam3SS_UOM!}
                      isRequired
                      id="steam3ss-unit"
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                    ></BhDropdown>
                    <span className="typography--body-small info-message" hidden={!isDefaultSteam3UnitChanged}>
                      Default unit is{' '}
                      {subcategoryInputMapping &&
                        Object.keys(subcategoryInputMapping).length &&
                        subcategoryInputMapping.Third_Side_Stripper_Steam &&
                        Object.keys(subcategoryInputMapping.Third_Side_Stripper_Steam).length &&
                        Object.keys(subcategoryInputMapping.Third_Side_Stripper_Steam[0]).length &&
                        subcategoryInputMapping.Third_Side_Stripper_Steam[0].unitSymbol}
                    </span>
                  </div>
                  {((unitType !== 'coker' && unitType !== 'fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) && (
                    <>
                      <div>
                        <BhTooltip
                          title="4th Side Stripper Steam"
                          message="Additional source of steam to the atmospheric tower (side stripper). These are optional as needed."
                        >
                          <BhTextInput
                            class="text-unit"
                            type="text"
                            data-testid="steam4ss-test"
                            label="4th Side Stripper Steam"
                            value={simulationData && simulationData.towerData && simulationData.towerData.steam4SS!}
                            id="steam4ss"
                            isError={errors && errors.steam4ss ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.towerData
                                ? simulationData.towerData.steam4SSMin + ' to ' + simulationData.towerData.steam4SSMax
                                : ''
                            }
                          >
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam4SSMin!}</label>
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam4SSMax!}</label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.steam4ss}></BhFormMessage>
                      </div>
                      <div>
                        <BhDropdown
                          data-testid="steam4ss-unit-test"
                          label="Unit"
                          class="dropdown-unit"
                          menuItems={JSON.stringify(steamUnits)}
                          value={simulationData && simulationData.towerData && simulationData.towerData.steam4SS_UOM!}
                          isRequired
                          id="steam4ss-unit"
                          onBhEventChange={(event: any) => handleDropDownChange(event)}
                        ></BhDropdown>
                        <span className="typography--body-small info-message" hidden={!isDefaultSteam4UnitChanged}>
                          Default unit is{' '}
                          {subcategoryInputMapping &&
                            Object.keys(subcategoryInputMapping).length &&
                            subcategoryInputMapping.Fourth_Side_Stripper_Steam &&
                            Object.keys(subcategoryInputMapping.Fourth_Side_Stripper_Steam).length &&
                            Object.keys(subcategoryInputMapping.Fourth_Side_Stripper_Steam[0]).length &&
                            subcategoryInputMapping.Fourth_Side_Stripper_Steam[0].unitSymbol}
                        </span>
                      </div>
                    </>
                  )}
                  {unitType !== 'coker' && unitType !== 'fcc' && (
                    <>
                      <div>
                        <BhTooltip
                          title="5th Side Stripper Steam"
                          message="Additional source of steam to the atmospheric tower (side stripper). These are optional as needed."
                        >
                          <BhTextInput
                            class="text-unit"
                            type="text"
                            data-testid="steam5ss-test"
                            label="5th Side Stripper Steam"
                            value={simulationData && simulationData.towerData && simulationData.towerData.steam5SS!}
                            id="steam5ss"
                            isError={errors && errors.steam5ss ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.towerData
                                ? simulationData.towerData.steam5SSMin + ' to ' + simulationData.towerData.steam5SSMax
                                : ''
                            }
                            //ref={steam5SSref}
                          >
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam5SSMin!}</label>
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam5SSMax!}</label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.steam5ss}></BhFormMessage>
                      </div>
                      <div>
                        <BhDropdown
                          label="Unit"
                          data-testid="steam5ss-unit-test"
                          class="dropdown-unit"
                          menuItems={JSON.stringify(steamUnits)}
                          value={simulationData && simulationData.towerData && simulationData.towerData.steam5SS_UOM!}
                          isRequired
                          id="steam5ss-unit"
                          onBhEventChange={(event: any) => handleDropDownChange(event)}
                        ></BhDropdown>
                        <span className="typography--body-small info-message" hidden={!isDefaultSteam5UnitChanged}>
                          Default unit is{' '}
                          {subcategoryInputMapping &&
                            Object.keys(subcategoryInputMapping).length &&
                            subcategoryInputMapping.Fifth_Side_Stripper_Steam &&
                            Object.keys(subcategoryInputMapping.Fifth_Side_Stripper_Steam).length &&
                            Object.keys(subcategoryInputMapping.Fifth_Side_Stripper_Steam[0]).length &&
                            subcategoryInputMapping.Fifth_Side_Stripper_Steam[0].unitSymbol}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="input-frame order-2">
                  {(unitType === 'coker' || unitType === 'fcc') && !isWithDesalter && (
                    <>
                      <div>
                        <BhTooltip
                          title="5th Side Stripper Steam"
                          message="Additional source of steam to the atmospheric tower (side stripper). These are optional as needed."
                        >
                          <BhTextInput
                            class="text-unit"
                            type="text"
                            data-testid="steam5ss-test"
                            label="5th Side Stripper Steam"
                            value={simulationData && simulationData.towerData && simulationData.towerData.steam5SS!}
                            id="steam5ss"
                            isError={errors && errors.steam5ss ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.towerData
                                ? simulationData.towerData.steam5SSMin + ' to ' + simulationData.towerData.steam5SSMax
                                : ''
                            }
                            //ref={steam5SSref}
                          >
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam5SSMin!}</label>
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam5SSMax!}</label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.steam5ss}></BhFormMessage>
                      </div>
                      <div>
                        <BhDropdown
                          label="Unit"
                          data-testid="steam5ss-unit-test"
                          class="dropdown-unit"
                          menuItems={JSON.stringify(steamUnits)}
                          value={simulationData && simulationData.towerData && simulationData.towerData.steam5SS_UOM!}
                          isRequired
                          id="steam5ss-unit"
                          onBhEventChange={(event: any) => handleDropDownChange(event)}
                        ></BhDropdown>
                        <span className="typography--body-small info-message" hidden={!isDefaultSteam5UnitChanged}>
                          Default unit is{' '}
                          {subcategoryInputMapping &&
                            Object.keys(subcategoryInputMapping).length &&
                            subcategoryInputMapping.Fifth_Side_Stripper_Steam &&
                            Object.keys(subcategoryInputMapping.Fifth_Side_Stripper_Steam).length &&
                            Object.keys(subcategoryInputMapping.Fifth_Side_Stripper_Steam[0]).length &&
                            subcategoryInputMapping.Fifth_Side_Stripper_Steam[0].unitSymbol}
                        </span>
                      </div>
                    </>
                  )}
                  {((unitType !== 'coker' && unitType !== 'fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) && (
                    <>
                      <div>
                        <BhTooltip
                          title="6th Side Stripper Steam"
                          message="Additional source of steam to the atmospheric tower (side stripper). These are optional as needed."
                        >
                          <BhTextInput
                            data-testid="steam6ss-test"
                            class="text-unit"
                            type="text"
                            label="6th Side Stripper Steam"
                            value={simulationData && simulationData.towerData && simulationData.towerData.steam6SS!}
                            id="steam6ss"
                            isError={errors && errors.steam6ss ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.towerData
                                ? simulationData.towerData.steam6SSMin + ' to ' + simulationData.towerData.steam6SSMax
                                : ''
                            }
                          >
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam6SSMin!}</label>
                            <label hidden>{simulationData && simulationData.towerData && simulationData.towerData.steam6SSMax!}</label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.steam6ss}></BhFormMessage>
                      </div>
                      <div>
                        <BhDropdown
                          label="Unit"
                          data-testid="steam6ss-unit-test"
                          class="dropdown-unit"
                          menuItems={JSON.stringify(steamUnits)}
                          value={simulationData && simulationData.towerData && simulationData.towerData.steam6SS_UOM!}
                          isRequired
                          id="steam6ss-unit"
                          onBhEventChange={(event: any) => handleDropDownChange(event)}
                        ></BhDropdown>
                        <span className="typography--body-small info-message" hidden={!isDefaultSteam6UnitChanged}>
                          Default unit is{' '}
                          {subcategoryInputMapping &&
                            Object.keys(subcategoryInputMapping).length &&
                            Object.keys(subcategoryInputMapping.Sixth_Side_Stripper_Steam).length &&
                            Object.keys(subcategoryInputMapping.Sixth_Side_Stripper_Steam[0]).length &&
                            subcategoryInputMapping.Sixth_Side_Stripper_Steam[0].unitSymbol}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </BhAccordion>
            <BhAccordion
              data-testid="handleOverheadPanelChange-test"
              header="Overhead Conditions"
              isOpen={expandedPanelOverhead}
              iconOrientation="right"
              size="medium"
              onBhEventOpen={(event: any) => {
                handleOverheadPanelChange(event, expandedPanelOverhead);
              }}
              onBhEventClose={(event: any) => {
                handleOverheadPanelChange(event, expandedPanelOverhead);
              }}
              className="input-accordion-title"
            >
              <div className="input-wrapper">
                <div className="input-frame order-0">
                  <div>
                    <BhTooltip
                      title="Atm Tower Top Temperature"
                      message="Atmospheric tower top temperature as measured in the overhead line."
                    >
                      <BhTextInput
                        type="text"
                        data-testid="ohtemp-test"
                        label="Atm Tower Top Temperature"
                        value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.OHTemp!}
                        id="ohtemp"
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={(errors && errors.ohtemp) || ohTempErrorText ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.overheadConditions
                            ? simulationData.overheadConditions.OHTempMin + ' to ' + simulationData.overheadConditions.OHTempMax
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.OHTempMin!}
                        </label>
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.OHTempMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.ohtemp || ohTempErrorText}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip title="Atm Tower Top Pressure" message="Atmospheric tower top pressure as measured in the overhead line.">
                      <BhTextInput
                        type="text"
                        data-testid="ohpress-test"
                        label="Atm Tower Top Pressure"
                        class="text-unit"
                        value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.OHPress!}
                        id="ohpress"
                        isError={errors && errors.ohpress ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.overheadConditions
                            ? simulationData.overheadConditions.OHPressMin + ' to ' + simulationData.overheadConditions.OHPressMax
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.OHPressMin!}
                        </label>
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.OHPressMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.ohpress}></BhFormMessage>
                  </div>
                  <div>
                    <BhDropdown
                      label="Unit"
                      data-testid="oh-press-unit-test"
                      class="dropdown-unit"
                      //selectedValue={true}
                      menuItems={JSON.stringify(pressureUnits)}
                      value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.OHPress_UOM!}
                      isRequired
                      id="oh-press-unit"
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                    ></BhDropdown>
                    <span className="typography--body-small info-message" hidden={!isDefaultOhPressUnitChanged}>
                      Default unit is{' '}
                      {subcategoryInputMapping &&
                        Object.keys(subcategoryInputMapping).length &&
                        Object.keys(subcategoryInputMapping.Atm_Tower_Top_Pressure).length &&
                        Object.keys(subcategoryInputMapping.Atm_Tower_Top_Pressure[0]).length &&
                        subcategoryInputMapping.Atm_Tower_Top_Pressure[0].unitSymbol}
                    </span>
                  </div>
                  <div>
                    <BhTooltip
                      title={getLabel().title}
                      message={getLabel().toolTip}
                    >
                      <BhTextInput
                        type="text"
                        isRequired
                        data-testid="washrate-test"
                        label={getLabel().title}
                        class="text-unit"
                        value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washRate!}
                        id="washrate"
                        isError={errors && errors.washrate ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.overheadConditions
                            ? simulationData.overheadConditions.washRateMin + ' to ' + simulationData.overheadConditions.washRateMax
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washRateMin!}
                        </label>
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washRateMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.washrate}></BhFormMessage>
                  </div>
                  <div>
                    <BhDropdown
                      data-testid="wash-rate-unit-test"
                      class="dropdown-unit"
                      label="Unit"
                      //selectedValue={true}
                      value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washRate_UOM!}
                      menuItems={JSON.stringify(washRateUnits)}
                      isRequired
                      id="wash-rate-unit"
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                    ></BhDropdown>
                    <span className="typography--body-small info-message" hidden={!isDefaultWashRateUnitChanged}>
                      Default unit is{' '}
                      {subcategoryInputMapping &&
                        Object.keys(subcategoryInputMapping).length &&
                        Object.keys(subcategoryInputMapping.Overhead_Water_Wash_Rate).length &&
                        Object.keys(subcategoryInputMapping.Overhead_Water_Wash_Rate[0]).length &&
                        subcategoryInputMapping.Overhead_Water_Wash_Rate[0].unitSymbol}
                    </span>
                  </div>
                </div>
                <div className="input-frame order-1">

                    <div>
                      <BhTooltip title="Total External Wash Rate" message="Amount of wash added not recirculated from accumulator.">
                        <BhTextInput
                          type="text"
                          data-testid="totalExternalWashRate-test"
                          label="Total External Wash Rate"
                          unit={
                            simulationData &&
                            simulationData.overheadConditions &&
                            simulationData.overheadConditions.totalExternalWashRate_UOM!
                          }
                          value={
                            simulationData && simulationData.overheadConditions && simulationData.overheadConditions.totalExternalWashRate!
                          }
                          id="totalExternalWashRate"
                          isError={(errors && errors.totalExternalWashRate) || totalExternalErrorText ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.overheadConditions
                              ? simulationData.overheadConditions.totalExternalWashRateMin +
                                ' to ' +
                                simulationData.overheadConditions.totalExternalWashRateMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadConditions &&
                              simulationData.overheadConditions.totalExternalWashRateMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadConditions &&
                              simulationData.overheadConditions.totalExternalWashRateMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.totalExternalWashRate || totalExternalErrorText}></BhFormMessage>
                    </div>
          
                  <div>
                    <BhTooltip
                      title="Overhead Water Wash Temperature"
                      message="Temperature of overhead wash (accumulator temperature if a recirculation wash is used)."
                    >
                      <BhTextInput
                        type="text"
                        data-testid="washtemp-test"
                        label="Overhead Water Wash Temperature"
                        isRequired
                        value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washTemp!}
                        id="washtemp"
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={(errors && errors.washtemp) || washTempErrorText ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.overheadConditions
                            ? simulationData.overheadConditions.washTempMin + ' to ' + simulationData.overheadConditions.washTempMax
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washTempMin!}
                        </label>
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washTempMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.washtemp || washTempErrorText}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip title="Temp Before Water Wash" message="Temperature of process stream where wash is injected.">
                      <BhTextInput
                        type="text"
                        label="Temp Before Water Wash"
                        isRequired
                        data-testid="washprocesstemp-test"
                        value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washProcessTemp!}
                        id="washprocesstemp"
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={(errors && errors.washprocesstemp) || washProcessTempErrorText ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.overheadConditions
                            ? simulationData.overheadConditions.washProcessTempMin +
                              ' to ' +
                              simulationData.overheadConditions.washProcessTempMax
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washProcessTempMin!}
                        </label>
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washProcessTempMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.washprocesstemp || washProcessTempErrorText}></BhFormMessage>
                  </div>

         
                </div>
                <div className="input-frame order-2">

                    <>
                      <div>
                        <BhTooltip title="Pressure Before Water Wash" message="Pressure of process stream where wash is injected.">
                          <BhTextInput
                            type="text"
                            data-testid="washprocesspress-test"
                            class="text-unit"
                            label="Pressure Before Water Wash"
                            value={
                              simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washProcessPress!
                            }
                            id="washprocesspress"
                            isError={errors && errors.washprocesspress ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.overheadConditions
                                ? simulationData.overheadConditions.washProcessPressMin +
                                  ' to ' +
                                  simulationData.overheadConditions.washProcessPressMax
                                : ''
                            }
                          >
                            <label hidden>
                              {simulationData &&
                                simulationData.overheadConditions &&
                                simulationData.overheadConditions.washProcessPressMin!}
                            </label>
                            <label hidden>
                              {simulationData &&
                                simulationData.overheadConditions &&
                                simulationData.overheadConditions.washProcessPressMax!}
                            </label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.washprocesspress}></BhFormMessage>
                      </div>
                      <div>
                        <BhDropdown
                          data-testid="washprocess-press-unit-test"
                          class="dropdown-unit"
                          label="Unit"
                          selectedValue={true}
                          value={
                            simulationData && simulationData.overheadConditions && simulationData.overheadConditions.washProcessPress_UOM!
                          }
                          menuItems={JSON.stringify(pressureUnits)}
                          isRequired
                          id="washprocess-press-unit"
                          onBhEventChange={(event: any) => handleDropDownChange(event)}
                        ></BhDropdown>
                        <span className="typography--body-small info-message" hidden={!isDefaultWashProcessPressUnitChanged}>
                          Default unit is{' '}
                          {subcategoryInputMapping &&
                            Object.keys(subcategoryInputMapping).length &&
                            Object.keys(subcategoryInputMapping.Pressure_before_Water_Wash).length &&
                            Object.keys(subcategoryInputMapping.Pressure_before_Water_Wash[0]).length &&
                            subcategoryInputMapping.Pressure_before_Water_Wash[0].unitSymbol}
                        </span>
                      </div>
                    </>
              

                  <div>
                    <BhTooltip title="Temp at Velocity Calc" message="Temperature of process stream where velocity is calculated.">
                      <BhTextInput
                        type="text"
                        data-testid="velocitytemp-test"
                        label="Temp at Velocity Calc"
                        isRequired
                        value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.velocityTemp!}
                        id="velocitytemp"
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={(errors && errors.velocitytemp) || velocityTempErrorText ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.overheadConditions
                            ? simulationData.overheadConditions.velocityTempMin + ' to ' + simulationData.overheadConditions.velocityTempMax
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.velocityTempMin!}
                        </label>
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.velocityTempMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.velocitytemp || velocityTempErrorText}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip title="Pressure at Velocity Calc" message="Pressure of process stream where velocity is calculated.">
                      <BhTextInput
                        type="text"
                        class="text-unit"
                        data-testid="velocitypress-test"
                        label="Pressure at Velocity Calc"
                        value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.velocityPress!}
                        id="velocitypress"
                        isError={errors && errors.velocitypress ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.overheadConditions
                            ? simulationData.overheadConditions.velocityPressMin +
                              ' to ' +
                              simulationData.overheadConditions.velocityPressMax
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.velocityPressMin!}
                        </label>
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.velocityPressMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.velocitypress}></BhFormMessage>
                  </div>
                  <div>
                    <BhDropdown
                      data-testid="velocity-press-unit-test"
                      label="Unit"
                      class="dropdown-unit"
                      //selectedValue={true}
                      value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.velocityPress_UOM!}
                      menuItems={JSON.stringify(pressureUnits)}
                      isRequired
                      id="velocity-press-unit"
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                    ></BhDropdown>
                    <span className="typography--body-small info-message" hidden={!isDefaultVelocityPressUnitChanged}>
                      Default unit is{' '}
                      {subcategoryInputMapping &&
                        Object.keys(subcategoryInputMapping).length &&
                        Object.keys(subcategoryInputMapping.Pressure_at_Velocity_Calc).length &&
                        Object.keys(subcategoryInputMapping.Pressure_at_Velocity_Calc[0]).length &&
                        subcategoryInputMapping.Pressure_at_Velocity_Calc[0].unitSymbol}
                    </span>
                  </div>

           
                </div>
                <div className="input-frame order-3">
            
                    <div>
                      <BhTooltip
                        title={configType === '1A' || configType === '1B' ? 'Drum Temperature' : 'Hot Drum Temperature'}
                        message={
                          configType === '1A' || configType === '1B'
                            ? 'Overhead accumulator temperature'
                            : 'Overhead first or "hot" accumulator temperature'
                        }
                      >
                        <BhTextInput
                          type="text"
                          label={configType === '1A' || configType === '1B' ? 'Drum Temperature' : 'Hot Drum Temperature'}
                          isRequired
                          data-testid="drumtemp-test"
                          value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.drumTemp!}
                          id="drumtemp"
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          isError={(errors && errors.drumtemp) || drumTempErrorText ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.overheadConditions
                              ? simulationData.overheadConditions.drumTempMin + ' to ' + simulationData.overheadConditions.drumTempMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.drumTempMin!}
                          </label>
                          <label hidden>
                            {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.drumTempMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.drumtemp || drumTempErrorText}></BhFormMessage>
                    </div>
                 
                  <div>
                    <BhTooltip
                      title={configType === '1A' || configType === '1B' ? 'Drum Pressure' : 'Hot Drum Pressure'}
                      message={
                        configType === '1A' || configType === '1B'
                          ? 'Overhead accumulator pressure'
                          : 'Overhead first or "hot" accumulator pressure'
                      }
                    >
                      <BhTextInput
                        type="text"
                        class="text-unit"
                        data-testid="drumpress-test"
                        label={configType === '1A' || configType === '1B' ? 'Drum Pressure' : 'Hot Drum Pressure'}
                        value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.drumPress!}
                        id="drumpress"
                        isError={errors && errors.drumpress ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.overheadConditions
                            ? simulationData.overheadConditions.drumPressMin + ' to ' + simulationData.overheadConditions.drumPressMax
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.drumPressMin!}
                        </label>
                        <label hidden>
                          {simulationData && simulationData.overheadConditions && simulationData.overheadConditions.drumPressMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.drumpress}></BhFormMessage>
                  </div>
                  <div>
                    <BhDropdown
                      label="Unit"
                      data-testid="drum-press-unit-test"
                      class="dropdown-unit"
                      //selectedValue={true}
                      value={simulationData && simulationData.overheadConditions && simulationData.overheadConditions.drumPress_UOM!}
                      menuItems={JSON.stringify(pressureUnits)}
                      isRequired
                      id="drum-press-unit"
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                    ></BhDropdown>
                    <span className="typography--body-small info-message" hidden={!isDefaultDrumPressUnitChanged}>
                      Default unit is{' '}
                      {subcategoryInputMapping &&
                        Object.keys(subcategoryInputMapping).length &&
                        Object.keys(subcategoryInputMapping.Hot_Drum_Pressure).length &&
                        Object.keys(subcategoryInputMapping.Hot_Drum_Pressure[0]).length &&
                        subcategoryInputMapping.Hot_Drum_Pressure[0].unitSymbol}
                    </span>
                  </div>
                  <BhTooltip title="Is Wet Reflux Present?" message='"TRUE" or "FALSE" for wet reflux condition'>
                    <BhDropdown
                      data-testid="wet-reflux-test"
                      id="wet-reflux"
                      class="data-unit"
                      label="Is Wet Reflux Present?"
                      value={
                        simulationData && simulationData.liquidAssayStreams && simulationData.liquidAssayStreams.isWetReflux
                          ? 'TRUE'
                          : 'FALSE'
                      }
                      menuItems={wetRefluxItems}
                      isRequired
                      onBhEventChange={(event) => handleDropDownChange(event)}
                    ></BhDropdown>
                  </BhTooltip>
                </div>
              </div>
            </BhAccordion>
            <BhAccordion
              data-testid="handleHydrocarbonPanelChange-test"
              header="Hydrocarbon Liquid"
              isOpen={expandedPanelHydrocarbon}
              iconOrientation="right"
              size="medium"
              onBhEventOpen={(event: any) => {
                handleHydrocarbonPanelChange(event, expandedPanelHydrocarbon);
              }}
              onBhEventClose={(event: any) => {
                handleHydrocarbonPanelChange(event, expandedPanelHydrocarbon);
              }}
              className="input-accordion-title"
            >
              {(configType === '2A' || configType === '2B') && (
                <div className="sutitle-left">
                  <BhTitleWrapper type="label" subtext="Hydrocarbon Liquid Hot"></BhTitleWrapper>
                </div>
              )}
              <div className="input-wrapper">
                <div className="input-frame order-0">
                  <div>
                    <BhTooltip
                      title={configType === '1A' || configType === '1B' ? 'Overhead Drum Reflux Rate' : 'Hot Drum Reflux Rate'}
                      message="Overhead liquid reflux flow"
                    >
                      <BhTextInput
                        type="text"
                        data-testid="reflux-test"
                        class="text-unit"
                        label={configType === '1A' || configType === '1B' ? 'Overhead Drum Reflux Rate' : 'Hot Drum Reflux Rate'}
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.reflux!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.reflux!
                        }
                        id="reflux"
                        isError={errors && errors.reflux ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid
                              ? simulationData.liquidAssayStreams.hydrocarbonLiquid.refluxMin +
                                ' to ' +
                                simulationData.liquidAssayStreams.hydrocarbonLiquid.refluxMax
                              : ''
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot
                            ? simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotRefluxMin +
                              ' to ' +
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotRefluxMax
                            : ''
                        }
                      >
                        <label hidden>
                          {configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.refluxMin!
                            : simulationData &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotRefluxMin!}
                        </label>
                        <label hidden>
                          {configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.refluxMax!
                            : simulationData &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotRefluxMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.reflux}></BhFormMessage>
                  </div>
                  <div>
                    <BhDropdown
                      data-testid="reflux-unit-test"
                      class="dropdown-unit"
                      label="Unit"
                      //selectedValue={true}
                      value={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.reflux_UOM!
                          : simulationData &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.reflux_UOM!
                      }
                      menuItems={JSON.stringify(refluxProductUnits)}
                      isRequired
                      id="reflux-unit"
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                    ></BhDropdown>
                    <span className="typography--body-small info-message" hidden={!isDefaultRefluxUnitChanged}>
                      Default unit is{' '}
                      {subcategoryInputMapping &&
                        Object.keys(subcategoryInputMapping).length &&
                        Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow).length &&
                        Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow[0]).length &&
                        subcategoryInputMapping.Overhead_Liquid_Flow[0].unitSymbol}
                    </span>
                  </div>
                  <div>
                    <BhTooltip
                      title={configType === '1A' || configType === '1B' ? 'Overhead Drum Product Rate' : 'Hot Drum Product Rate'}
                      message="Overhead liquid product flow"
                    >
                      <BhTextInput
                        type="text"
                        data-testid="product-test"
                        class="text-unit"
                        label={configType === '1A' || configType === '1B' ? 'Overhead Drum Product Rate' : 'Hot Drum Product Rate'}
                        isRequired
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.product!
                            : simulationData &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.product!
                        }
                        id="product"
                        isError={errors && errors.product ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid
                              ? simulationData.liquidAssayStreams.hydrocarbonLiquid.productMin +
                                ' to ' +
                                simulationData.liquidAssayStreams.hydrocarbonLiquid.productMax
                              : ''
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot
                            ? simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotProductMin +
                              ' to ' +
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotProductMax
                            : ''
                        }
                      >
                        <label hidden>
                          {configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.productMin!
                            : simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotProductMin!}
                        </label>
                        <label hidden>
                          {configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.productMax!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotProductMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.product}></BhFormMessage>
                  </div>
                  <div>
                    <BhDropdown
                      class="dropdown-unit"
                      label="Unit"
                      //selectedValue={true}
                      value={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.product_UOM!
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.product_UOM!
                      }
                      menuItems={JSON.stringify(refluxProductUnits)}
                      isRequired
                      data-testid="product-unit-test"
                      id="product-unit"
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                    ></BhDropdown>
                    <span className="typography--body-small info-message" hidden={!isDefaultProductUnitChanged}>
                      Default unit is{' '}
                      {subcategoryInputMapping &&
                        Object.keys(subcategoryInputMapping).length &&
                        Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow).length &&
                        Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow[1]).length &&
                        subcategoryInputMapping.Overhead_Liquid_Flow[1].unitSymbol}
                    </span>
                  </div>
                  <div>
                    <BhTooltip
                      title={configType === '1A' || configType === '1B' ? 'Overhead Drum Naphtha Gravity' : 'Hot Drum Naphtha Gravity'}
                      message="Atmospheric overhead liquid gravity: Specific gravity or °API acceptable"
                    >
                      <BhTextInput
                        data-testid="gravity-test"
                        type="text"
                        label={configType === '1A' || configType === '1B' ? 'Overhead Drum Naphtha Gravity' : 'Hot Drum Naphtha Gravity'}
                        isRequired
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.gravity!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.gravity!
                        }
                        id="gravity"
                        isError={errors && errors.gravity ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid
                              ? simulationData.liquidAssayStreams.hydrocarbonLiquid.gravityMin +
                                ' to ' +
                                simulationData.liquidAssayStreams.hydrocarbonLiquid.gravityMax
                              : ''
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot
                            ? simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotGravityMin +
                              ' to ' +
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotGravityMax
                            : ''
                        }
                      >
                        <label hidden>
                          {configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.gravityMin!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotGravityMin!}
                        </label>
                        <label hidden>
                          {configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.gravityMax!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.hotGravityMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.gravity}></BhFormMessage>
                  </div>
                </div>
                <div className="input-frame order-1">
                  <BhTooltip
                    title={
                      configType === '1A' || configType === '1B'
                        ? 'Overhead Drum Naphtha Distillation Type'
                        : 'Hot Drum Naphtha Distillation Type'
                    }
                    message="Distillation type (select from list)"
                  >
                    <BhDropdown
                      data-testid="distillation-test"
                      class="data-unit"
                      label={
                        configType === '1A' || configType === '1B'
                          ? 'Overhead Drum Naphtha Distillation Type'
                          : 'Hot Drum Naphtha Distillation Type'
                      }
                      id="distillation"
                      menuItems={distillationTypeItems}
                      value={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType!
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType!
                      }
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                      isRequired
                    ></BhDropdown>
                  </BhTooltip>
                  <div>
                    <BhTooltip
                      title={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                            ? '0 wt% Distilled'
                            : '0 vol% Distilled'
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                          ? '0 wt% Distilled'
                          : '0 vol% Distilled'
                      }
                      message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                    >
                      <BhTextInput
                        type="text"
                        data-testid="distillation0-test"
                        label={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                              ? '0 wt% Distilled'
                              : '0 vol% Distilled'
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                            ? '0 wt% Distilled'
                            : '0 vol% Distilled'
                        }
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[0]!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[0]!
                        }
                        id="distillation0"
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={(errors && errors.distillation0) || distillation0ErrorText ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation
                              ? simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation.distillationMin +
                                ' to ' +
                                simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation.distillationMax
                              : ''
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation
                            ? simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation.hotDistillationMin +
                              ' to ' +
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation.hotDistillationMax
                            : ''
                        }
                      >
                        <label hidden>
                          {configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation.distillationMin!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation.hotDistillationMin!}
                        </label>
                        <label hidden>
                          {configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation.distillationMax!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation.hotDistillationMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.distillation0 || distillation0ErrorText}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip
                      title={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                            ? '5 wt% Distilled'
                            : '5 vol% Distilled'
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                          ? '5 wt% Distilled'
                          : '5 vol% Distilled'
                      }
                      message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                    >
                      <BhTextInput
                        type="text"
                        data-testid="distillation5-test"
                        label={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                              ? '5 wt% Distilled'
                              : '5 vol% Distilled'
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                            ? '5 wt% Distilled'
                            : '5 vol% Distilled'
                        }
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[5]!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[5]!
                        }
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        id="distillation5"
                        isError={errors && errors.distillation5 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                      >
                        <label hidden>NA</label>
                        <label hidden>NA</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.distillation5}></BhFormMessage>
                  </div>
                </div>
                <div className="input-frame order-2">
                  <div>
                    <BhTooltip
                      title={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                            ? '10 wt% Distilled'
                            : '10 vol% Distilled'
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                          ? '10 wt% Distilled'
                          : '10 vol% Distilled'
                      }
                      message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                    >
                      <BhTextInput
                        type="text"
                        label={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                              ? '10 wt% Distilled'
                              : '10 vol% Distilled'
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                            ? '10 wt% Distilled'
                            : '10 vol% Distilled'
                        }
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[10]!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[10]!
                        }
                        isRequired
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        data-testid="distillation10-test"
                        id="distillation10"
                        isError={errors && errors.distillation10 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                      >
                        <label hidden>NA</label>
                        <label hidden>NA</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.distillation10}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip
                      title={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                            ? '20 wt% Distilled'
                            : '20 vol% Distilled'
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                          ? '20 wt% Distilled'
                          : '20 vol% Distilled'
                      }
                      message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                    >
                      <BhTextInput
                        type="text"
                        label={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                              ? '20 wt% Distilled'
                              : '20 vol% Distilled'
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                            ? '20 wt% Distilled'
                            : '20 vol% Distilled'
                        }
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[20]!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[20]!
                        }
                        data-testid="distillation20-test"
                        id="distillation20"
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={errors && errors.distillation20 ? true : false}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                      >
                        <label hidden>NA</label>
                        <label hidden>NA</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.distillation20}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip
                      title={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                            ? '30 wt% Distilled'
                            : '30 vol% Distilled'
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                          ? '30 wt% Distilled'
                          : '30 vol% Distilled'
                      }
                      message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                    >
                      <BhTextInput
                        type="text"
                        label={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                              ? '30 wt% Distilled'
                              : '30 vol% Distilled'
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                            ? '30 wt% Distilled'
                            : '30 vol% Distilled'
                        }
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[30]!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[30]!
                        }
                        data-testid="distillation30-test"
                        id="distillation30"
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={errors && errors.distillation30 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                      >
                        <label hidden>NA</label>
                        <label hidden>NA</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.distillation30}></BhFormMessage>
                  </div>
                </div>
                <div className="input-frame order-3">
                  <div>
                    <BhTooltip
                      title={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                            ? '50 wt% Distilled'
                            : '50 vol% Distilled'
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                          ? '50 wt% Distilled'
                          : '50 vol% Distilled'
                      }
                      message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                    >
                      <BhTextInput
                        type="text"
                        label={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                              ? '50 wt% Distilled'
                              : '50 vol% Distilled'
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                            ? '50 wt% Distilled'
                            : '50 vol% Distilled'
                        }
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[50]!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[50]!
                        }
                        data-testid="distillation50-test"
                        id="distillation50"
                        isRequired
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={errors && errors.distillation50 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                      >
                        <label hidden>NA</label>
                        <label hidden>NA</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.distillation50}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip
                      title={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                            ? '70 wt% Distilled'
                            : '70 vol% Distilled'
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                          ? '70 wt% Distilled'
                          : '70 vol% Distilled'
                      }
                      message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                    >
                      <BhTextInput
                        type="text"
                        label={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                              ? '70 wt% Distilled'
                              : '70 vol% Distilled'
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                            ? '70 wt% Distilled'
                            : '70 vol% Distilled'
                        }
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[70]!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[70]!
                        }
                        data-testid="distillation70-test"
                        id="distillation70"
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={errors && errors.distillation70 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                      >
                        <label hidden>NA</label>
                        <label hidden>NA</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.distillation70}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip
                      title={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                            ? '80 wt% Distilled'
                            : '80 vol% Distilled'
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                          ? '80 wt% Distilled'
                          : '80 vol% Distilled'
                      }
                      message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                    >
                      <BhTextInput
                        type="text"
                        label={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                              ? '80 wt% Distilled'
                              : '80 vol% Distilled'
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                            ? '80 wt% Distilled'
                            : '80 vol% Distilled'
                        }
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[80]!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[80]!
                        }
                        data-testid="distillation80-test"
                        id="distillation80"
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={errors && errors.distillation80 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                      >
                        <label hidden>NA</label>
                        <label hidden>NA</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.distillation80}></BhFormMessage>
                  </div>
                </div>
                <div className="input-frame order-4">
                  <div>
                    <BhTooltip
                      title={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                            ? '90 wt% Distilled'
                            : '90 vol% Distilled'
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                          ? '90 wt% Distilled'
                          : '90 vol% Distilled'
                      }
                      message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                    >
                      <BhTextInput
                        type="text"
                        label={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                              ? '90 wt% Distilled'
                              : '90 vol% Distilled'
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                            ? '90 wt% Distilled'
                            : '90 vol% Distilled'
                        }
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[90]!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[90]!
                        }
                        data-testid="distillation90-test"
                        id="distillation90"
                        isRequired
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={errors && errors.distillation90 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                      >
                        <label hidden>NA</label>
                        <label hidden>NA</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.distillation90}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip
                      title={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                            ? '95 wt% Distilled'
                            : '95 vol% Distilled'
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                          ? '95 wt% Distilled'
                          : '95 vol% Distilled'
                      }
                      message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                    >
                      <BhTextInput
                        type="text"
                        label={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                              ? '95 wt% Distilled'
                              : '95 vol% Distilled'
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                            ? '95 wt% Distilled'
                            : '95 vol% Distilled'
                        }
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[95]!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[95]!
                        }
                        data-testid="distillation95-test"
                        id="distillation95"
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={errors && errors.distillation95 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                      >
                        <label hidden>NA</label>
                        <label hidden>NA</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.distillation95}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip
                      title={
                        configType === '1A' || configType === '1B'
                          ? simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                            ? '100 wt% Distilled'
                            : '100 vol% Distilled'
                          : simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                          ? '100 wt% Distilled'
                          : '100 vol% Distilled'
                      }
                      message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                    >
                      <BhTextInput
                        type="text"
                        label={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                              ? '100 wt% Distilled'
                              : '100 vol% Distilled'
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                            ? '100 wt% Distilled'
                            : '100 vol% Distilled'
                        }
                        value={
                          configType === '1A' || configType === '1B'
                            ? simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[100]!
                            : simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[100]!
                        }
                        data-testid="distillation100-test"
                        id="distillation100"
                        unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                        isError={errors && errors.distillation100 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                      >
                        <label hidden>NA</label>
                        <label hidden>NA</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.distillation100}></BhFormMessage>
                  </div>
                </div>
              </div>
              {(configType === '2A' || configType === '2B') && <div className="hr-border"></div>}
              {(configType === '2A' || configType === '2B') && (
                <div className="sutitle-left">
                  <BhTitleWrapper type="label" subtext="Hydrocarbon Liquid Cold"></BhTitleWrapper>
                </div>
              )}
              {(configType === '2A' || configType === '2B') && (
                <div className="input-wrapper">
                  <div className="input-frame order-0">
                    <div>
                      <BhTooltip title="Cold Drum Reflux Rate" message="Overhead liquid reflux flow">
                        <BhTextInput
                          data-testid="reflux_1-test"
                          class="text-unit"
                          label="Cold Drum Reflux Rate"
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.reflux!
                          }
                          id="reflux_1"
                          isError={errors && errors.reflux_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold
                              ? simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldRefluxMin +
                                ' to ' +
                                simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldRefluxMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldRefluxMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldRefluxMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.reflux_1}></BhFormMessage>
                    </div>
                    <div>
                      <BhDropdown
                        data-testid="reflux-unit_1-test"
                        class="dropdown-unit"
                        label="Unit"
                        //selectedValue={true}
                        value={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.reflux_UOM!
                        }
                        menuItems={JSON.stringify(refluxProductUnits)}
                        isRequired
                        id="reflux-unit_1"
                        onBhEventChange={(event: any) => handleDropDownChange(event)}
                      ></BhDropdown>
                      <span className="typography--body-small info-message" hidden={!isDefaultReflux1UnitChanged}>
                        Default unit is{' '}
                        {Object.keys(subcategoryInputMapping).length &&
                          Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow).length &&
                          Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow[0]).length &&
                          subcategoryInputMapping.Overhead_Liquid_Flow[0].unitSymbol}
                      </span>
                    </div>
                    <div>
                      <BhTooltip title="Cold Drum Product Rate" message="Overhead liquid product flow">
                        <BhTextInput
                          class="text-unit"
                          data-testid="product_1-test"
                          label="Cold Drum Product Rate"
                          isRequired
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.product!
                          }
                          id="product_1"
                          isError={errors && errors.product_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold
                              ? simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldProductMin +
                                ' to ' +
                                simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldProductMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldProductMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldProductMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.product_1}></BhFormMessage>
                    </div>
                    <div>
                      <BhDropdown
                        class="dropdown-unit"
                        label="Unit"
                        //selectedValue={true}
                        value={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.product_UOM!
                        }
                        menuItems={JSON.stringify(productUnits)}
                        isRequired
                        data-testid="product-unit_1-test"
                        id="product-unit_1"
                        onBhEventChange={(event: any) => handleDropDownChange(event)}
                      ></BhDropdown>
                      <span className="typography--body-small info-message" hidden={!isDefaultProduct1UnitChanged}>
                        Default unit is{' '}
                        {Object.keys(subcategoryInputMapping).length &&
                          Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow).length &&
                          Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow[1]).length &&
                          subcategoryInputMapping.Overhead_Liquid_Flow[1].unitSymbol}
                      </span>
                    </div>
                    <div>
                      <BhTooltip
                        title="Cold Drum Naphtha Gravity"
                        message="Atmospheric overhead liquid gravity: Specific gravity or °API acceptable"
                      >
                        <BhTextInput
                          data-testid="gravity_1-test"
                          label="Cold Drum Naphtha Gravity"
                          isRequired
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.gravity!
                          }
                          id="gravity_1"
                          isError={errors && errors.gravity_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold
                              ? simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldGravityMin +
                                ' to ' +
                                simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldGravityMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldGravityMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold.coldGravityMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.gravity_1}></BhFormMessage>
                    </div>
                  </div>
                  <div className="input-frame order-1">
                    <BhTooltip title="Cold Drum Naphtha Distillation Type" message="Distillation type (select from list)">
                      <BhDropdown
                        data-testid="distillation_1-test"
                        class="data-unit"
                        label="Cold Drum Naphtha Distillation Type"
                        id="distillation_1"
                        menuItems={distillationTypeItems}
                        value={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType!
                        }
                        onBhEventChange={(event: any) => handleDropDownChange(event)}
                        isRequired
                      ></BhDropdown>
                    </BhTooltip>
                    <div>
                      <BhTooltip
                        title={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                            ? '0 wt% Distilled'
                            : '0 vol% Distilled'
                        }
                        message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                      >
                        <BhTextInput
                          data-testid="distillation0_1-test"
                          label={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                              ? '0 wt% Distilled'
                              : '0 vol% Distilled'
                          }
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[0]!
                          }
                          id="distillation0_1"
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          isError={(errors && errors.distillation0_1) || distillation0_1ErrorText ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation
                              ? simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation.coldDistillationMin +
                                ' to ' +
                                simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation.coldDistillationMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation.coldDistillationMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.liquidAssayStreams &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                              simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation.coldDistillationMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.distillation0_1 || distillation0_1ErrorText}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                            ? '5 wt% Distilled'
                            : '5 vol% Distilled'
                        }
                        message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                      >
                        <BhTextInput
                          data-testid="distillation5_1-test"
                          label={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                              ? '5 wt% Distilled'
                              : '5 vol% Distilled'
                          }
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[5]!
                          }
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          id="distillation5_1"
                          isError={errors && errors.distillation5_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                        >
                          <label hidden>NA</label>
                          <label hidden>NA</label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.distillation5_1}></BhFormMessage>
                    </div>
                  </div>
                  <div className="input-frame order-2">
                    <div>
                      <BhTooltip
                        title={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                            ? '10 wt% Distilled'
                            : '10 vol% Distilled'
                        }
                        message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                      >
                        <BhTextInput
                          label={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                              ? '10 wt% Distilled'
                              : '10 vol% Distilled'
                          }
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[10]!
                          }
                          isRequired
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          data-testid="distillation10_1-test"
                          id="distillation10_1"
                          isError={errors && errors.distillation10_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                        >
                          <label hidden>NA</label>
                          <label hidden>NA</label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.distillation10_1}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                            ? '20 wt% Distilled'
                            : '20 vol% Distilled'
                        }
                        message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                      >
                        <BhTextInput
                          label={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                              ? '20 wt% Distilled'
                              : '20 vol% Distilled'
                          }
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[20]!
                          }
                          data-testid="distillation20_1-test"
                          id="distillation20_1"
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          isError={errors && errors.distillation20_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                        >
                          <label hidden>NA</label>
                          <label hidden>NA</label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.distillation20_1}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                            ? '30 wt% Distilled'
                            : '30 vol% Distilled'
                        }
                        message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                      >
                        <BhTextInput
                          label={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                              ? '30 wt% Distilled'
                              : '30 vol% Distilled'
                          }
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[30]!
                          }
                          data-testid="distillation30_1-test"
                          id="distillation30_1"
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          isError={errors && errors.distillation30_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                        >
                          <label hidden>NA</label>
                          <label hidden>NA</label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.distillation30_1}></BhFormMessage>
                    </div>
                  </div>
                  <div className="input-frame order-3">
                    <div>
                      <BhTooltip
                        title={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                            ? '50 wt% Distilled'
                            : '50 vol% Distilled'
                        }
                        message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                      >
                        <BhTextInput
                          label={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                              ? '50 wt% Distilled'
                              : '50 vol% Distilled'
                          }
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[50]!
                          }
                          data-testid="distillation50_1-test"
                          id="distillation50_1"
                          isRequired
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          isError={errors && errors.distillation50_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                        >
                          <label hidden>NA</label>
                          <label hidden>NA</label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.distillation50_1}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                            ? '70 wt% Distilled'
                            : '70 vol% Distilled'
                        }
                        message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                      >
                        <BhTextInput
                          label={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                              ? '70 wt% Distilled'
                              : '70 vol% Distilled'
                          }
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[70]!
                          }
                          data-testid="distillation70_1-test"
                          id="distillation70_1"
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          isError={errors && errors.distillation70_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                        >
                          <label hidden>NA</label>
                          <label hidden>NA</label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.distillation70_1}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                            ? '80 wt% Distilled'
                            : '80 vol% Distilled'
                        }
                        message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                      >
                        <BhTextInput
                          label={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                              ? '80 wt% Distilled'
                              : '80 vol% Distilled'
                          }
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[80]!
                          }
                          data-testid="distillation80_1-test"
                          id="distillation80_1"
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          isError={errors && errors.distillation80_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                        >
                          <label hidden>NA</label>
                          <label hidden>NA</label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.distillation80_1}></BhFormMessage>
                    </div>
                  </div>
                  <div className="input-frame order-4">
                    <div>
                      <BhTooltip
                        title={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                            ? '90 wt% Distilled'
                            : '90 vol% Distilled'
                        }
                        message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                      >
                        <BhTextInput
                          label={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                              ? '90 wt% Distilled'
                              : '90 vol% Distilled'
                          }
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[90]!
                          }
                          data-testid="distillation90_1-test"
                          id="distillation90_1"
                          isRequired
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          isError={errors && errors.distillation90_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                        >
                          <label hidden>NA</label>
                          <label hidden>NA</label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.distillation90_1}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                            ? '95 wt% Distilled'
                            : '95 vol% Distilled'
                        }
                        message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                      >
                        <BhTextInput
                          label={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                              ? '95 wt% Distilled'
                              : '95 vol% Distilled'
                          }
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[95]!
                          }
                          data-testid="distillation95_1-test"
                          id="distillation95_1"
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          isError={errors && errors.distillation95_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                        >
                          <label hidden>NA</label>
                          <label hidden>NA</label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.distillation95_1}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title={
                          simulationData &&
                          Object.keys(simulationData).length > 0 &&
                          simulationData.liquidAssayStreams &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                          simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                            ? '100 wt% Distilled'
                            : '100 vol% Distilled'
                        }
                        message="Do not change the Volume% numbers. These are fixed in the web version. You may skip temperature entries for volume% not used, but there must be a 10, 50 & 90 percent."
                      >
                        <BhTextInput
                          label={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                              ? '100 wt% Distilled'
                              : '100 vol% Distilled'
                          }
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.liquidAssayStreams &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation &&
                            simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[100]!
                          }
                          data-testid="distillation100_1-test"
                          id="distillation100_1"
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          isError={errors && errors.distillation100_1 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                        >
                          <label hidden>NA</label>
                          <label hidden>NA</label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.distillation100_1}></BhFormMessage>
                    </div>
                  </div>
                </div>
              )}
            </BhAccordion>
            <BhAccordion
              data-testid="handleOffgasPanelChange-test"
              header="Off Gas"
              isOpen={expandedPanelOffgas}
              iconOrientation="right"
              size="medium"
              onBhEventOpen={(event: any) => {
                handleOffgasPanelChange(event, expandedPanelOffgas);
              }}
              onBhEventClose={(event: any) => {
                handleOffgasPanelChange(event, expandedPanelOffgas);
              }}
              className="input-accordion-title"
            >
              <div className="input-wrapper">
                <div className="input-frame order-0">
                  <div>
                    <BhTooltip title="Overhead Off Gas Rate" message="Accumulator off gas flow">
                      <BhTextInput
                        type="text"
                        class="text-unit"
                        label="Overhead Off Gas Rate"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.offGas!}
                        data-testid="offgas-test"
                        id="offgas"
                        isError={errors && errors.offgas ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.offGasMin + ' to ' + simulationData.offGasData.offGasMax
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.offGasMin!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.offGasMax!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.offgas}></BhFormMessage>
                  </div>
                  <div>
                    <BhDropdown
                      class="dropdown-unit"
                      label="Unit"
                      selectedValue={true}
                      value={simulationData && simulationData.offGasData && simulationData.offGasData.offGas_UOM!}
                      menuItems={JSON.stringify(offGasUnits)}
                      isRequired
                      data-testid="off-gas-unit-test"
                      id="off-gas-unit"
                      onBhEventChange={(event: any) => handleDropDownChange(event)}
                    ></BhDropdown>
                    <span className="typography--body-small info-message" hidden={!isDefaultOffGasUnitChanged}>
                      Default unit is{' '}
                      {subcategoryInputMapping &&
                        Object.keys(subcategoryInputMapping).length &&
                        Object.keys(subcategoryInputMapping.Overhead_Gas_Flow).length &&
                        Object.keys(subcategoryInputMapping.Overhead_Gas_Flow[0]).length &&
                        subcategoryInputMapping.Overhead_Gas_Flow[0].unitSymbol}
                    </span>
                  </div>
                  <div>
                    <BhTooltip title="Off Gas Composition - H2" message="Mole % of hydrogen in off gas GC">
                      <BhTextInput
                        type="text"
                        label="Off Gas Composition - H2"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.H2!}
                        data-testid="h2-test"
                        id="h2"
                        isError={errors && errors.h2 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.H2Min + ' to ' + simulationData.offGasData.H2Max
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.H2Min!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.H2Max!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.h2}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip title="Off Gas Composition - H2S" message="Mole % of hydrogen sulfide in off gas GC">
                      <BhTextInput
                        type="text"
                        label="Off Gas Composition - H2S"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.H2S!}
                        data-testid="h2s-test"
                        id="h2s"
                        isError={errors && errors.h2s ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.H2SMin + ' to ' + simulationData.offGasData.H2SMax
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.H2SMin!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.H2SMax!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.h2s}></BhFormMessage>
                  </div>
                </div>
                <div className="input-frame order-1">
                  <div>
                    <BhTooltip title="Off Gas Composition - CO2" message="Mole % of carbon dioxide in off gas GC">
                      <BhTextInput
                        type="text"
                        label="Off Gas Composition - CO2"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.CO2!}
                        data-testid="co2-test"
                        id="co2"
                        isError={errors && errors.co2 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.CO2Min + ' to ' + simulationData.offGasData.CO2Max
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.CO2Min!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.CO2Max!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.co2}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip title="Off Gas Composition - Methane" message="Mole % of methane in off gas GC">
                      <BhTextInput
                        type="text"
                        label="Off Gas Composition - Methane"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.C1!}
                        data-testid="c1-test"
                        id="c1"
                        isError={errors && errors.c1 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.C1Min + ' to ' + simulationData.offGasData.C1Max
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.C1Min!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.C1Max!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.c1}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip title="Off Gas Composition - Ethane" message="Mole % of ethane in off gas GC">
                      <BhTextInput
                        type="text"
                        label="Off Gas Composition - Ethane"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.C2!}
                        data-testid="c2-test"
                        id="c2"
                        isError={errors && errors.c2 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.C2Min + ' to ' + simulationData.offGasData.C2Max
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.C2Min!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.C2Max!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.c2}></BhFormMessage>
                  </div>
                </div>
                <div className="input-frame order-2">
                  <div>
                    <BhTooltip title="Off Gas Composition - Propane" message="Mole % of propane in off gas GC">
                      <BhTextInput
                        type="text"
                        label="Off Gas Composition - Propane"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.C3!}
                        data-testid="c3-test"
                        id="c3"
                        isError={errors && errors.c3 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.C3Min + ' to ' + simulationData.offGasData.C3Max
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.C3Min!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.C3Max!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.c3}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip title="Off Gas Composition - Isobutane" message="Mole % of isobutane in off gas GC">
                      <BhTextInput
                        type="text"
                        label="Off Gas Composition - Isobutane"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.iC4!}
                        data-testid="ic4-test"
                        id="ic4"
                        isError={errors && errors.ic4 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.iC4Min + ' to ' + simulationData.offGasData.iC4Max
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.iC4Min!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.iC4Max!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.ic4}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip title="Off Gas Composition - n-Butane" message="Mole % of n-butane in off gas GC">
                      <BhTextInput
                        type="text"
                        label="Off Gas Composition - n-Butane"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.nC4!}
                        data-testid="nc4-test"
                        id="nc4"
                        isError={errors && errors.nc4 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.nC4Min + ' to ' + simulationData.offGasData.nC4Max
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.nC4Min!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.nC4Max!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.nc4}></BhFormMessage>
                  </div>
                </div>
                <div className="input-frame order-3">
                  <div>
                    <BhTooltip title="Off Gas Composition - Isopentane" message="Mole % of isopentane in off gas GC">
                      <BhTextInput
                        type="text"
                        label="Off Gas Composition - Isopentane"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.iC5!}
                        data-testid="ic5-test"
                        id="ic5"
                        isError={errors && errors.ic5 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.iC5Min + ' to ' + simulationData.offGasData.iC5Max
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.iC5Min!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.iC5Max!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.ic5}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip title="Off Gas Composition - n-Pentane" message="Mole % of n-pentane in off gas GC">
                      <BhTextInput
                        type="text"
                        label="Off Gas Composition - n-Pentane"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.nC5!}
                        data-testid="nc5-test"
                        id="nc5"
                        isError={errors && errors.nc5 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.nC5Min + ' to ' + simulationData.offGasData.nC5Max
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.nC5Min!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.nC5Max!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.nc5}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip title="Off Gas Composition - n-Hexane+" message="Mole % of hexanes+ in off gas GC">
                      <BhTextInput
                        type="text"
                        label="Off Gas Composition - n-Hexane+"
                        value={simulationData && simulationData.offGasData && simulationData.offGasData.nC6!}
                        data-testid="nc6-test"
                        id="nc6"
                        isError={errors && errors.nc6 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.offGasData
                            ? simulationData.offGasData.nC6Min + ' to ' + simulationData.offGasData.nC6Max
                            : ''
                        }
                      >
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.nC6Min!}</label>
                        <label hidden>{simulationData && simulationData.offGasData && simulationData.offGasData.nC6Max!}</label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.nc6}></BhFormMessage>
                  </div>
                </div>
              </div>
            </BhAccordion>
            <BhAccordion
              data-testid="handleOverheadWaterPanelChange-test"
              header="Overhead Water"
              isOpen={expandedPanelOverheadWater}
              iconOrientation="right"
              size="medium"
              onBhEventOpen={(event: any) => {
                handleOverheadWaterPanelChange(event, expandedPanelOverheadWater);
              }}
              onBhEventClose={(event: any) => {
                handleOverheadWaterPanelChange(event, expandedPanelOverheadWater);
              }}
              className="input-accordion-title"
            >
              <div className="input-wrapper">
                <div className="input-frame order-0">
                  <div>
                    <BhTooltip
                      title={configType === '1A' || configType === '1B' ? 'Overhead Drum Water pH' : 'Hot Drum Water pH'}
                      message={
                        configType === '1A' || configType === '1B'
                          ? 'pH of overhead accumulator water'
                          : 'pH of overhead hot or first accumulator water'
                      }
                    >
                      <BhTextInput
                        type="text"
                        label={configType === '1A' || configType === '1B' ? 'Overhead Drum Water pH' : 'Hot Drum Water pH'}
                        isRequired
                        value={
                          simulationData &&
                          simulationData.overheadWater &&
                          simulationData.overheadWater.pHData &&
                          simulationData.overheadWater.pHData.pH!
                        }
                        data-testid="ph-test"
                        id="ph"
                        isError={errors && errors.ph ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.overheadWater && simulationData.overheadWater.pHData
                            ? simulationData.overheadWater.pHData.PHMin + ' to ' + simulationData.overheadWater.pHData.PHMax
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.pHData &&
                            simulationData.overheadWater.pHData.PHMin!}
                        </label>
                        <label hidden>
                          {simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.pHData &&
                            simulationData.overheadWater.pHData.PHMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.ph}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip
                      title={configType === '1A' || configType === '1B' ? 'Overhead Drum Water Cl' : 'Hot Drum Water Cl'}
                      message={
                        configType === '1A' || configType === '1B'
                          ? 'wppm chloride in overhead accumulator water'
                          : 'wppm chloride in overhead hot or first accumulator water'
                      }
                    >
                      <BhTextInput
                        type="text"
                        data-testid="cl-test"
                        label={configType === '1A' || configType === '1B' ? 'Overhead Drum Water Cl' : 'Hot Drum Water Cl'}
                        value={
                          simulationData &&
                          simulationData.overheadWater &&
                          simulationData.overheadWater.clData &&
                          simulationData.overheadWater.clData.cl!
                        }
                        id="cl"
                        isError={errors && errors.cl ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.overheadWater && simulationData.overheadWater.clData
                            ? simulationData.overheadWater.clData.chlorideMin + ' to ' + simulationData.overheadWater.clData.chlorideMax
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.clData &&
                            simulationData.overheadWater.clData.chlorideMin!}
                        </label>
                        <label hidden>
                          {simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.clData &&
                            simulationData.overheadWater.clData.chlorideMax!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.cl}></BhFormMessage>
                  </div>
                  <div>
                    <BhTooltip
                      title={configType === '1A' || configType === '1B' ? 'Overhead Drum Water NH3' : 'Hot Drum Water NH3'}
                      message={
                        configType === '1A' || configType === '1B'
                          ? 'wppm of ammonia in overhead accumulator water'
                          : 'wppm of ammonia in overhead hot or first accumulator water'
                      }
                    >
                      <BhTextInput
                        data-testid="nh3-test"
                        type="text"
                        label={configType === '1A' || configType === '1B' ? 'Overhead Drum Water NH3' : 'Hot Drum Water NH3'}
                        value={
                          simulationData &&
                          simulationData.overheadWater &&
                          simulationData.overheadWater.NH3Data &&
                          simulationData.overheadWater.NH3Data.NH3!
                        }
                        id="nh3"
                        isError={errors && errors.nh3 ? true : false}
                        onBhEventChange={(event: any) => handleInputChange(event)}
                        onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                        onBhEventBlur={(event: any) => handleInputChange(event)}
                        message={
                          simulationData && simulationData.overheadWater && simulationData.overheadWater.NH3Data
                            ? simulationData.overheadWater.NH3Data.NH3Min + ' to ' + simulationData.overheadWater.NH3Data.NH3Max
                            : ''
                        }
                      >
                        <label hidden>
                          {simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.NH3Data &&
                            simulationData.overheadWater.NH3Data.NH3Min!}
                        </label>
                        <label hidden>
                          {simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.NH3Data &&
                            simulationData.overheadWater.NH3Data.NH3Max!}
                        </label>
                      </BhTextInput>
                    </BhTooltip>
                    <BhFormMessage isError message={errors.nh3}></BhFormMessage>
                  </div>
                </div>
                {(configType === '2A' || configType === '2B') && (
                  <div className="input-frame order-1">
                    <div>
                      <BhTooltip title="Cold Drum Water pH" message="pH of overhead cold or second accumulator water">
                        <BhTextInput
                          data-testid="ph2-test"
                          label="Cold Drum Water pH"
                          isRequired
                          value={
                            simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.pHData &&
                            simulationData.overheadWater.pHData.pH_2!
                          }
                          id="ph2"
                          isError={errors && errors.ph2 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.overheadWater && simulationData.overheadWater.pHData
                              ? simulationData.overheadWater.pHData.PHMin + ' to ' + simulationData.overheadWater.pHData.PHMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.pHData &&
                              simulationData.overheadWater.pHData.PHMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.pHData &&
                              simulationData.overheadWater.pHData.PHMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.ph2}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip title="Cold Drum Water Cl" message="wppm chloride in overhead cold or second accumulator water">
                        <BhTextInput
                          data-testid="cl2-test"
                          label="Cold Drum Water Cl"
                          value={
                            simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.clData &&
                            simulationData.overheadWater.clData.cl_2!
                          }
                          id="cl2"
                          isError={errors && errors.cl2 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.overheadWater && simulationData.overheadWater.clData
                              ? simulationData.overheadWater.clData.chlorideMin + ' to ' + simulationData.overheadWater.clData.chlorideMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.clData &&
                              simulationData.overheadWater.clData.chlorideMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.clData &&
                              simulationData.overheadWater.clData.chlorideMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.cl2}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip title="Cold Drum Water NH3" message="wppm of ammonia in overhead cold or second accumulator water">
                        <BhTextInput
                          data-testid="nh32-test"
                          label="Cold Drum Water NH3"
                          value={
                            simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.NH3Data &&
                            simulationData.overheadWater.NH3Data.NH3_2!
                          }
                          id="nh32"
                          isError={errors && errors.nh32 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.overheadWater && simulationData.overheadWater.NH3Data
                              ? simulationData.overheadWater.NH3Data.NH3Min + ' to ' + simulationData.overheadWater.NH3Data.NH3Max
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.NH3Data &&
                              simulationData.overheadWater.NH3Data.NH3Min!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.NH3Data &&
                              simulationData.overheadWater.NH3Data.NH3Max!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.nh32}></BhFormMessage>
                    </div>
                  </div>
                )}
                <div className="input-frame order-1">
                  {(unitType === 'coker' || unitType === 'fcc') && (
                    <>
                      <div>
                        <BhTooltip
                          title={configType === '1A' || configType === '1B' ? 'Overhead Drum Water NH3' : 'Hot Drum Water NH3'}
                          message={
                            configType === '1A' || configType === '1B'
                              ? 'wppm of ammonia in overhead accumulator water'
                              : 'wppm of ammonia in overhead hot or first accumulator water'
                          }
                        >
                          <BhTextInput
                            data-testid="sulfide-test"
                            type="text"
                            label="Sulfide"
                            value={simulationData && simulationData.overheadWater && simulationData.overheadWater.sulfide}
                            id="sulfide"
                            isError={errors && errors.sulfide ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.overheadWater
                                ? simulationData.overheadWater.sulfideMin + ' to ' + simulationData.overheadWater.sulfideMax
                                : ''
                            }
                          >
                            <label hidden>
                              {simulationData && simulationData.overheadWater && simulationData.overheadWater.sulfideMin!}
                            </label>
                            <label hidden>
                              {simulationData && simulationData.overheadWater && simulationData.overheadWater.sulfideMax!}
                            </label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.sulfide}></BhFormMessage>
                      </div>
                    </>
                  )}
                  {unitType !== 'coker' && unitType !== 'fcc' && (
                    <>
                      <BhDropdown
                        class="data-unit"
                        data-testid="neutralizer-test"
                        label="Overhead Neutralizer"
                        id="neutralizer"
                        value={simulationData && simulationData.overheadWater && simulationData.overheadWater.neutralizerName!}
                        menuItems={JSON.stringify(neutralizerData)}
                        //placeholder="No preflash"
                        isRequired
                        onBhEventChange={(event: any) => handleDropDownChange(event)}
                      ></BhDropdown>
                      <div>
                        <BhTooltip
                          title="Overhead Neutralizer Rate"
                          message="Must use volume flow units with all neutralizers except Ammonia Gas which requires mass flow."
                        >
                          <BhTextInput
                            data-testid="neutralizerrate-test"
                            type="text"
                            class="text-unit"
                            label="Overhead Neutralizer Rate"
                            value={simulationData && simulationData.overheadWater && simulationData.overheadWater.neutralizerRate!}
                            id="neutralizerrate"
                            isError={errors && errors.neutralizerrate ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.overheadWater
                                ? simulationData.overheadWater.neutralizerRateMin + ' to ' + simulationData.overheadWater.neutralizerRateMax
                                : ''
                            }
                          >
                            <label hidden>
                              {simulationData && simulationData.overheadWater && simulationData.overheadWater.neutralizerRateMin!}
                            </label>
                            <label hidden>
                              {simulationData && simulationData.overheadWater && simulationData.overheadWater.neutralizerRateMax!}
                            </label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.neutralizerrate}></BhFormMessage>
                      </div>
                      <div>
                        <BhDropdown
                          class="dropdown-unit"
                          label="Unit"
                          //selectedValue={true}
                          value={simulationData && simulationData.overheadWater && simulationData.overheadWater.neutralizerRate_UOM!}
                          menuItems={JSON.stringify(neutralizerUnits)}
                          isRequired
                          data-testid="neutra-rate-unit-test"
                          id="neutra-rate-unit"
                          onBhEventChange={(event: any) => handleDropDownChange(event)}
                        ></BhDropdown>
                        <span className="typography--body-small info-message" hidden={!isDefaultNeutralizerRateUnitChanged}>
                          Default unit is{' '}
                          {subcategoryInputMapping &&
                            Object.keys(subcategoryInputMapping).length &&
                            subcategoryInputMapping.Overhead_Neutralizer_Rate &&
                            Object.keys(subcategoryInputMapping.Overhead_Neutralizer_Rate).length &&
                            Object.keys(subcategoryInputMapping.Overhead_Neutralizer_Rate[0]).length &&
                            subcategoryInputMapping.Overhead_Neutralizer_Rate[0].unitSymbol}
                        </span>
                      </div>
                    </>
                  )}
                  <BhTooltip
                    title="Overhead Tramp Amine"
                    message="Name of tramp amine to track (select from list; none if no tramp amine to track)"
                  >
                    <BhDropdown
                      data-testid="tramp-amine-test"
                      class="data-unit"
                      label="Overhead Tramp Amine"
                      id="tramp-amine"
                      value={
                        simulationData &&
                        simulationData.overheadWater &&
                        simulationData.overheadWater.trampAmineData &&
                        simulationData.overheadWater.trampAmineData.trampAmine!
                      }
                      menuItems={JSON.stringify(amineUnits)}
                      isRequired
                      onBhEventChange={(event) => handleDropDownChange(event)}
                    ></BhDropdown>
                  </BhTooltip>
                  {(unitType === 'coker' || unitType === 'fcc') && (
                    <div>
                      <BhTooltip
                        title={configType === '1A' || configType === '1B' ? 'Overhead Tramp Amine ppm' : '1° Tramp Amine ppm'}
                        message={
                          configType === '1A' || configType === '1B'
                            ? 'wppm of tramp amine in overhead accumulator water (use maximum expected value if not measuring with IC regularly)'
                            : 'wppm of tramp amine in hot or first accumulator water (use maximum expected value if not measuring with IC regularly)'
                        }
                      >
                        <BhTextInput
                          data-testid="trampamine-test"
                          type="text"
                          label={configType === '1A' || configType === '1B' ? 'Overhead Tramp Amine ppm' : '1° Tramp Amine ppm'}
                          value={
                            simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.trampAmineData &&
                            simulationData.overheadWater.trampAmineData.trampAmineppm!
                          }
                          id="trampamine"
                          isError={errors && errors.trampamine ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.overheadWater && simulationData.overheadWater.trampAmineData
                              ? simulationData.overheadWater.trampAmineData.trampAmineppmMin +
                                ' to ' +
                                simulationData.overheadWater.trampAmineData.trampAmineppmMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.trampAmineData.trampAmineppmMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.trampAmineData.trampAmineppmMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.trampamine}></BhFormMessage>
                    </div>
                  )}
                </div>
                <div className="input-frame order-2">
                  {unitType !== 'coker' && unitType !== 'fcc' && (
                    <div>
                      <BhTooltip
                        title={configType === '1A' || configType === '1B' ? 'Overhead Tramp Amine ppm' : '1° Tramp Amine ppm'}
                        message={
                          configType === '1A' || configType === '1B'
                            ? 'wppm of tramp amine in overhead accumulator water (use maximum expected value if not measuring with IC regularly)'
                            : 'wppm of tramp amine in hot or first accumulator water (use maximum expected value if not measuring with IC regularly)'
                        }
                      >
                        <BhTextInput
                          type="text"
                          label={configType === '1A' || configType === '1B' ? 'Overhead Tramp Amine ppm' : '1° Tramp Amine ppm'}
                          value={
                            simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.trampAmineData &&
                            simulationData.overheadWater.trampAmineData.trampAmineppm!
                          }
                          id="trampamine"
                          isError={errors && errors.trampamine ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.overheadWater && simulationData.overheadWater.trampAmineData
                              ? simulationData.overheadWater.trampAmineData.trampAmineppmMin +
                                ' to ' +
                                simulationData.overheadWater.trampAmineData.trampAmineppmMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.trampAmineData.trampAmineppmMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.trampAmineData.trampAmineppmMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.trampamine}></BhFormMessage>
                    </div>
                  )}
                  {(configType === '2A' || configType === '2B') && (
                    <div>
                      <BhTooltip
                        title="2° Tramp Amine ppm"
                        message="wppm of tramp amine in cold or second accumulator water (use maximum expected value if not measuring with IC regularly)"
                      >
                        <BhTextInput
                          data-testid="trampamine2-test"
                          label="2° Tramp Amine ppm"
                          value={
                            simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.trampAmineData &&
                            simulationData.overheadWater.trampAmineData.trampAmineppm2!
                          }
                          id="trampamine2"
                          isError={errors && errors.trampamine2 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.overheadWater && simulationData.overheadWater.trampAmineData
                              ? simulationData.overheadWater.trampAmineData.trampAmineppmMin +
                                ' to ' +
                                simulationData.overheadWater.trampAmineData.trampAmineppmMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.trampAmineData.trampAmineppmMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.trampAmineData.trampAmineppmMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.trampamine2}></BhFormMessage>
                    </div>
                  )}
                  <div>
                    <BhTooltip
                      title="Overhead Steam Amine"
                      message="Name of steam amine to track (select from list; none if no steam amine to track)"
                    >
                      <BhDropdown
                        data-testid="steam-amine-test"
                        class="data-unit"
                        label="Overhead Steam Amine"
                        id="steam-amine"
                        value={
                          simulationData &&
                          simulationData.overheadWater &&
                          simulationData.overheadWater.steamAmineData &&
                          simulationData.overheadWater.steamAmineData.steamAmine!
                        }
                        menuItems={JSON.stringify(amineUnits)}
                        isRequired
                        onBhEventChange={(event) => handleDropDownChange(event)}
                      ></BhDropdown>
                    </BhTooltip>
                  </div>
                  {(configType === '1A' || configType === '1B') && (
                    <div>
                      <BhTooltip title="Overhead Steam Amine ppm" message="wppm of steam amine in steam ">
                        <BhTextInput
                          data-testid="steamamine-test"
                          type="text"
                          label="Overhead Steam Amine ppm"
                          value={
                            simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.steamAmineData &&
                            simulationData.overheadWater.steamAmineData.steamAmineppm!
                          }
                          id="steamamine"
                          isError={errors && errors.steamamine ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.overheadWater && simulationData.overheadWater.steamAmineData
                              ? simulationData.overheadWater.steamAmineData.steamAmineppmMin +
                                ' to ' +
                                simulationData.overheadWater.steamAmineData.steamAmineppmMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.steamAmineData.steamAmineppmMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.steamAmineData.steamAmineppmMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.steamamine}></BhFormMessage>
                    </div>
                  )}
                </div>
                {(configType === '2A' || configType === '2B') && (
                  <div className="input-frame order-3">
                    <div>
                      <BhTooltip
                        title="1° Steam Amine ppm"
                        message="wppm of steam amine in hot or first accumulator water (use maximum expected value if not measuring with IC regularly)"
                      >
                        <BhTextInput
                          label="1° Steam Amine ppm"
                          value={
                            simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.steamAmineData &&
                            simulationData.overheadWater.steamAmineData.steamAmineppm!
                          }
                          id="steamamine"
                          isError={errors && errors.steamamine ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.overheadWater && simulationData.overheadWater.steamAmineData
                              ? simulationData.overheadWater.steamAmineData.steamAmineppmMin +
                                ' to ' +
                                simulationData.overheadWater.steamAmineData.steamAmineppmMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.steamAmineData.steamAmineppmMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.steamAmineData.steamAmineppmMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.steamamine}></BhFormMessage>
                    </div>
                    <div>
                      <BhTooltip
                        title="2° Steam Amine ppm"
                        message="wppm of steam amine in cold or second accumulator water (use maximum expected value if not measuring with IC regularly)"
                      >
                        <BhTextInput
                          data-testid="steamamine2-test"
                          label="2° Steam Amine ppm"
                          value={
                            simulationData &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.steamAmineData &&
                            simulationData.overheadWater.steamAmineData.steamAmineppm2!
                          }
                          id="steamamine2"
                          isError={errors && errors.steamamine2 ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData && simulationData.overheadWater && simulationData.overheadWater.steamAmineData
                              ? simulationData.overheadWater.steamAmineData.steamAmineppmMin +
                                ' to ' +
                                simulationData.overheadWater.steamAmineData.steamAmineppmMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.steamAmineData.steamAmineppmMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.steamAmineData.steamAmineppmMax!}
                          </label>
                        </BhTextInput>
                      </BhTooltip>
                      <BhFormMessage isError message={errors.steamamine2}></BhFormMessage>
                    </div>
                    {unitType !== 'coker' && unitType !== 'fcc' && (configType === '2A' || configType === '2B') && (
                      <>
                        <div>
                        <BhTooltip
                          title="Total Combined Reflux Flow"
                          message="Total reflux flow returning to top of distillation tower.  Include pumparound flow if it returns to top tray.  If overhead is the only reflux duplicate its flow here."
                          >
                          <BhTextInput
                            isRequired
                            type="text"
                            data-testid="combinedRefluxFlow-test"
                            class="text-unit"
                            label="Total Combined Reflux Flow"
                            value={
                              simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.combinedRefluxData &&
                              simulationData.overheadWater.combinedRefluxData.combinedRefluxFlow!
                            }
                            id="combinedRefluxFlow"
                            isError={errors && errors.combinedRefluxFlow ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.combinedRefluxData
                                ? simulationData.overheadWater.combinedRefluxData.combinedRefluxFlowMin +
                                  ' to ' +
                                  simulationData.overheadWater.combinedRefluxData.combinedRefluxFlowMax
                                : ''
                            }
                          >
                            <label hidden>
                              {simulationData &&
                                Object.keys(simulationData).length > 0 &&
                                simulationData.overheadWater &&
                                simulationData.overheadWater.combinedRefluxData &&
                                simulationData.overheadWater.combinedRefluxData.combinedRefluxFlowMin!}
                            </label>
                            <label hidden>
                              {simulationData &&
                                Object.keys(simulationData).length > 0 &&
                                simulationData.overheadWater &&
                                simulationData.overheadWater.combinedRefluxData &&
                                simulationData.overheadWater.combinedRefluxData.combinedRefluxFlowMax!}
                            </label>
                          </BhTextInput>
                          </BhTooltip>
                          <BhFormMessage isError message={errors.combinedRefluxFlow}></BhFormMessage>
                        </div>
                        <div>
                          <BhDropdown
                            data-testid="combined-reflux-unit-test"
                            class="dropdown-unit"
                            label="Unit"
                            value={
                              simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.combinedRefluxData &&
                              simulationData.overheadWater.combinedRefluxData.combinedRefluxFlow_UOM!
                            }
                            menuItems={JSON.stringify(refluxProductUnits)}
                            isRequired
                            id="combined-reflux-unit"
                            onBhEventChange={(event: any) => handleDropDownChange(event)}
                          ></BhDropdown>
                          <span className="typography--body-small info-message" hidden={!isDefaultCombinedRefluxFlowChanged}>
                            Default unit is{' '}
                            {subcategoryInputMapping &&
                              Object.keys(subcategoryInputMapping).length &&
                              Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow).length &&
                              Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow[0]).length &&
                              subcategoryInputMapping.Overhead_Liquid_Flow[0].unitSymbol}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="input-wrapper">
                <div className="input-frame order-0">
                  {unitType !== 'coker' && unitType !== 'fcc' && (configType === '1A' || configType === '1B') && (
                    <>
                      <div>
                        <BhTooltip
                          title="Total Combined Reflux Flow"
                          message="Total reflux flow returning to top of distillation tower.  Include pumparound flow if it returns to top tray.  If overhead is the only reflux duplicate its flow here."
                        >
                        <BhTextInput
                          isRequired
                          type="text"
                          data-testid="combinedRefluxFlow-test"
                          class="text-unit"
                          label="Total Combined Reflux Flow"
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.combinedRefluxData &&
                            simulationData.overheadWater.combinedRefluxData.combinedRefluxFlow!
                          }
                          id="combinedRefluxFlow"
                          isError={errors && errors.combinedRefluxFlow ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.combinedRefluxData
                              ? simulationData.overheadWater.combinedRefluxData.combinedRefluxFlowMin +
                                ' to ' +
                                simulationData.overheadWater.combinedRefluxData.combinedRefluxFlowMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.combinedRefluxData &&
                              simulationData.overheadWater.combinedRefluxData.combinedRefluxFlowMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.combinedRefluxData &&
                              simulationData.overheadWater.combinedRefluxData.combinedRefluxFlowMax!}
                          </label>
                        </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.combinedRefluxFlow}></BhFormMessage>
                      </div>
                      <div>
                        <BhDropdown
                          data-testid="combined-reflux-unit-test"
                          class="dropdown-unit"
                          label="Unit"
                          value={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.combinedRefluxData &&
                            simulationData.overheadWater.combinedRefluxData.combinedRefluxFlow_UOM!
                          }
                          menuItems={JSON.stringify(refluxProductUnits)}
                          isRequired
                          id="combined-reflux-unit"
                          onBhEventChange={(event: any) => handleDropDownChange(event)}
                        ></BhDropdown>
                        <span className="typography--body-small info-message" hidden={!isDefaultCombinedRefluxFlowChanged}>
                          Default unit is{' '}
                          {subcategoryInputMapping &&
                            Object.keys(subcategoryInputMapping).length &&
                            Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow).length &&
                            Object.keys(subcategoryInputMapping.Overhead_Liquid_Flow[0]).length &&
                            subcategoryInputMapping.Overhead_Liquid_Flow[0].unitSymbol}
                        </span>
                      </div>
                    </>
                  )}
                  {unitType !== 'coker' && unitType !== 'fcc' && (
                    <>
                      <div>
                       <BhTooltip
                          title="Total Combined Reflux Temperature"
                          message="Temperature of reflux entering distillation tower top tray.  May be different from overhead if pumparound is used for reflux.  If overhead is the only reflux, duplicate the drum temperature here."
                        >
                        <BhTextInput
                          type="text"
                          data-testid="combinedRefluxTemperature-test'"
                          class="text"
                          label="Total Combined Reflux Temperature"
                          isRequired
                          unit={deslaterTempUOM && deslaterTempUOM === '°F' ? '°F' : '°C'}
                          value={
                            simulationData.overheadWater &&
                            simulationData.overheadWater.combinedRefluxData &&
                            simulationData.overheadWater.combinedRefluxData.combinedRefluxTemp!
                          }
                          id="combinedRefluxTemperature"
                          isError={errors && errors.combinedRefluxTemperature ? true : false}
                          onBhEventChange={(event: any) => handleInputChange(event)}
                          onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                          onBhEventBlur={(event: any) => handleInputChange(event)}
                          message={
                            simulationData &&
                            Object.keys(simulationData).length > 0 &&
                            simulationData.overheadWater &&
                            simulationData.overheadWater.combinedRefluxData
                              ? simulationData.overheadWater.combinedRefluxData.combinedRefluxTempMin +
                                ' to ' +
                                simulationData.overheadWater.combinedRefluxData.combinedRefluxTempMax
                              : ''
                          }
                        >
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.combinedRefluxData &&
                              simulationData.overheadWater.combinedRefluxData.combinedRefluxTempMin!}
                          </label>
                          <label hidden>
                            {simulationData &&
                              Object.keys(simulationData).length > 0 &&
                              simulationData.overheadWater &&
                              simulationData.overheadWater.combinedRefluxData &&
                              simulationData.overheadWater.combinedRefluxData.combinedRefluxTempMax!}
                          </label>
                        </BhTextInput>
                       </BhTooltip>
                        <BhFormMessage isError message={errors.combinedRefluxTemperature}></BhFormMessage>
                      </div>
                      <div>
                        <BhTooltip
                          title="Total External Neutralizer Flow"
                          message="Use if the same neutralizer from other unit overheads has a path to the desalter wash.  User must determine the amount of neutralizer imported and express in same dosage units."
                        >
                          <BhTextInput
                            type="text"
                            data-testid="outside-neutralizer-test"
                            label="Total External Neutralizer Flow"
                            unit={simulationData && simulationData.overheadWater && simulationData.overheadWater.neutralizerRate_UOM!}
                            value={simulationData && simulationData.overheadWater && simulationData.overheadWater.outsideNeutralizer!}
                            id="outsideneutralizer"
                            isError={(errors && errors.outsideneutralizer) || outsideNeutralizerText ? true : false}
                            onBhEventChange={(event: any) => handleInputChange(event)}
                            onKeyPress={(e) => /[a-zA-z\s!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                            onBhEventBlur={(event: any) => handleInputChange(event)}
                            message={
                              simulationData && simulationData.overheadWater
                                ? simulationData.overheadWater.outsideNeutralizerMin +
                                  ' to ' +
                                  simulationData.overheadWater.outsideNeutralizerMax
                                : ''
                            }
                          >
                            <label hidden>
                              {simulationData && simulationData.overheadWater && simulationData.overheadWater.outsideNeutralizerMin!}
                            </label>
                            <label hidden>
                              {simulationData && simulationData.overheadWater && simulationData.overheadWater.outsideNeutralizerMax!}
                            </label>
                          </BhTextInput>
                        </BhTooltip>
                        <BhFormMessage isError message={errors.outsideneutralizer || outsideNeutralizerText}></BhFormMessage>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </BhAccordion>
          </BhCard>
        </form>
      </div>
      <BhDialog
        header="Delete input"
        message="Are you sure you want to delete this input?"
        ctas={[
          {
            type: 'critical',
            label: 'Delete',
            key: 'btnDelete',
          },
          {
            type: 'ghost',
            label: 'Cancel',
            key: 'btnCancel',
          },
        ]}
        isOpen={isDeleteDialogueOpen}
        onBhEventCtaClick={(event: any) => handleDialogueCtaClick(event)}
        onBhEventClose={(event: any) => handleClose(event)}
      ></BhDialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(18, 18, 18, 0.8);' }}
        open={isLoading}
        onClick={handleBackDropClose}
      >
        {isLoading ? <BhSpinner size="large"></BhSpinner> : null}
      </Backdrop>
      <BhDialog
        isOpen={isErrorDialogueOpen}
        message={errorMessage}
        header={errorHeader}
        onBhEventClose={(event: any) => handleDialogueClose(event)}
      ></BhDialog>
    </React.Fragment>
  );
}
