import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const simulationDataForm = (callback: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  //Form values
  const [values, setValues] = useState<any>({ date: new Date() });
  //Errors
  const [errors, setErrors] = useState<any>({ date: null });
  const [isFormValid, setFormValid] = useState<boolean>(false);
  const mandatoryText = 'This field is mandatory';
  const rangeText = 'The value entered is out of range.';
  const regex = /^-?\d*(\.\d+)?$/;
  const regex2 = /^\d*(\-\d*)?$/;
  const validate = (name: string, value: any, minVal: any, maxVal: any, minVal1: any, maxVal1: any , isMandatory: any) => {
    if (isMandatory) {
      if (value === undefined) {
        setFormValid(false);
        setErrors({
          ...errors,
          [name]: mandatoryText,
        });
      } else {
        if (!value) {
          setFormValid(false);
          setErrors({
            ...errors,
            [name]: mandatoryText,
          });
        } else if (value) {
          if (!regex.test(value) && !regex2.test(value)) {
            setFormValid(false);
            setErrors({
              ...errors,
              [name]: rangeText,
            });
          } else {
            setFormValid(true);
            setErrors({
              ...errors,
              [name]: null,
            });
          }
          if ((minVal !== 'NA' && maxVal !== 'NA') ||(minVal1 !== 'NA' && maxVal1 !== 'NA')) {
            if ((parseFloat(value) >= parseFloat(minVal) && parseFloat(value) <= parseFloat(maxVal)) || (parseFloat(value) >= parseFloat(minVal1) && parseFloat(value) <= parseFloat(maxVal1))) {
              if (!regex.test(value) && !regex2.test(value)) {
                setFormValid(false);
                setErrors({
                  ...errors,
                  [name]: rangeText,
                });
              } else {
                setFormValid(true);
                setErrors({
                  ...errors,
                  [name]: null,
                });
              }
            } else {
              setFormValid(false);
              setErrors({
                ...errors,
                [name]: rangeText,
              });
            }
          } else {
            if (!regex.test(value)) {
              setFormValid(false);
              setErrors({
                ...errors,
                [name]: rangeText,
              });
            } else {
              setFormValid(true);
              setErrors({
                ...errors,
                [name]: null,
              });
            }
          }
        } else {
          setFormValid(true);
          setErrors({
            ...errors,
            [name]: null,
          });
        }
      }
    } else {
      if (value) {
        if (!regex.test(value) && !regex2.test(value)) {
          setFormValid(false);
          setErrors({
            ...errors,
            [name]: rangeText,
          });
        } else {
          setFormValid(true);
          setErrors({
            ...errors,
            [name]: null,
          });
        }
        if (minVal !== 'NA' && maxVal !== 'NA') {
          if (parseFloat(value) >= parseFloat(minVal) && parseFloat(value) <= parseFloat(maxVal)) {
            if (!regex.test(value) && !regex2.test(value)) {
              setFormValid(false);
              setErrors({
                ...errors,
                [name]: rangeText,
              });
            } else {
              setFormValid(true);
              setErrors({
                ...errors,
                [name]: null,
              });
            }
          } else {
            setFormValid(false);
            setErrors({
              ...errors,
              [name]: rangeText,
            });
          }
        } else {
          if (!regex.test(value)) {
            setFormValid(false);
            setErrors({
              ...errors,
              [name]: rangeText,
            });
          } else {
            setFormValid(true);
            setErrors({
              ...errors,
              [name]: null,
            });
          }
        }
      }
      else {
        setFormValid(true);
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    }
  };

  //A method to handle form inputs
  const handleChange = (event: any) => {
    //To stop default events
    //event.persist();
    let name = event.target.id;
    let val = event.target.value;
    let minVal = event.target.children && event.target.children[0] && event.target.children[0].innerText;
    let maxVal = event.target.children && event.target.children[1] && event.target.children[1].innerText;
    let minVal1 = name === 'washpercentage' || name ==='wash2percentage' ? event.target.children && event.target.children[2] && event.target.children[2].innerText :'NA';
    let maxVal1 = name === 'washpercentage' || name === 'wash2percentage' ? event.target.children && event.target.children[3] && event.target.children[3].innerText: 'NA';
    let isMandatory = event.target.isRequired;
    validate(name, val, minVal, maxVal, minVal1, maxVal1, isMandatory);
    //Let's set these values in state
    setValues({
      ...values,
      [name]: val,
    });
  };

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    if (!searchParams?.get('date')) {
      let configType = localStorage.getItem('configType')!;
      let configFromStorage: any = JSON.parse(localStorage.getItem('defaultConfig')!);
      let unitType;
      let isWithDesalter;
    if (configFromStorage && configFromStorage.refinery) {
      if (configFromStorage.refinery.unit && configFromStorage.refinery.unit[0]) {
        unitType = configFromStorage.refinery.unit[0].unitType.trim().toLowerCase();
        if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'coker'){
          isWithDesalter = configFromStorage.refinery.unit[0].subUnit[0].cokerType === 'CD' ? true : false
        }
        else if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc'){
          isWithDesalter = configFromStorage.refinery.unit[0].subUnit[0].fccType === 'FD' ? true : false

        }
        else isWithDesalter = false
      }
    }
      let mandatoryKeys: any = {
        washrate : true,
        crudecharge: ((unitType !== 'coker' && unitType !== 'fcc') || ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter)) ? true :false,
        crudegravity: (unitType !== 'coker' && unitType !== 'fcc') ? true: false,
        date: true,
        washpercentage: (unitType !== 'coker' && unitType !== 'fcc') ? true: false,
        wash2percentage: configType === '1B' || configType === '2B' ? true : false,
        desaltertemp: (unitType !== 'coker' && unitType !== 'fcc') ? true: false,
        brineph: (unitType !== 'coker' && unitType !== 'fcc') ? true: false,
        brineph2: configType === '1B' || configType === '2B' ? true : false,
        brinesampletemp: (unitType !== 'coker' && unitType !== 'fcc') ? true: false,
        steamtower: true,
        washtemp: true,
        washprocesstemp: true,
        velocitytemp: true,
        drumtemp: true,
        product: true,
        gravity: true,
        distillation10: true,
        distillation50: true,
        distillation90: true,
        product_1: configType === '2B' || configType === '2A' ? true : false,
        gravity_1: configType === '2B' || configType === '2A' ? true : false,
        distillation10_1: configType === '2B' || configType === '2A' ? true : false,
        distillation50_1: configType === '2B' || configType === '2A' ? true : false,
        distillation90_1: configType === '2B' || configType === '2A' ? true : false,
        ph: true,
        ph2: configType === '2B' || configType === '2A' ? true : false,
        combinedRefluxFlow: (unitType !== 'coker' && unitType !== 'fcc') ? true: false,
        combinedRefluxTemperature: (unitType !== 'coker' && unitType !== 'fcc') ? true: false,
      };
      //check if any mandatory input is left empty
      let result: any = {};
      let keys = Object.keys(errors);
      for (let key in mandatoryKeys) {
        if (!keys.includes(key) && mandatoryKeys[key]) {
          result[key] = mandatoryKeys[key];
        }
      }
      let formErrors = {
        crudecharge: null,
        crudegravity: null,
        date: null,
        washpercentage: null,
        wash2percentage: null,
        desaltertemp: null,
        brineph: null,
        brineph2: null,
        brinesampletemp: null,
        steamtower: null,
        washtemp: null,
        washprocesstemp: null,
        velocitytemp: null,
        drumtemp: null,
        product: null,
        gravity: null,
        distillation10: null,
        distillation50: null,
        distillation90: null,
        product_1: null,
        gravity_1: null,
        distillation10_1: null,
        distillation50_1: null,
        distillation90_1: null,
        ph: null,
        ph2: null,
        combinedRefluxFlow: null,
        combinedRefluxTemperature: null,
        washrate :null
      };
      if (result && Object.keys(result).length > 0) {
        for (let itemKey in result) {
          formErrors = {
            ...formErrors,
            [itemKey]: mandatoryText,
          };
        }
        setErrors(formErrors);
      }
    }
  };

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    validate,
    isFormValid,
  };
};

export default simulationDataForm;
