/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import {
  BhActionBar,
  BhButtonDropdown,
  BhDialog,
  BhTabularList,
  BhTitleWrapper,
  BhDivider,
  BhCard,
  BhTextInput,
  BhFormMessage,
  BhTextArea,
  BhButton,
} from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import { DataGrid, GridColDef, GridPagination, gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import React, { useLayoutEffect, useState } from 'react';
import ExcelUploadModal from '../Shared/Modal/ExcelUploadModal';
import './NewSimulation.scss';
import { useNavigate } from 'react-router-dom';
import { APIEnum } from '../../constants/api-enum';
import apiRequest from '../../services/api-helper';
import { Breadcrumbs, Card, CardContent, CardHeader, IconButton, Link, Menu, MenuItem, Stack, TablePaginationProps } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { format } from 'date-fns-tz';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
declare const window: any;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function NewSimulation() {
  const [simulationData, setSimulationData] = useState<any>({});
  const [isSimulationOpen, setSimulationOpen] = useState(false);
  const [isDeleteDialogueOpen, setisDeleteDialogueOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [subText, setSubText] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setErrorFlag] = useState(false);
  const [isSuccess, setSuccessFlag] = useState(false);
  const [configId, setConfigId] = useState('');
  const [configType, setConfigType] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openDropDownMenu, setOpenDropDownMenu] = useState(false);
  const open = Boolean(anchorEl);

  const [simulationName, setSimulationName] = useState('');
  const [isSimNameError,setSimNameError] = useState(false);
  const [comments, setComments] = useState('');
  const [isCommentError,setCommentError] = useState(false);
  const [isSimulationModalOpen,setSimulationModalOpen] = useState(false);
  const [simulationHeader, setSimulationHeader] = useState('');
  const [unitType, setUnitType] = useState<string>('');
  
  const columns: GridColDef[] = [
    { field: 'id', headerName: '', headerClassName: 'grid-header', flex: 1 },
    {
      field: 'date',
      headerName: 'Date',
      width: 195,
      headerClassName: 'grid-header',
      type: 'date',
      renderCell: (params) => renderDate(params.row?.date, params.row?.id),
      flex: 1,
    },
    {
      field: 'crudecharge',
      headerName: (unitType !=='coker' && unitType !=='fcc') ? 'Crude Charge' : 'Unit Charge',
      width: 195,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderCrudeCharge(params.row),
      flex: 1,
    },
    { field: 'brineph', headerName: 'Brine pH', width: 195, sortable: true, headerClassName: 'grid-header', flex: 1 },
    {
      field: 'overheadtemp',
      headerName: 'OH Temp',
      width: 195,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderOhTemp(params.row),
      flex: 1,
    },
    {
      field: 'overheadpress',
      headerName: 'OH Press',
      width: 195,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderOhPress(params.row),
      flex: 1,
    },
    {
      field: 'neutrorate',
      headerName: 'Neutralizer Rate',
      width: 185,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderNeutroRate(params.row),
      flex: 1,
    },
    {
      field: 'chloride',
      headerName: 'Chloride',
      width: 185,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 1,
    },
    {
      field: 'ammonia',
      headerName: 'Ammonia',
      width: 185,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 1,
    },
    {
      field: 'sulfide',
      headerName: 'Sulfide',
      width: 195,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 1,
    },
    { field: 'trampamine', headerName: 'Tramp Amine', width: 195, sortable: true, headerClassName: 'grid-header', flex: 1 },
  ];

  const renderDate = (date: any, id: any) => {
    if (!date) {
      return '';
    }
    return (
      <a className="date-link" href={`/simulation-data?id=${id}`}>
        {format(new Date(date), 'dd-MMM-yyyy')}
      </a>
    );
  };

  const renderCrudeCharge = (row: any) => {
    if (!row?.crudecharge) {
      return 'NA';
    } else return row?.crudecharge + ' ' + row?.crudechargeunit;
  };
  const renderOhTemp = (row: any) => {
    if (!row?.overheadtemp) {
      return '';
    } else return row?.overheadtemp + ' ' + row?.overheadtempunit;
  };
  const renderOhPress = (row: any) => {
    if (!row?.overheadpress) {
      return '';
    } else return row?.overheadpress + ' ' + row?.overheadpressunit;
  };
  const renderNeutroRate = (row: any) => {
    if (!row?.neutrorate) {
      return '';
    } else return row?.neutrorate + ' ' + row?.neutrorateunit;
  };

  const bindSimulationData = () => {
    let data: any = [];
    let length: number = 0;
    let payLoad = {
      data,
      length,
    };
    let isWithDesalter:boolean = false;
     //set unit type from local storage
     let configFromStorage: any = JSON.parse(localStorage.getItem('defaultConfig')!);
     if (configFromStorage && configFromStorage.refinery) {
       if (configFromStorage.refinery.unit && configFromStorage.refinery.unit[0]) {
         setUnitType(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase());
         if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'coker'){
          isWithDesalter = configFromStorage.refinery.unit[0].subUnit[0].cokerType === 'CD' ? true : false;
        }
        else if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc')
          isWithDesalter = configFromStorage.refinery.unit[0].subUnit[0].fccType === 'FD' ? true : false;
        else isWithDesalter = false;
       }
     }
    let simulationDataFromStorage = JSON.parse(localStorage.getItem('simulationData')!);
    if (simulationDataFromStorage && simulationDataFromStorage.length) {
      simulationDataFromStorage.forEach((item: any) => {
        let tempUnit;
        if (item.overheadConditions.OHTemp_UOM === '°F' || item.overheadConditions.OHTemp_UOM === 'F') tempUnit = '°F';
        else if (item.overheadConditions.OHTemp_UOM === '°C' || item.overheadConditions.OHTemp_UOM === 'C') tempUnit = '°C';
        payLoad.data.push({
          id: item.simulationId,
          date: new Date(item.inputDate),
          crudecharge: (configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() !=='coker' && configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() !=='fcc') ||((configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() ==='coker' || configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() ==='fcc') && isWithDesalter) ? item.desalter.crudeCharge.toFixed(3): null,
          crudechargeunit: (configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() !=='coker' && configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() !=='fcc') ||((configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() ==='coker' || configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() ==='fcc') && isWithDesalter) ? item.desalter.crudeCharge_UOM: null,
          brineph: (configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() !=='coker' && configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() !=='fcc') ? item.desalter.brinepHData.brinepH.toFixed(1): null,
          overheadtemp: item.overheadConditions.OHTemp ? item.overheadConditions.OHTemp.toFixed() : '',
          overheadtempunit: item.overheadConditions.OHTemp ? tempUnit : '',
          overheadpress: item.overheadConditions.OHPress ? item.overheadConditions.OHPress.toFixed(3) : '',
          overheadpressunit: item.overheadConditions.OHPress ? item.overheadConditions.OHPress_UOM : '',
          neutrorate: (configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() !=='coker' && configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() !=='fcc') ? (item.overheadWater.neutralizerRate ? item.overheadWater.neutralizerRate.toFixed(1) : ''): null,
          neutrorateunit: (configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() !=='coker' && configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() !=='fcc') ? (item.overheadWater.neutralizerRate ? item.overheadWater.neutralizerRate_UOM : ''): null,
          chloride:item.overheadWater.clData && item.overheadWater.clData.cl ? item.overheadWater.clData.cl.toFixed(1) : '',
          ammonia:item.overheadWater.NH3Data && item.overheadWater.NH3Data.NH3 ? item.overheadWater.NH3Data.NH3.toFixed(1) : '',
          sulfide:item.overheadWater.sulfide ? item.overheadWater.sulfide.toFixed(1) : '',
          trampamine: item.overheadWater.trampAmineData.trampAmine,
        });
      });
      payLoad.length = simulationDataFromStorage.length;
    }
    setSimulationData(payLoad);
  };

  const deleteSimulationData = () => {
    let dataFromStorage = JSON.parse(localStorage.getItem('simulationData')!);
    let simulationIdArray: any = [];
    dataFromStorage.forEach((element: { simulationId: any }) => {
      simulationIdArray.push(element.simulationId);
    });
    const crmDataIds = { crmDataIds: simulationIdArray };
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulation/draft`;
    apiRequest(encodeURI(apiUrl), crmDataIds, APIEnum.DELETE, true, localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' ? 'SandBox':'Actual')
      .then((result) => {
        if (result) {
          setSuccessFlag(true);
          setMessage('Unit details saved successfully');
          //history table data api call to get updated config
        } else {
          setErrorFlag(true);
          result &&
          result.responseStaus &&
          result.responseStaus.errorResponse &&
          result.responseStaus.errorResponse[0] &&
          result.responseStaus.errorResponse[0].message
            ? setMessage(result.responseStaus.errorResponse[0].message)
            : setMessage('Bad Request');
          setTimeout(() => {}, 3000);
        }
      })
      .catch((error: { message: any }) => {
        setErrorFlag(true);
        setMessage(error.message);
        setTimeout(() => {}, 2500);
      });
  };

  const closeSimulationModal = () => {
    setSimulationOpen(false);
    setOpenDropDownMenu(false);
    setOpenDropDown(false);
  };

  const handleActionBarCtaClick = (event: CustomEvent) => {
    //redirect to landing page
    if (event.detail === 'btnCancel') navigate('/');
    //run simulation api integration
    else if (event.detail === 'btnRunSimulation') {
      setSimulationData(['']);
      localStorage.setItem('isApiCalled', 'false');
      localStorage.removeItem('payLoadRunSimulation');
      localStorage.removeItem('isRunSimulationCalled');
      localStorage.removeItem('sandboxsimulation');
      navigate('/simulation-report');
    }
  };

  const handleAddInputCtaClick = (event: CustomEvent) => {
    //redirect to pages based on options
    if (event.detail.value == 'input-from-excel') setSimulationOpen(true);
    else if (event.detail.value == 'input-manual-entry') {
      localStorage.removeItem('manualInput');
      if(localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true'){
        setSimulationModalOpen(true);
      }
      else {
        setSimulationModalOpen(false);
        navigate('/simulation-data');
        window.location.reload();
      }
    }
  };
  const handleDialogueCtaClick = (event: any) => {
    if (event.detail === 'btnCancel') setisDeleteDialogueOpen(!isDeleteDialogueOpen);
    else if (event.detail === 'btnDelete') {
      deleteSimulationData();
      //clear local storage data and reload page
      localStorage.removeItem('manualInput');
      localStorage.removeItem('simulationData');
      localStorage.removeItem('excelData');
      localStorage.removeItem('sandboxsimulation');
      window.location.reload();
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = (event: CustomEvent) => {
    setisDeleteDialogueOpen(!isDeleteDialogueOpen);
  };

  const handleSandboxModalClose = () => {
    setSimulationModalOpen(false);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const ctaClick = (event: any) => {
    setAnchorEl(null);
    if (event.target.id === 'view-config') {
    } else if (event.target.id === 'clear-reset') {
      setisDeleteDialogueOpen(!isDeleteDialogueOpen);
    }
  };

  const Pagination = ({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        className={className}
        count={pageCount}
        page={page + 1}
        style={{ display: 'flex' }}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  const CustomPagination = (props: any) => {
    return <GridPagination ActionsComponent={Pagination} {...props} labelRowsPerPage={'Items per page'} />;
  };

  const CustomNoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No data available
      </Stack>
    );
  };

  useLayoutEffect(() => {
    if(localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true') {
      setSimulationHeader('New sandbox simulation');
      let simulationDetails = localStorage.getItem('sandboxsimulation') && JSON.parse(localStorage.getItem('sandboxsimulation')!);
      if(simulationDetails && Object.keys(simulationDetails).length){
      setSimulationName(simulationDetails.name);
      setComments(simulationDetails.comment);
      }
    }
    else
    {
      setSimulationHeader('New simulation');
    }
    bindSimulationData();
    setSubText(localStorage.getItem('subtitle')!);
    setConfigId(localStorage.getItem('configId')!);
    setConfigType(localStorage.getItem('configType')!);
    let simulationDataFromStorage = localStorage.getItem('simulationData');
    if (typeof simulationDataFromStorage === 'undefined' || simulationDataFromStorage === null) setSimulationOpen(true);
    return()=>{
      setSimulationName('');
      setComments('');
    }
  }, [localStorage.getItem('isSandbox')]);

  const addInputClicked = () => {
    setOpenDropDown((prev) => !prev);
  };

  const otherInputClicked = () => {
    setOpenDropDownMenu((prev) => !prev);
  };

  const handleTextChange = (event: CustomEvent) => {
    if(event.detail){
    setComments(event.detail);
    setCommentError(false);
    }
    else{
      setComments('');
      setCommentError(true);
    }
  };

  const handleInputChange = (event: any) => {
      if(event.target.value){
        setSimulationName(event.target.value);
        setSimNameError(false);
      }
      else{
        setSimulationName('');
        setSimNameError(true);
      }
  }

  const handleNextClick = () => {
    if(simulationName && comments){
    let simulationDetails={'name':simulationName, 'comment':comments};
    localStorage.setItem('sandboxsimulation',JSON.stringify(simulationDetails));
    navigate('/simulation-data');
    window.location.reload();
    }
  else{
   localStorage.removeItem('sandboxsimulation');
  }
  };

  return (
    <React.Fragment>
      <div style={{ marginLeft: '20px', marginTop: '-17px', height: '30px' }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            position: 'absolute',
          }}
        >
          <Link color="inherit" href="/">
            <PublicIcon />
          </Link>
        </Breadcrumbs>
      </div>
      <div style={{ marginTop: '-15px' }}>
        <BhDivider></BhDivider>
      </div>

      <div style={{ display: 'flex', marginLeft: '30px', marginRight: '30px', flexDirection: 'column' }}>
        <div style={{ height: '50px' }}>
          <BhTitleWrapper data-testid="new-sim-title" type="subtitle" header={simulationHeader} subtext={subText}></BhTitleWrapper>
        </div>

        <div style={{ height: '60px', display: 'flex', justifyContent: 'end', marginRight: '60px' }}>
          <BhButtonDropdown
            isOpen={openDropDown}
            onBhEventCtaClick={() => addInputClicked()}
            label="Add input"
            id="btnAddInput"
            type="secondary"
            leftIcon="add"
            menuItems={{
              itemGroups: [
                {
                  items: [
                    {
                      label: 'From excel',
                      value: 'input-from-excel',
                    },
                    {
                      label: 'Manual input',
                      value: 'input-manual-entry',
                    },
                  ],
                  divider: false,
                },
              ],
            }}
            onBhEventChange={(event) => handleAddInputCtaClick(event)}
          ></BhButtonDropdown>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px', marginRight: '30px' }}>
        <Card>
          <CardHeader
            action={
              <div>
                <IconButton aria-label="settings" onClick={(e) => handleClick(e)}>
                  <MoreHorizIcon />
                </IconButton>
              </div>
            }
            title="Simulation data"
          />
          <CardContent>
            <div>
              {Object.keys(simulationData).length > 0 && simulationData.data && simulationData.data.length ? (
                <div style={{ height: 400, width: '100%' }}>
                  <DataGrid
                    pagination
                    slots={{
                      pagination: CustomPagination,
                    }}
                    rows={simulationData.data}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                      sorting: {
                        sortModel: [{ field: 'date', sort: 'desc' }],
                      },
                    }}
                    pageSizeOptions={[5, 10, 15, 20, 25]}
                    columnVisibilityModel={{ id: false, brineph:(unitType ==='coker' || unitType ==='fcc') ? false:true, neutrorate:(unitType === 'coker' || unitType === 'fcc') ? false:true, chloride:(unitType !=='coker' && unitType !=='fcc') ? false:true, ammonia:(unitType !=='coker' && unitType !=='fcc') ? false:true, sulfide:(unitType !=='coker' && unitType !=='fcc') ? false:true   }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    rowHeight={44}
                    columnHeaderHeight={42}
                  />
                </div>
              ) : (
                <div style={{ height: 150 }}>
                  <DataGrid
                    pagination
                    slots={{
                      pagination: CustomPagination,
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={[]}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                      sorting: {
                        sortModel: [{ field: 'date', sort: 'desc' }],
                      },
                    }}
                    pageSizeOptions={[5, 10, 15, 20, 25]}
                    columnVisibilityModel={{ id: false, brineph:(unitType === 'coker' || unitType === 'fcc') ? false:true, neutrorate:(unitType === 'coker' || unitType === 'fcc') ? false:true, chloride:(unitType !=='coker' && unitType !=='fcc') ? false:true, ammonia:(unitType !=='coker' && unitType !=='fcc') ? false:true, sulfide:(unitType !=='coker' && unitType !=='fcc') ? false:true }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    rowHeight={44}
                    columnHeaderHeight={42}
                  />
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
      <div>
        <Menu open={open} anchorEl={anchorEl} onClose={handleMenuClose} id="newsim-menu">
          <MenuItem
            disabled={simulationData.data && simulationData.data.length === 0}
            id="view-config"
            onClick={(event: any) => ctaClick(event)}
          >
            View configuration
          </MenuItem>
          <MenuItem
            disabled={simulationData.data && simulationData.data.length === 0}
            id="clear-reset"
            onClick={(event: any) => ctaClick(event)}
          >
            Clear & reset
          </MenuItem>
        </Menu>
      </div>

      {simulationData.data && simulationData.data.length > 0 && (
        <div className="simulation-report-footer">
          <BhActionBar
            data-testid="handle-action-barCta-click-test"
            footerActions={[
              {
                type: 'secondary',
                label: 'Cancel',
                key: 'btnCancel',
              },
              {
                type: 'primary',
                label: 'Run simulation',
                key: 'btnRunSimulation',
              },
            ]}
            onBhEventCtaClick={(event: any) => handleActionBarCtaClick(event)}
          >
            <img src={`${process.env.PUBLIC_URL}/assets/images//done.png`}></img>
            <span className="typography--decorative-small typography--color-secondary status-span">Draft auto saved</span>
          </BhActionBar>
        </div>
      )}
      <ExcelUploadModal
        otherInputClicked={otherInputClicked}
        openDropDownMenu={openDropDownMenu}
        isSimulationOpen={isSimulationOpen}
        header= {!localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false' ? "Input - Excel" : 'New simulation- Sandbox'}
        closeSimulationModal={closeSimulationModal}
        configType={configType}
        bindSimulationData={bindSimulationData}
        isSandbox={localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' ? true : false}
      ></ExcelUploadModal>
      <BhDialog
        header="Clear & reset"
        message="Clicking on ‘Clear & Reset’ will delete the current draft with any changes you have done, this action is irreversible."
        ctas={[
          {
            type: 'critical',
            label: 'Clear & reset',
            key: 'btnDelete',
          },
          {
            type: 'ghost',
            label: 'Cancel',
            key: 'btnCancel',
          },
        ]}
        isOpen={isDeleteDialogueOpen}
        onBhEventCtaClick={(event: any) => handleDialogueCtaClick(event)}
        onBhEventClose={(event: any) => handleClose(event)}
      ></BhDialog>
       <BootstrapDialog onClose={()=>handleSandboxModalClose()} aria-labelledby="upload-excel-title" open={isSimulationModalOpen} className="upload-modal">
        <BootstrapDialogTitle id="upload-excel-title" onClose={()=>handleSandboxModalClose()}>
          <div className="upload-header">New simulation- Sandbox</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div>
              <BhTextInput
                            id="txt-simname"
                            type="text"
                            label="New simulation name"
                            value={simulationName}
                            isRequired
                            isError={isSimNameError}
                            onBhEventChange={(event) => {
                              handleInputChange(event);
                            }}
                          ></BhTextInput>
                           <BhFormMessage isError message={isSimNameError ? 'This field is mandatory': ''} id="simname-error"></BhFormMessage>
                          <div className='pd-3'></div>
                               <BhTextArea
            fluidHorz={true}
            maxChar={500}
            messageType="count"
            label='Comments'
            isRequired
            placeholder='Write something...'
            onBhEventInput={(event: any) => handleTextChange(event)}
            //onBhEventChange={(event: any) => handleTextChange(event)}
            value={comments}
          ></BhTextArea>
           <BhFormMessage isError message={isCommentError ? 'This field is mandatory': ''} id="comment-error"></BhFormMessage>
          </div>
        </DialogContent>
        <DialogActions>
          <BhButton type="ghost" label="Close" key="btnUploadCancel" onClick={()=>handleSandboxModalClose()} ></BhButton>
          <BhButton
            type="primary"
            label="Save"
            key="btnSave"
            className='btn-next'
            isDisabled={!simulationName || !comments}
            onClick={handleNextClick}
          ></BhButton>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
